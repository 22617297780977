"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.theme = void 0;
var create_theme_1 = require("theme/create-theme");
var colors = {
    primary: '#59365e',
    secondary: '#6c5fc7',
    tertiary: '#f5f2f2',
    a0: 'rgba(255,255,255,0)',
    a100: 'rgba(52, 69, 99, 0.04)',
    a200: 'rgba(52, 69, 99, 0.08)',
    a300: 'rgba(52, 69, 99, 0.15)',
    a400: 'rgba(52, 69, 99, 0.2)',
    a500: 'rgba(52, 69, 99, 0.27)',
    n0: '#FFFFFF',
    n10: '#FAFBFC',
    n20: '#F5F5F7',
    n30: '#EBECF0',
    n40: '#DFE1E6',
    n50: '#C1C7D0',
    n60: '#B3BAC5',
    n70: '#A5ADBA',
    n80: '#97A0AF',
    n90: '#8993A4',
    n100: '#7A869A',
    n200: '#6B778C',
    n300: '#5E6C84',
    n400: '#505F79',
    n500: '#42526E',
    n600: '#344563',
    n700: '#2B3950',
    n800: '#222D38',
    n900: '#12191F',
    red100: '#ffe4e9',
    red200: '#fcc3cb',
    red300: '#f58c9e',
    red400: '#ee4c6d',
    red500: '#e12449',
    red600: '#c91235',
    red700: '#ad0b2a',
    red800: '#80061d',
    red900: '#4f0110',
    red1000: '#3b010b',
    yel100: '#FFF7D6',
    yel200: '#F8E6A0',
    yel300: '#F4CC46',
    yel400: '#E2B203',
    yel500: '#CF9F02',
    yel600: '#B38600',
    yel700: '#946F00',
    yel800: '#7F5F01',
    yel900: '#533F04',
    yel1000: '#3D2E00',
    gre100: '#DFFCF0',
    gre200: '#BAF3DB',
    gre300: '#7EE2B8',
    gre400: '#4BCE97',
    gre500: '#2ABB7F',
    gre600: '#22A06B',
    gre700: '#1F845A',
    gre800: '#216E4E',
    gre900: '#164B35',
    gre1000: '#133527',
    blu100: '#E9F2FF',
    blu200: '#CCE0FF',
    blu300: '#84B8FF',
    blu400: '#579DFF',
    blu500: '#388BFF',
    blu600: '#1D7AFC',
    blu700: '#0C66E4',
    blu800: '#0055CC',
    blu900: '#08316B',
    blu1000: '#082145',
    pur100: '#F3F0FF',
    pur200: '#DFD8FD',
    pur300: '#B8ACF6',
    pur400: '#9F8FEF',
    pur500: '#8F7EE7',
    pur600: '#8270DB',
    pur700: '#6E5DC6',
    pur800: '#5E4DB2',
    pur900: '#352C63',
    pur1000: '#231C3F'
};
var radiusOpts = [0, 0.5, 1, 1.5, 2, 3];
var sizingOpts = [-1, -0.5, 0, 0.5, 1, 1.5, 2, 3, 4, 6, 8, 12, 16];
var radius = radiusOpts.reduce(function (obj, size) {
    obj[size] = {
        borderRadius: "".concat(size * 8, "px")
    };
    return obj;
}, {});
var m = sizingOpts.reduce(function (obj, size) {
    obj[size] = {
        margin: "".concat(size * 8, "px")
    };
    return obj;
}, {});
var mx = sizingOpts.reduce(function (obj, size) {
    obj[size] = {
        marginRight: "".concat(size * 8, "px"),
        marginLeft: "".concat(size * 8, "px")
    };
    return obj;
}, {});
var my = sizingOpts.reduce(function (obj, size) {
    obj[size] = {
        marginTop: "".concat(size * 8, "px"),
        marginBottom: "".concat(size * 8, "px")
    };
    return obj;
}, {});
var mt = sizingOpts.reduce(function (obj, size) {
    obj[size] = {
        marginTop: "".concat(size * 8, "px")
    };
    return obj;
}, {});
var mr = sizingOpts.reduce(function (obj, size) {
    obj[size] = {
        marginRight: "".concat(size * 8, "px")
    };
    return obj;
}, {});
var mb = sizingOpts.reduce(function (obj, size) {
    obj[size] = {
        marginBottom: "".concat(size * 8, "px")
    };
    return obj;
}, {});
var ml = sizingOpts.reduce(function (obj, size) {
    obj[size] = {
        marginLeft: "".concat(size * 8, "px")
    };
    return obj;
}, {});
var p = sizingOpts.reduce(function (obj, size) {
    obj[size] = {
        padding: "".concat(size * 8, "px")
    };
    return obj;
}, {});
var px = sizingOpts.reduce(function (obj, size) {
    obj[size] = {
        paddingRight: "".concat(size * 8, "px"),
        paddingLeft: "".concat(size * 8, "px")
    };
    return obj;
}, {});
var py = sizingOpts.reduce(function (obj, size) {
    obj[size] = {
        paddingTop: "".concat(size * 8, "px"),
        paddingBottom: "".concat(size * 8, "px")
    };
    return obj;
}, {});
var pt = sizingOpts.reduce(function (obj, size) {
    obj[size] = {
        paddingTop: "".concat(size * 8, "px")
    };
    return obj;
}, {});
var pr = sizingOpts.reduce(function (obj, size) {
    obj[size] = {
        paddingRight: "".concat(size * 8, "px")
    };
    return obj;
}, {});
var pb = sizingOpts.reduce(function (obj, size) {
    obj[size] = {
        paddingBottom: "".concat(size * 8, "px")
    };
    return obj;
}, {});
var pl = sizingOpts.reduce(function (obj, size) {
    obj[size] = {
        paddingLeft: "".concat(size * 8, "px")
    };
    return obj;
}, {});
var gap = sizingOpts.reduce(function (obj, size) {
    obj[size] = {
        gap: "".concat(size * 8, "px")
    };
    return obj;
}, {});
var allColors = Object.keys(colors).reduce(function (acc, name) {
    acc[name] = {
        color: colors[name]
    };
    return acc;
}, {});
var allBgs = Object.keys(colors).reduce(function (acc, name) {
    acc[name] = {
        background: colors[name]
    };
    return acc;
}, {});
exports.theme = (0, create_theme_1.createTheme)({
    theme: {
        border: {
            'default': { border: "1px solid ".concat(colors.n30) },
            'dashed': { border: "2px solid ".concat(colors.n30) }
        },
        elevation: {
            sunken: {},
            resting: {},
            raised: {},
            floating: {}
        },
        color: __assign({ 'inherit': { color: 'inherit' }, 'primary': { color: colors.primary }, 'primary.inverse': { color: colors.n0 }, 'secondary': { color: colors.secondary }, 'secondary.inverse': { color: colors.n0 }, 'tertiary': { color: colors.tertiary }, 'tertiary.inverse': { color: colors.n0 }, 'text': { color: colors.n900 }, 'text.subtle': { color: colors.n100 }, 'neutral': { color: colors.n40 }, 'neutral.inverse': { color: colors.n600 }, 'neutral.subtle.inverse': { color: colors.n500 }, 'success': { color: colors.gre500 }, 'success.inverse': { color: colors.n0 }, 'success.subtle.inverse': { color: colors.gre600 }, 'danger': { color: colors.red500 }, 'danger.inverse': { color: colors.n0 }, 'danger.subtle.inverse': { color: colors.red600 }, 'warning': { color: colors.yel500 }, 'warning.inverse': { color: colors.n900 }, 'warning.subtle.inverse': { color: colors.yel600 }, 'info': { color: colors.blu500 }, 'info.inverse': { color: colors.n0 }, 'info.subtle.inverse': { color: colors.blu600 } }, allColors),
        bg: __assign({ 'primary': { background: colors.primary }, 'secondary': { background: colors.secondary }, 'tertiary': { background: colors.tertiary }, 'neutral': { background: colors.n40 }, 'neutral.subtle': { background: colors.n20 }, 'info': { background: colors.blu500 }, 'info.subtle': { background: colors.blu100 }, 'success': { background: colors.gre500 }, 'success.subtle': { background: colors.gre100 }, 'warning': { background: colors.yel400 }, 'warning.subtle': { background: colors.yel100 }, 'danger': { background: colors.red500 }, 'danger.subtle': { background: colors.red100 } }, allBgs),
        fontWeight: {
            'light': { fontWeight: 300 },
            'normal': { fontWeight: 400 },
            'bold': { fontWeight: 600 },
            'bolder': { fontWeight: 700 }
        },
        fontSize: {
            '0.25': { fontSize: '0.5rem', lineHeight: '1rem' },
            '0.5': { fontSize: '0.75rem', lineHeight: '1.2rem' },
            '1': { fontSize: '1rem', lineHeight: '1.4rem' },
            '2': { fontSize: '1.15rem', lineHeight: '1.5rem' },
            '3': { fontSize: '1.3rem', lineHeight: '2rem' },
            '4': { fontSize: '1.6rem', lineHeight: '2rem' },
            '5': { fontSize: '2rem', lineHeight: '2.4rem' },
            '6': { fontSize: '2.4rem', lineHeight: '2.8rem' }
        },
        effect: {
            clickable: {
                cursor: 'pointer'
            },
            hoverable: {
                transition: 'background-color 200ms ease'
            },
            'hoverable:hover': {
                backgroundColor: colors.a100
            }
        }
    },
    util: {
        m: m,
        mx: mx,
        my: my,
        mt: mt,
        mr: mr,
        mb: mb,
        ml: ml,
        p: p,
        px: px,
        py: py,
        pt: pt,
        pr: pr,
        pb: pb,
        pl: pl,
        radius: radius,
        gap: gap,
        flexJustify: {
            start: { justifyContent: 'flex-start' },
            center: { justifyContent: 'center' },
            end: { justifyContent: 'flex-end' },
            between: { justifyContent: 'space-between' }
        },
        flexAlign: {
            start: { alignItems: 'flex-start' },
            center: { alignItems: 'center' },
            end: { alignItems: 'flex-end' },
            stretch: { alignItems: 'stretch' }
        },
        flexWrap: {
            wrap: { flexWrap: 'wrap' },
            nowrap: { flexWrap: 'nowrap' }
        },
        display: {
            'block': { display: 'block' },
            'inline-block': { display: 'inline-block' },
            'inline': { display: 'inline' },
            'flex': { display: 'flex' }
        },
        yAlign: {
            'top': { verticalAlign: 'top' },
            'middle': { verticalAlign: 'middle' },
            'bottom': { verticalAlign: 'bottom' }
        },
        xAlign: {
            'right': { textAlign: 'right' },
            'center': { textAlign: 'center' },
            'left': { textAlign: 'left' }
        }
    }
});
