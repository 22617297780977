import React                     from 'react';
import {Box, Button, Flex, Text} from '@glimpse/glass';
import {useTranslation}          from 'foundations/i18n/use-translation';
import {Link}                    from 'react-router-dom';
import styles                    from 'features/billing-product/billing-product-upsell.module.css';
import {RequirePermission}       from 'features/permission/require-permission';

export function BillingProductUpsell({
    icon,
    preTitle,
    title,
    desc
}: {
    icon: React.ReactNode
    preTitle: string,
    title: string,
    desc: string,
}) {
    const {t} = useTranslation();
    return (
        <Box bg={'neutral.subtle'} p={8} radius={1} className={styles.background} border={'default'}>
            <Flex gap={1}>
                <div>{icon}</div>
                <Text fontSize={2} fontWeight={'bold'}>{preTitle}</Text>
            </Flex>
            <Box mb={0.5} mt={3}>
                <Text display={'block'} fontSize={4} fontWeight={'bold'}>{title}</Text>
            </Box>
            <Box mb={4}>
                <Text color={'text.subtle'} display={'block'}>{desc}</Text>
            </Box>
            <RequirePermission permission={'billing.write'}>
                <Link to={'/settings/billing'}>
                    <Button variant={'primary'}>{t('Upgrade now')}</Button>
                </Link>
            </RequirePermission>
        </Box>
    );
}