import {GlassChangeEvent, Textbox} from '@glimpse/glass';
import {useEffect, useState}       from 'react';
import {useTranslation}            from 'foundations/i18n/use-translation';

export function Searchbox({
    onChange,
    name,
    value
}: {
    onChange: (event: GlassChangeEvent) => void,
    name: string,
    value: string
}) {
    const {t}                     = useTranslation();
    const [interim, setInterim]   = useState(value);
    const [debounce, setDebounce] = useState<ReturnType<typeof setTimeout>>();

    const handleChange = (event: GlassChangeEvent) => {
        setInterim(event.currentTarget.value);
    };

    useEffect(() => {
        clearTimeout(debounce);
        setDebounce(setTimeout(() => {
            onChange({
                currentTarget: {
                    name:  name,
                    value: interim
                }
            });
        }, 400));
    }, [interim]);

    return (
        <Textbox onChange={handleChange} name={name} value={interim} placeholder={t('Search')}/>
    );
}