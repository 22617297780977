import {Component, ScorecardResult}            from '@glimpse/glimpse';
import {Box, Grid, Text, Reveal, Spaced, Flex} from '@glimpse/glass';
import {glimpse}                               from 'providers/glimpse/glimpse-provider';
import React                                   from 'react';
import {Link}                                  from 'react-router-dom';
import {useTranslation}                        from 'foundations/i18n/use-translation';
import {ScorecardCheckRunStatus}               from 'features/scorecard-check-run/scorecard-check-run-status';
import {useQuery}                              from 'foundations/net/use-query';
import {RequirePermission}                     from 'features/permission/require-permission';
import {CenteredSpinner}                       from 'foundations/ui/interstitial/centered-spinner';
import {ProgressBar}                           from '@glimpse/glass';

export function ScorecardResultList({
    componentId
}: {
    componentId: Component['id']
}) {
    const {t}             = useTranslation();
    const query           = {
        component_id: componentId
    };
    const {data, loading} = useQuery<ScorecardResult>({
        key: ['scorecard-result', query],
        fn:  () => glimpse.scorecardResult.list(query)
    });

    if (loading) {
        return (
            <Box border={'default'} xAlign={'center'} radius={1}>
                <CenteredSpinner/>
            </Box>
        );
    }

    if (data?.length === 0) {
        return (
            <Box border={'default'} pt={4} pb={4} xAlign={'center'} radius={1}>
                <Text color='text.subtle'>{t('No scorecards assigned.')}</Text>
            </Box>
        );
    }

    return (
        <RequirePermission permission={'components.read'} fallback>
            <Grid gap={1} xs={12}>
                {
                    data?.map(result =>
                        <Reveal>
                            <Box border={'default'} radius={1} effects={['clickable', 'hoverable']}>
                                <Link to={`/components/${result.component_id}/scorecard/${result.scorecard_id}`}>
                                    <Box p={2}>
                                        <Box mb={1.5}>
                                            <Grid>
                                                <Grid.Item xs={9}>
                                                    <Text fontSize={1} fontWeight={'bold'}>{result.scorecard!.name}</Text>
                                                </Grid.Item>
                                                <Grid.Item xs={3}>
                                                    <Flex gap={1} align={'center'}>
                                                        <Text fontSize={0.5} color={'text.subtle'}>{result.score}/{result.max_score}</Text>
                                                        <ProgressBar value={result.score} max={result.max_score} color={'success'}/>
                                                    </Flex>
                                                </Grid.Item>
                                            </Grid>
                                        </Box>
                                        {
                                            result.results?.length === 0 &&
                                            <Text color={'text.subtle'}>{t('This scorecard doesn\'t have any checks')}</Text>
                                        }
                                        <Box ml={1}>
                                            {
                                                result?.results?.map(result => {
                                                    return (
                                                        <Box mb={1}>
                                                            <Box mr={1} display={'inline-block'}>
                                                                <ScorecardCheckRunStatus passed={result.latest_run?.passed}/>
                                                            </Box>
                                                            <Spaced gap={1}>
                                                                <Text display={'inline-block'}>{result.check.name}</Text>
                                                                {
                                                                    result.latest_run?.passed === false &&
                                                                    <Text color={'text.subtle'}>{result.latest_run.message}</Text>
                                                                }
                                                            </Spaced>
                                                        </Box>
                                                    );
                                                })
                                            }
                                        </Box>
                                    </Box>
                                </Link>
                            </Box>
                        </Reveal>
                    )
                }
            </Grid>
        </RequirePermission>
    );
}