import {Modal, Button, Form, Textbox, Textarea} from '@glimpse/glass';
import {ErrorModal}                             from 'foundations/ui/error-modal/error-modal';
import {LoadingModal}                           from 'foundations/ui/loading-modal/loading-modal';
import {useForm}                                from 'foundations/ui/form/use-form';
import React                                    from 'react';
import {Scorecard}      from '@glimpse/glimpse';
import {glimpse}        from 'providers/glimpse/glimpse-provider';
import {useTranslation} from 'foundations/i18n/use-translation';
import toast                                    from 'react-hot-toast';
import {useQuery}                               from 'foundations/net/use-query';
import {useMutate}                              from 'foundations/net/use-mutate';

export function UpdateScorecardModal({
    open,
    onClose,
    scorecardId
}: {
    open: boolean,
    onClose: () => void,
    scorecardId: Scorecard['id']
}) {
    const {t}  = useTranslation();
    const form = useForm<Scorecard.Update.Params>();
    const load = useQuery<Scorecard>({
        key:       ['scorecards', scorecardId],
        fn:        async () => await glimpse.scorecard.list({id: scorecardId}),
        onSuccess: (res) => form.setData(res?.data[0])
    });

    const mutate = useMutate<Scorecard>({
        key:       ['scorecards', scorecardId],
        fn:        async () => await glimpse.scorecard.update(scorecardId, form.data),
        onSuccess: () => {
            toast.success(t('Scorecard updated'));
            onClose();
        }
    });

    if (load.loading)
        return <LoadingModal onClose={onClose} open={open}/>;

    if (load.error)
        return <ErrorModal onClose={onClose} open={open}/>;

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header title={t('Update Scorecard')}/>
            <Modal.Body>
                <Form>
                    <Form.Field label={t('Name')} errors={mutate.error?.params.name}>
                        <Textbox {...form.register('name')}/>
                    </Form.Field>
                    <Form.Field label={t('Description')} errors={mutate.error?.params.description}>
                        <Textarea {...form.register('description')}/>
                    </Form.Field>
                </Form>
            </Modal.Body>
            <Modal.Footer right={
                <>
                    <Button onClick={onClose}>{t('Cancel')}</Button>
                    <Button variant='primary' loading={mutate.loading} onClick={mutate.submit}>{t('Save')}</Button>
                </>
            }/>
        </Modal>
    );
}