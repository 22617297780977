import {Modal, Button, Form, Textbox, Text, Box, Select} from '@glimpse/glass';
import {PropertyValue}                                   from '@glimpse/glimpse';
import {useForm}                                         from 'foundations/ui/form/use-form';
import {glimpse}                                         from 'providers/glimpse/glimpse-provider';
import {LoadingModal}                                    from 'foundations/ui/loading-modal/loading-modal';
import {ErrorModal}                                      from 'foundations/ui/error-modal/error-modal';
import {useTranslation}                                  from 'foundations/i18n/use-translation';
import toast                                             from 'react-hot-toast';
import {useMutate}                                       from 'foundations/net/use-mutate';
import {useQuery}                                        from 'foundations/net/use-query';
import {useQueryClient}                                  from '@tanstack/react-query';

export function UpdatePropertyValueModal({
    open,
    onClose,
    propertyValueId
}: {
    open: boolean,
    onClose: () => void,
    propertyValueId: PropertyValue['id']
}) {
    const {t}         = useTranslation();
    const queryClient = useQueryClient();
    const form        = useForm<PropertyValue.Update.Params>();

    const load = useQuery<PropertyValue>({
        key:       ['property-value', propertyValueId],
        fn:        () => glimpse.propertyValue.list({
            id:     propertyValueId,
            expand: ['property']
        }),
        onSuccess: (res) => form.setData(res.data[0])
    });

    const update = useMutate<PropertyValue>({
        key:       ['property-value', propertyValueId],
        fn:        () => glimpse.propertyValue.update(propertyValueId, form.data),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['property']});
            toast.success(t('Property updated'));
            onClose();
        }
    });

    if (load.loading) {
        return <LoadingModal onClose={onClose} open={open}/>;
    }

    if (load.error) {
        return <ErrorModal onClose={onClose} open={open}/>;
    }

    const options = load.data?.[0].property?.options?.split(',').map((opt) => ({
        label: opt.trim(),
        value: opt.trim()
    }));

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header title={load?.data?.[0]?.property?.label ?? t('Property')}/>
            <Modal.Body>
                <Box mb={3} mt={-1}>
                    <Text color='text.subtle'>{load.data?.[0]?.property?.description ?? t('No description')}</Text>
                </Box>
                <Form>
                    <Form.Field errors={update.error?.params.value} label={t('Value')}>
                        {
                            load.data?.[0].property?.format === 'select' &&
                            <Select {...form.register('value')} options={options ?? []}/>
                        }
                        {
                            load.data?.[0].property?.format === 'text' &&
                            <Textbox {...form.register('value')}/>
                        }
                    </Form.Field>
                    {
                        load.data?.[0]?.property?.format === 'text' &&
                        <Form.Field errors={update.error?.params.link} label={t('URL')} description={t('URL to go to when this value is clicked')}>
                            <Textbox {...form.register('url')}/>
                        </Form.Field>
                    }
                </Form>
            </Modal.Body>
            <Modal.Footer right={
                <>
                    <Button onClick={onClose}>{t('Cancel')}</Button>
                    <Button variant='primary' loading={update.loading} onClick={update.submit}>{t('Save')}</Button>
                </>
            }/>
        </Modal>
    );
}