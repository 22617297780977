import {Icon, Text, Box} from '@glimpse/glass';
import React  from 'react';
import styles from 'foundations/ui/empty/empty.module.css';

export function Empty({
    icon,
    title,
    desc,
    prompt
}: {
    icon: React.ReactElement<typeof Icon>,
    title: string,
    desc: string,
    prompt?: React.ReactElement
}) {
    return (
        <div className={styles.empty}>
            <div className={styles.content}>
                <Box mb={2} p={1.5} display='inline-block' bg='neutral.subtle' className={styles.icon} radius={1}>
                    {icon}
                </Box>
                <Box mb={1}>
                    <Text fontWeight='bolder' fontSize={3}>{title}</Text>
                </Box>
                <Text color='text.subtle'>{desc}</Text>
                {
                    prompt &&
                    <Box mt={4}>
                        {prompt}
                    </Box>
                }
            </div>
        </div>
    );
}