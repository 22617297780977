export class ObjectUtil {

    public static isEmpty(obj: Record<any, any>) {
        for (const prop in obj) {
            if (obj[prop] !== '' && obj[prop] !== null && typeof obj[prop] !== 'undefined') {
                return false;
            }
        }

        return true;
    }
}