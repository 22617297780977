"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createTheme = void 0;
function createTheme(sheet) {
    var util = {};
    var theme = {};
    Object.keys(sheet.util).forEach(function (utils) {
        util[utils] = compute("glass-util-".concat(utils), sheet.util[utils]);
    });
    Object.keys(sheet.theme).forEach(function (themes) {
        theme[themes] = compute("glass-theme-".concat(themes), sheet.theme[themes]);
    });
    return {
        theme: theme,
        util: util
    };
}
exports.createTheme = createTheme;
function compute(prefix, set) {
    var computed = {};
    Object.keys(set).forEach(function (key) {
        var css = '';
        var keyName = key.replaceAll('.', '_DOT_');
        Object.keys(set[key]).forEach(function (cssProp) {
            var cssPropName = cssProp.replace(/[A-Z]/g, function (s) { return '-' + s.toLowerCase(); });
            css += "".concat(cssPropName, ": ").concat(set[key][cssProp], ";");
        });
        computed[key] = {
            name: "".concat(prefix, "-").concat(keyName),
            css: ":global(.".concat(prefix, "-").concat(keyName, ") {").concat(css, "}")
        };
    });
    return computed;
}
