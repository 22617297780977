import styles           from 'foundations/layouts/app-layout/app-glowbar-profile.module.css';
import {Menu, useModal} from '@glimpse/glass';
import PlaceholderImg   from 'features/user/user-placeholder-image.jpg';
import IconLight        from 'foundations/brand/icon_light.svg';
import {useContext}     from 'react';
import {AuthContext}    from 'features/auth/auth-context';
import {Link}           from 'react-router-dom';
import {SignoutModal}   from 'features/auth/signout-modal';
import {useTranslation} from 'foundations/i18n/use-translation';
import {UserSlideout}   from 'features/user/user-slideout';

export function AppGlowbarProfile() {
    const {t}               = useTranslation();
    const {user, workspace} = useContext(AuthContext);
    const profileModal      = useModal();
    const signoutModal      = useModal();

    const menu = (
        <>
            <Menu.Group>
                <div className={styles.menuInfo}>
                    <img src={PlaceholderImg} className={styles.menuImage}/>
                    <span className={styles.menuName}>{user.name}</span>
                </div>
            </Menu.Group>
            <Menu.Group>
                <div>
                    <Menu.Item onClick={profileModal.toggle}>
                        {t('Profile')}
                    </Menu.Item>
                </div>
                <Link to={'/settings'}>
                    <Menu.Item>
                        {t('Settings')}
                    </Menu.Item>
                </Link>
            </Menu.Group>
            <Menu.Group>
                <div>
                    <Menu.Item onClick={signoutModal.toggle}>
                        {t('Sign out')}
                    </Menu.Item>
                </div>
            </Menu.Group>
        </>
    );

    return (
        <>
            <SignoutModal open={signoutModal.open} onClose={signoutModal.toggle}/>
            <UserSlideout open={profileModal.open} onClose={profileModal.toggle} userId={user.id}/>
            <Menu menu={menu}>
                <div className={styles.profile}>
                    <img className={styles.profileImage} src={IconLight}/>
                    <div className={styles.profileInfo}>
                        <span className={styles.profileWorkspace}>{workspace.name}</span>
                        <span className={styles.profileName}>{user.name}</span>
                    </div>
                </div>
            </Menu>
        </>
    );
}

