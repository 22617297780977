import {Modal, Button, Text} from '@glimpse/glass';
import {useTranslation} from 'foundations/i18n/use-translation';
import {useAuth}        from 'features/auth/use-auth';

export function SignoutModal({
    open,
    onClose
}: {
    open: boolean,
    onClose: () => void,
}) {
    const {signOut} = useAuth();
    const {t}       = useTranslation();
    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header title={t('Sign Out')}/>
            <Modal.Body>
                <Text>
                    {t('Are you sure you want to sign out?')}
                </Text>
            </Modal.Body>
            <Modal.Footer right={
                <>
                    <Button onClick={onClose}>{t('Close')}</Button>
                    <Button variant='destructive' onClick={signOut}>{t('Sign Out')}</Button>
                </>
            }/>
        </Modal>
    );
}