import {Box, Button, Icon, Text} from '@glimpse/glass';
import React                     from 'react';
import {useTranslation}          from 'foundations/i18n/use-translation';

export function IntegrationSuccessInlay() {
    const {t} = useTranslation();
    return (
        <Box xAlign={'center'} yAlign={'center'}>
            <Box mb={4}>
                <Icon fa={['fas', 'fa-check']} color={'success'} fontSize={6}/>
            </Box>
            <Box mb={1}>
                <Text fontSize={3} fontWeight={'bold'}>{t('Integration successfully connected!')}</Text>
            </Box>
            <Box mb={6}>
                <Text>{t('You can safely close this window.')}</Text>
            </Box>
            <Button fullwidth variant={'primary'} onClick={window.close}>{t('Close Window')}</Button>
        </Box>
    );
}