import {Alert, Box, Button, Grid, Icon, Tabs, Text, Textbox} from '@glimpse/glass';
import React                                                 from 'react';
import {useForm}   from 'foundations/ui/form/use-form';
import {glimpse}   from 'providers/glimpse/glimpse-provider';
import {AppLayout} from 'foundations/layouts/app-layout/app-layout';
import {useTranslation}                                      from 'foundations/i18n/use-translation';
import {useMutate}                                           from 'foundations/net/use-mutate';
import {Insight}                                             from '@glimpse/glimpse';
import {BillingProductUpsell} from 'features/billing-product/billing-product-upsell';
import {RequireEntitlement}   from 'features/entitlement/require-entitlement';
import {RequirePermission}    from 'features/permission/require-permission';

export function InsightsScreen() {
    const {t}  = useTranslation();
    const form = useForm<Insight.Create.Params>();
    const api  = useMutate<Insight>({
        key: ['insight'],
        fn:  () => glimpse.insight.create(form.data)
    });

    const upsell = <BillingProductUpsell
        icon={<Icon fa={['fas', 'fa-bolt']} color={'secondary'} fontSize={4}/>}
        preTitle={t('Insights across your stack')}
        title={t('Query data from across Glimpse and your Integrations')}
        desc={t('Write custom queries to meet advanced use cases, dig deep into data across your integration and compare data points')}/>;

    return (
        <AppLayout title={t('Insights')}>
            <AppLayout.Header>
                <Box pt={3} pb={3}>
                    <Text fontSize={4} fontWeight='bolder'>{t('Insights')}</Text>
                </Box>
                <Tabs onChange={() => {
                }}>
                    <Tabs.Tab value={'a'} current={'a'} label={t('Query')}/>
                </Tabs>
            </AppLayout.Header>
            <AppLayout.Body>
                <RequireEntitlement entitlement={'insights'} fallback={upsell}>
                    <RequirePermission permission={'insights.write'}>
                        <Box mb={2}>
                            <Grid align={'center'} fill>
                                <Box mr={1}>
                                    <Textbox {...form.register('query')} placeholder={'github.repo("org/repo").owner.name'}/>
                                </Box>
                                <Grid.Item shrink>
                                    <Button variant='primary' onClick={api.submit} loading={api.loading}>{t('Submit')}</Button>
                                </Grid.Item>
                            </Grid>
                        </Box>
                        {
                            api.error &&
                            <Box mb={2}>
                                <Alert bg='danger.subtle'>
                                    {api.error?.params?.query?.[0] ?? api.error?.message}
                                </Alert>
                            </Box>
                        }
                        {
                            api.data?.[0] !== undefined &&
                            <Box radius={1} border='default' bg={'neutral.subtle'} p={2}>
                        <pre>
                            {
                                JSON.stringify(api.data[0]?.result, null, 4)
                            }
                        </pre>
                            </Box>
                        }
                    </RequirePermission>
                </RequireEntitlement>
            </AppLayout.Body>
        </AppLayout>
    );
}