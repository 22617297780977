import {User}                                             from '@glimpse/glimpse';
import {Table, useModal, Box, Text, Reveal, Button, Icon} from '@glimpse/glass';
import {useState}                                         from 'react';
import {glimpse}                                          from 'providers/glimpse/glimpse-provider';
import {UpdateUserModal}                                  from 'features/user/update-user-modal';
import {DeleteUserModal}                                  from 'features/user/delete-user-modal';
import {useTranslation}                                   from 'foundations/i18n/use-translation';
import {useQuery}                                         from 'foundations/net/use-query';
import {RequirePermission}                                from 'features/permission/require-permission';
import {UpdateWorkspaceMemberModal}                       from 'features/workspace-member/update-workspace-member-modal';

export function WorkspaceMembersTable({
    query = {}
}: {
    query?: User.List.Params
}) {
    const {t}                     = useTranslation();
    const [selected, setSelected] = useState<User['id']>();
    const viewModal               = useModal();
    const updateModal             = useModal();
    const deleteModal             = useModal();
    const api                     = useQuery<User>({
        key: ['user', {...query, expand: ['role']}],
        fn:  () => glimpse.user.list({...query, expand: ['role']})
    });

    const viewUser = (id: User['id']) => {
        setSelected(id);
        viewModal.toggle();
    };

    const deleteUser = (id: User['id']) => {
        setSelected(id);
        deleteModal.toggle();
    };

    const updateUser = (id: User['id']) => {
        setSelected(id);
        updateModal.toggle();
    };

    return (
        <RequirePermission permission={'users.write'}>
            <UpdateWorkspaceMemberModal open={updateModal.open} onClose={updateModal.toggle} userId={selected!}/>
            <DeleteUserModal open={deleteModal.open} onClose={deleteModal.toggle} userId={selected!}/>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>{t('Name')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('Role')}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {
                        api.data?.length === 0 &&
                        <Box p={2}>
                            <Text color='text.subtle'>{t('No users')}</Text>
                        </Box>
                    }
                    {
                        api.data?.map(user =>
                            <Reveal>
                                <Table.Row effects={['clickable', 'hoverable']} onClick={() => viewUser(user.id)}>
                                    <Table.BodyCell>{user.name}</Table.BodyCell>
                                    <Table.BodyCell>{user.role?.name}</Table.BodyCell>
                                    <Table.BodyCell shrink>
                                        <Reveal.Hidden>
                                            <div>
                                                <Button onClick={() => updateUser(user.id)} spacing={'compact'} icon={<Icon fa={['far', 'fa-pen']}/>}/>
                                                <Button onClick={() => deleteUser(user.id)} spacing={'compact'} icon={<Icon fa={['far', 'fa-trash']}/>}/>
                                            </div>
                                        </Reveal.Hidden>
                                    </Table.BodyCell>
                                </Table.Row>
                            </Reveal>
                        )
                    }
                </Table.Body>
            </Table>
        </RequirePermission>
    );
}