import React, {useContext, useEffect} from 'react';
import styles                         from 'foundations/layouts/app-layout/app-layout.module.css';
import {AppGlowbar}                   from 'foundations/layouts/app-layout/app-glowbar';
import {AppLayoutBody}                from 'foundations/layouts/app-layout/app-layout-body';
import {AppLayoutHeader}              from 'foundations/layouts/app-layout/app-layout-header';
import {AuthContext}                  from 'features/auth/auth-context';
import {useTranslation}               from 'foundations/i18n/use-translation';
import {Alert, Box, Button, Flex}     from '@glimpse/glass';
import {Link}                         from 'react-router-dom';

export function AppLayout({
    children,
    title
}: {
    children: React.ReactNode,
    title?: string
}) {
    const {t}  = useTranslation();
    const auth = useContext(AuthContext);

    useEffect(() => {
            document.title = title ?? 'Glimpse';
    }, [title]);

    return (
        <div className={styles.app}>
            <div className={styles.header}>
                <AppGlowbar/>
            </div>
            <main className={styles.content}>
                {
                    auth.subscription.status === 'canceled' &&
                    <Box pt={2} pl={2} pr={2} bg={'n10'}>
                        <Alert bg={'danger.subtle'}>
                            <Flex gap={2} justify={'center'} align={'center'}>
                                <span>{t('Your account was cancelled, features have been limited. Renew to keep using Glimpse')}</span>
                                <Link to={'/settings/billing/plans'}>
                                    <Button variant={'default'}>{t('Renew Now')}</Button>
                                </Link>
                            </Flex>
                        </Alert>
                    </Box>
                }
                {children}
            </main>
        </div>
    );
}

AppLayout.Header = AppLayoutHeader;
AppLayout.Body   = AppLayoutBody;