import {Grid, Text, Button, Skeleton, useModal, Box, Menu, Icon, Tabs, useTabs, Flex} from '@glimpse/glass';
import {useParams}                                                                    from 'react-router-dom';
import {AppLayout}                                                                    from 'foundations/layouts/app-layout/app-layout';
import {glimpse}                      from 'providers/glimpse/glimpse-provider';
import {Scorecard, ScorecardCheckRun} from '@glimpse/glimpse';
import {ScorecardCheckPanel}          from 'features/scorecard-check/scorecard-check-panel';
import {DeleteScorecardModal}                                                         from 'features/scorecard/delete-scorecard-modal';
import {UpdateScorecardModal}                                                         from 'features/scorecard/update-scorecard-modal';
import {RequirePermission}                                                            from 'features/permission/require-permission';
import {useTranslation}                                                               from 'foundations/i18n/use-translation';
import {useQuery}                                                                     from 'foundations/net/use-query';
import React                                                                          from 'react';
import {ScorecardAssignmentPanel}                                                     from 'features/scorecard-assignment/scorecard-assignment-panel';
import {ScorecardCheckRunTable}                                                       from 'features/scorecard-check-run/scorecard-check-run-table';
import {useForm}                                                                      from 'foundations/ui/form/use-form';
import {ScorecardCheckRunStatusFilter}                                                from 'features/scorecard-check-run/scorecard-check-run-status-filter';

export function ScorecardScreen() {
    const {t}         = useTranslation();
    const {id}        = useParams();
    const updateModal = useModal();
    const deleteModal = useModal();
    const tabs        = useTabs('overview');
    const historyForm = useForm<ScorecardCheckRun.List.Params>({
        scorecard_id: id!,
        limit:        50
    });
    const {data}      = useQuery<Scorecard>({
        key: ['scorecards'],
        fn:  glimpse.scorecard.list
    });

    const scorecard = data?.[0];

    const menu = (
        <>
            <Menu.Group>
                <Menu.Item onClick={updateModal.toggle} icon={<Icon fa={['far', 'fa-pen']}/>}>
                    {t('Edit')}
                </Menu.Item>
                <Menu.Item onClick={deleteModal.toggle} icon={<Icon fa={['far', 'fa-trash']}/>}>
                    {t('Delete')}
                </Menu.Item>
            </Menu.Group>
        </>
    );

    return (
        <AppLayout>
            <RequirePermission permission={'scorecards.read'} fallback>
                <UpdateScorecardModal open={updateModal.open} onClose={updateModal.toggle} scorecardId={id!}/>
                <DeleteScorecardModal open={deleteModal.open} onClose={deleteModal.toggle} scorecardId={id!}/>
                <AppLayout.Header>
                    <Box pt={3} pb={3}>
                        <Grid justify='between'>
                            <div>
                                <Box pb={1}>
                                    {
                                        scorecard?.name === undefined
                                            ? <Skeleton width='200px' height='22px'/>
                                            : <Text fontSize={4} fontWeight='bolder'>{scorecard.name}</Text>
                                    }
                                </Box>
                                {
                                    scorecard?.description === undefined
                                        ? <Skeleton width='150px' height='22px'/>
                                        : <Text color='text.subtle'>{scorecard.description ?? 'No Description'}</Text>
                                }
                            </div>
                            <div>
                                <RequirePermission permission={'scaffolds.write'}>
                                    <Menu menu={menu}>
                                        <Button icon={'fas fa-ellipsis'}/>
                                    </Menu>
                                </RequirePermission>
                            </div>
                        </Grid>
                    </Box>
                    <Tabs onChange={tabs.change}>
                        <Tabs.Tab value={'overview'} current={tabs.current} label={t('Overview')}/>
                        <Tabs.Tab value={'history'} current={tabs.current} label={t('History')}/>
                    </Tabs>
                </AppLayout.Header>
                <AppLayout.Body>
                    {
                        tabs.current === 'overview' &&
                        <Grid gap={6} xs={12}>
                            <Grid.Item xs={5}>
                                <Box pb={2}>
                                    <ScorecardCheckPanel scorecardId={id!}/>
                                </Box>
                            </Grid.Item>
                            <Grid.Item xs={7}>
                                <Box pb={2}>
                                    <ScorecardAssignmentPanel scorecardId={id!}/>
                                </Box>
                            </Grid.Item>
                        </Grid>
                    }
                    {
                        tabs.current === 'history' &&
                        <div>
                            <Box mb={2}>
                                <Flex gap={1}>
                                    <ScorecardCheckRunStatusFilter {...historyForm.register('passed')} label={t('Status')}/>
                                </Flex>
                            </Box>
                            <ScorecardCheckRunTable query={historyForm.data}/>
                        </div>
                    }
                </AppLayout.Body>
            </RequirePermission>
        </AppLayout>
    );
}