import {Alert, Box, Flex, Skeleton, Text} from '@glimpse/glass';
import {AppLayout}                        from 'foundations/layouts/app-layout/app-layout';
import React                              from 'react';
import {RequirePermission}                from 'features/permission/require-permission';
import {useTranslation}                   from 'foundations/i18n/use-translation';
import {useParams}                        from 'react-router-dom';
import {RolePermissionsPanel}             from 'features/role/role-permissions-panel';
import {useQuery}                         from 'foundations/net/use-query';
import {glimpse}                          from 'providers/glimpse/glimpse-provider';
import {Role}                             from '@glimpse/glimpse';

export function RoleScreen() {
    const {id} = useParams();
    const {t}  = useTranslation();

    const {loading, data} = useQuery<Role>({
        key: ['role', {id}],
        fn:  () => glimpse.role.list({id})
    });

    return (
        <AppLayout>
            <RequirePermission permission={'roles.write'} fallback>
                <AppLayout.Header>
                    <Box pt={3} pb={3}>
                        {
                            loading
                                ? <Skeleton width='200px' height='22px'/>
                                : <Text fontSize={4} fontWeight='bolder'>{data[0]?.name + ' ' + t('role')}</Text>
                        }
                    </Box>
                </AppLayout.Header>
                <AppLayout.Body>
                    {
                        data[0]?.workspace_id === null &&
                        <Box mb={2}>
                            <Alert>
                                {t('This is a prebuilt role and can\'t be modified.')}
                            </Alert>
                        </Box>
                    }
                    <RolePermissionsPanel roleId={id!}/>
                </AppLayout.Body>
            </RequirePermission>
        </AppLayout>
    );
}