import styles              from 'foundations/layouts/app-layout/app-glowbar.module.css';
import {AppGlowbarProfile} from 'foundations/layouts/app-layout/app-glowbar-profile';
import React               from 'react';
import {AppGlowbarMenu}    from 'foundations/layouts/app-layout/app-glowbar-menu';
import {useTranslation}    from 'foundations/i18n/use-translation';

export function AppGlowbar() {
    const {t} = useTranslation();

    return (
        <div className={styles.bar}>
            <div className={styles.profile}>
                <AppGlowbarProfile/>
            </div>
            <div className={styles.menu}>
                <AppGlowbarMenu/>
            </div>
        </div>
    );
}