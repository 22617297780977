import {Modal, Button, Form, GlassChangeEvent, Select} from '@glimpse/glass';
import {ComponentOwner, Team, User}                    from '@glimpse/glimpse';
import {useForm}                                       from 'foundations/ui/form/use-form';
import {glimpse}                                       from 'providers/glimpse/glimpse-provider';
import {useTranslation}                                from 'foundations/i18n/use-translation';
import toast                                           from 'react-hot-toast';
import {useMutate}                                     from 'foundations/net/use-mutate';
import {useQuery}                                      from 'foundations/net/use-query';
import {useCallback, useMemo, useState}                from 'react';

export function CreateComponentOwnerModal({
    open,
    onClose,
    componentId
}: {
    open: boolean,
    onClose: () => void,
    componentId: ComponentOwner['component_id']
}) {
    const {t}               = useTranslation();
    const [query, setQuery] = useState<string>();
    const form              = useForm<ComponentOwner.Create.Params>({
        component_id: componentId
    });

    const {error, loading, submit} = useMutate({
        key:       ['component-owner'],
        fn:        () => glimpse.componentOwner.create(form.data),
        onSuccess: () => {
            toast.success(t('Owner added'));
            onClose();
        }
    });

    const users = useQuery<User>({
        key: ['user', query],
        fn:  () => glimpse.user.list({query})
    });

    const teams = useQuery<Team>({
        key: ['team', query],
        fn:  () => glimpse.team.list({query})
    });

    const existing = useQuery<ComponentOwner>({
        key: ['component-owner', {component_id: componentId}],
        fn:  () => glimpse.componentOwner.list({component_id: componentId})
    });

    const options = useMemo(() => {
        if (typeof teams.data === 'undefined' || typeof users.data === 'undefined' || typeof existing.data === 'undefined') {
            return [];
        }

        // Get all existing owners so we can exclude them
        const existingIds = existing.data.map((owner) => owner.user_id ?? owner.team_id);

        return [...teams.data, ...users.data]
            .filter((owner) => !existingIds.includes(owner.id))
            .map((item: Team | User) => ({
                label: item.name,
                value: item.id
            }));
    }, [teams.data, users.data, existing.data]);

    const handleChange = useCallback((event: GlassChangeEvent) => {
        const id     = event.currentTarget.value;
        const isTeam = teams.data.find((team) => team.id === id);

        if (isTeam) {
            form.setData({...form.data, team_id: id});
        } else {
            form.setData({...form.data, user_id: id});
        }
    }, [teams.data, users.data]);

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header title={t('Add Owner')}/>
            <Modal.Body>
                <Form>
                    <Form.Field errors={error?.params.name}>
                        <Select value={form.data.user_id ?? form.data.team_id} name={'id'} onChange={handleChange} options={options} placeholder={t('Search Teams & Users')}/>
                    </Form.Field>
                </Form>
            </Modal.Body>
            <Modal.Footer right={
                <>
                    <Button onClick={onClose}>{t('Cancel')}</Button>
                    <Button variant='primary' loading={loading} onClick={submit}>{t('Add')}</Button>
                </>
            }/>
        </Modal>
    );
}