import {AppLayout}                          from 'foundations/layouts/app-layout/app-layout';
import {Box, Button, Flex, Separator, Text} from '@glimpse/glass';
import React, {useContext, useEffect}       from 'react';
import {BillingPlanGrid}                    from 'features/billing-plan/billing-plan-grid';
import {RequirePermission}                  from 'features/permission/require-permission';
import {useTranslation}                     from 'foundations/i18n/use-translation';
import {AuthContext}                        from 'features/auth/auth-context';
import {useMutate}                          from 'foundations/net/use-mutate';
import {glimpse}                            from 'providers/glimpse/glimpse-provider';

export function SettingsBillingScreen() {
    const {t}  = useTranslation();
    const auth = useContext(AuthContext);

    const {data, submit, loading} = useMutate({
        fn: glimpse.billingPortal.create
    });

    useEffect(() => {
        submit();
    }, []);

    const portalUrl = data?.[0].url;

    return (
        <AppLayout title={t('Billing')}>
            <RequirePermission permission={'billing.read'} fallback>
                <AppLayout.Header>
                    <Flex justify={'between'} align={'center'}>
                        <Box pt={3} pb={3}>
                            <Box mb={1}>
                                <Text fontSize={4} fontWeight='bolder'>{t('Billing')}</Text>
                            </Box>
                            <Text color={'text.subtle'}>{t('We\'ve partnered with Stripe to provide you with flexible and robust billing')}</Text>
                        </Box>
                    </Flex>
                </AppLayout.Header>
                <AppLayout.Body>
                    <Box border={'default'} p={4} radius={1} mb={4}>
                        <Flex justify={'between'} align={'center'}>
                            {
                                auth.subscription.status === 'active' &&
                                <div>
                                    <Box mb={1}>
                                        <Text fontWeight={'bold'} fontSize={3}>{t('Subscription Active')}</Text>
                                    </Box>
                                    <Text color={'text.subtle'}>{t('Your monthly subscription is active and will renew automatically.')}</Text>
                                </div>
                            }
                            {
                                auth.subscription.status === 'trialing' &&
                                <div>
                                    <Box mb={1}>
                                        <Text fontWeight={'bold'} fontSize={3}>{t('Trialing')}</Text>
                                    </Box>
                                    <Text>{t('You are currently trying Glimpse with 30 days free. Upgrade to ensure you don\'t lose access.')}</Text>
                                </div>
                            }
                            {
                                auth.subscription.status === 'canceled' &&
                                <div>
                                    <Box mb={1}>
                                        <Text fontWeight={'bold'} fontSize={3}>{t('Subscription Cancelled')}</Text>
                                    </Box>
                                    <Text>{t('Your subscription was cancelled, features have been limited. Renew your account to regain full access.')}</Text>
                                </div>
                            }
                            {
                                auth.subscription.status === 'overdue' &&
                                <div>
                                    <Box mb={1}>
                                        <Text fontWeight={'bold'} fontSize={3}>{t('Subscription Expired')}</Text>
                                    </Box>
                                    <Text>{t('Your subscription has expired, features have been limited. Renew your account to regain full access.')}</Text>
                                </div>
                            }
                            <div>
                                <Button
                                    href={portalUrl}
                                    hrefTarget={'_blank'}
                                    loading={loading}
                                    spacing={'large'}>
                                    {t('Upgrade Plan')}
                                </Button>
                                <Button
                                    href={portalUrl}
                                    hrefTarget={'_blank'}
                                    variant={'primary'}
                                    loading={loading}
                                    spacing={'large'}>
                                    {t('Manage Subscription')}
                                </Button>
                            </div>
                        </Flex>
                    </Box>
                    <BillingPlanGrid/>
                    <Box radius={1} border={'default'} p={2} mt={4}>
                        <Flex justify={'between'} align={'center'}>
                            <Text fontSize={2} fontWeight={'bold'}>{t('Billing Information')}</Text>
                            <Button href={portalUrl} hrefTarget={'_blank'} loading={loading}>{t('Update Billing Information')}</Button>
                        </Flex>
                        <Box mt={1.5} mb={1.5}>
                            <Separator/>
                        </Box>
                        <Flex justify={'between'} align={'center'}>
                            <Text fontSize={2} fontWeight={'bold'}>{t('Payment Methods')}</Text>
                            <Button href={portalUrl} hrefTarget={'_blank'} loading={loading}>{t('Update Payment Methods')}</Button>
                        </Flex>
                        <Box mt={1.5} mb={1.5}>
                            <Separator/>
                        </Box>
                        <Flex justify={'between'} align={'center'}>
                            <Text fontSize={2} fontWeight={'bold'}>{t('Invoices')}</Text>
                            <Button href={portalUrl} hrefTarget={'_blank'} loading={loading}>{t('View Invoices')}</Button>
                        </Flex>
                    </Box>
                </AppLayout.Body>
            </RequirePermission>
        </AppLayout>
    );
}