import {TokenResponse, useGoogleLogin} from '@react-oauth/google';
import toast                           from 'react-hot-toast';
import {useTranslation}                from 'foundations/i18n/use-translation';
import {useState}                      from 'react';

export function useGoogleAuth({
    onSuccess
}: {
    onSuccess: (authData: Omit<TokenResponse, 'error' | 'error_description' | 'error_uri'>, profileData: {
        email: string,
        name: string
    }) => void
}) {
    const [loading, setLoading] = useState<boolean>(false);
    const {t}                   = useTranslation();

    const google = useGoogleLogin({
        ux_mode:   'popup',
        onError:   () => {
            toast.error(t('An error occurred authenticating with Google'));
        },
        onSuccess: (auth: any) => {
            fetch('https://www.googleapis.com/userinfo/v2/me', {
                headers: {
                    Authorization: `Bearer ${auth.access_token}`
                }
            })
                .then(res => res.json())
                .then((profile) => {
                    onSuccess(auth, profile);
                })
                .catch(() => {
                    toast.error(t('An error occurred retrieving your Google profile')
                    );
                })
                .finally(() => setLoading(false));
        }
    });

    const submit = () => {
        setLoading(true);
        google();
    }

    return {
        submit,
        loading
    };

}