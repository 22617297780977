import {Response}                    from '@glimpse/glimpse';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import toast                         from 'react-hot-toast';
import {useTranslation}              from 'foundations/i18n/use-translation';


export function useMutate<T>(options: {
    key?: any[],
    fn: (params?: any) => Promise<Response<T[]>>,
    onSuccess?: (data: Response['data']) => void
}): {
    loading: boolean,
    data: Response<T>['data'][],
    error: Response<T>['error'],
    status?: Response<T>['status'],
    submit: (params?: any) => void,
} {
    const {t}         = useTranslation();
    const queryClient = useQueryClient();

    const onSuccess = (data: Response) => {
        if (data.status.success) {
            if (options.key) {
                queryClient.invalidateQueries({queryKey: [options.key[0]]});
            }

            if (options.onSuccess) {
                options.onSuccess(data);
            }
        } else {
            switch (data.error?.code) {
                case 'operation_invalid':
                case 'internal_error':
                    toast.error(t('A server error occurred. Please try again in a moment.'));
                    break;

                case 'subscription_required':
                    toast.error(t('An active subscription is required'));
                    break;
            }
        }
    };

    const mutate = useMutation<Response>({
        mutationFn: options.fn,
        onSuccess:  onSuccess,
        onError:    (error) => {
            if (error.message.toLowerCase().includes('networkerror')) {
                toast.error(t('A network error occurred. Please try again in a moment.'));
            }
        }
    });

    return {
        loading: mutate.isPending,
        error:   mutate.data?.error,
        data:    mutate.data?.data,
        status:  mutate.data?.status,
        submit:  (params: any) => mutate.mutate(params),
    };
}