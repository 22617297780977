import {Button, Box, Form, Textbox} from '@glimpse/glass';
import React     from 'react';
import {glimpse} from 'providers/glimpse/glimpse-provider';
import {useForm} from 'foundations/ui/form/use-form';
import {FormFallbackError}       from 'foundations/ui/form/form-fallback-error';
import {CreateIntegrationLayout} from 'features/integration/create-integration-layout';
import {useTranslation} from 'foundations/i18n/use-translation';
import {useMutate}      from 'foundations/net/use-mutate';

export function CreatePagerdutyIntegrationScreen() {
    const {t}                      = useTranslation();
    const form                     = useForm();
    const {error, loading, submit} = useMutate({
        key: ['integration'],
        fn:  () => glimpse.integration.create({
            connector: 'pagerduty',
            auth:      {}
        })
    });

    return (
        <CreateIntegrationLayout label={'PagerDuty'}>
            <Form>
                <FormFallbackError error={error}/>
                <Form.Field label={t('API token')}>
                    <Textbox {...form.register('api_token')}/>
                </Form.Field>
            </Form>
            <Box mt={4}>
                <Button spacing={'large'} fullwidth variant={'primary'} disabled={loading} onClick={submit}>{t('Connect')}</Button>
            </Box>
        </CreateIntegrationLayout>
    );
}