import React, {useContext} from 'react';
import {AuthContext}       from 'features/auth/auth-context';
import {Entitlement}       from '@glimpse/glimpse';

export function RequireEntitlement({
    entitlement,
    children,
    fallback
}: {
    entitlement: Entitlement['name'],
    children?: React.ReactNode,
    fallback?: React.ReactNode
}): React.ReactElement | null {
    const {entitlements} = useContext(AuthContext);
    const matching       = entitlements.find(e => e.name === entitlement);

    if (matching?.entitled) {
        return <>{children}</>;
    } else if (fallback) {
        return <>{fallback}</>;
    } else {
        return null;
    }
}