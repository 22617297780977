import {Component}             from '@glimpse/glimpse';
import {Box, Flex, Text}       from '@glimpse/glass';
import React                   from 'react';
import {useTranslation}        from 'foundations/i18n/use-translation';
import {RequirePermission}     from 'features/permission/require-permission';
import {ComponentPropertyGrid} from 'features/component-property/component-property-grid';

export function ComponentPropertyPanel({
    componentId
}: {
    componentId: Component['id']
}) {
    const {t} = useTranslation();
    return (
        <RequirePermission permission={'properties.read'}>
            <Box mb={1} ml={0.5}>
                <Flex justify='between' align={'center'}>
                    <Text fontWeight='bold' fontSize={3}>{t('Properties')}</Text>
                </Flex>
            </Box>
            <ComponentPropertyGrid componentId={componentId}/>
        </RequirePermission>
    );
}