import {Text, Form, Textbox, Box, Button} from '@glimpse/glass';
import {AuthLayout}                       from 'foundations/layouts/auth-layout/auth-layout';
import {useNavigate, useSearchParams}     from 'react-router-dom';
import {useForm}                          from 'foundations/ui/form/use-form';
import {PasswordReset}  from '@glimpse/glimpse';
import {glimpse}        from 'providers/glimpse/glimpse-provider';
import {useTranslation} from 'foundations/i18n/use-translation';
import {useMutate}                        from 'foundations/net/use-mutate';

export function ConsumePasswordResetScreen() {
    const {t}            = useTranslation();
    const navigate       = useNavigate();
    const [searchParams] = useSearchParams();
    const form           = useForm<PasswordReset.Consume.Params>({
        token: searchParams.get('token') as string
    });

    const {error, loading, submit} = useMutate({
        key:       ['password-reset'],
        fn:        () => glimpse.passwordReset.consume(form.data),
        onSuccess: () => {
            navigate('/reset-password/complete');
        }
    });

    return (
        <AuthLayout>
            <Box pb={4}>
                <Text fontSize={3} fontWeight='bold'>{t('Enter your new Password')}</Text>
            </Box>
            <Form onSubmit={submit}>
                <Form.Field label={t('Password')} errors={error?.params.password}>
                    <Textbox {...form.register('password')} type='password'/>
                </Form.Field>
            </Form>
            <Box pt={1} pb={2}>
                <Button fullwidth variant='primary' spacing='large' onClick={submit} loading={loading}>{t('Save')}</Button>
            </Box>
        </AuthLayout>
    );
}