import {Modal, Button, Form, Textbox, Textarea} from '@glimpse/glass';
import React     from 'react';
import {useForm}        from 'foundations/ui/form/use-form';
import {glimpse}        from 'providers/glimpse/glimpse-provider';
import {useTranslation} from 'foundations/i18n/use-translation';
import toast                                    from 'react-hot-toast';
import {useNavigate} from 'react-router-dom';
import {useMutate}   from 'foundations/net/use-mutate';
import {Team}        from '@glimpse/glimpse';

export function CreateTeamModal({
    open,
    onClose
}: {
    open: boolean,
    onClose: () => void
}) {
    const navigate                 = useNavigate();
    const {t}                      = useTranslation();
    const form                     = useForm<Team.Create.Params>();
    const {error, submit, loading} = useMutate<Team>({
        key:       ['team'],
        fn:        () => glimpse.team.create(form.data),
        onSuccess: (res) => {
            toast.success(t('Team created'));
            navigate(`/teams/${res.data[0].id}`);
            onClose();
        }
    });

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header title={t('Create Team')}/>
            <Modal.Body>
                <Form>
                    <Form.Field label={t('Name')} errors={error?.params.name}>
                        <Textbox {...form.register('name')} placeholder='Red UI Team'/>
                    </Form.Field>
                    <Form.Field label={t('Description')} errors={error?.params.name}>
                        <Textarea {...form.register('description')}/>
                    </Form.Field>
                </Form>
            </Modal.Body>
            <Modal.Footer right={
                <>
                    <Button onClick={onClose}>{t('Cancel')}</Button>
                    <Button variant='primary' loading={loading} onClick={submit}>{t('Create')}</Button>
                </>
            }/>
        </Modal>
    );
}