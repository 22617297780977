import {Table, Button, useModal, Reveal, Icon, Box, Text} from '@glimpse/glass';
import {useNavigate}     from 'react-router-dom';
import {glimpse}         from 'providers/glimpse/glimpse-provider';
import React, {useState} from 'react';
import {Empty}                                            from 'foundations/ui/empty/empty';
import {useTranslation}                                   from 'foundations/i18n/use-translation';
import {CenteredSpinner}                                  from 'foundations/ui/interstitial/centered-spinner';
import {useQuery}                                         from 'foundations/net/use-query';
import {RequirePermission}                                from 'features/permission/require-permission';
import {ScorecardAssignment}                              from '@glimpse/glimpse';
import {DeleteScorecardAssignmentModal}                   from 'features/scorecard-assignment/delete-scorecard-assignment-modal';

export function ScorecardAssignmentTable({
    scorecardId
}: {
    scorecardId: ScorecardAssignment['scorecard_id']
}) {
    const {t}                     = useTranslation();
    const modal                   = useModal();
    const [selected, setSelected] = useState<ScorecardAssignment['id']>();
    const query                   = {
        scorecard_id: scorecardId
    };
    const {loading, data}         = useQuery<ScorecardAssignment>({
        key: ['scorecard-assignment', query],
        fn:  () => glimpse.scorecardAssignment.list(query)
    });

    const deleteAssignment = (id: ScorecardAssignment['id']) => {
        setSelected(id);
        modal.toggle();
    };

    if (loading) {
        return (
            <Box border={'default'} xAlign={'center'} radius={1}>
                <CenteredSpinner/>
            </Box>
        );
    }

    if (data?.length === 0) {
        return (
            <Box border={'default'} pt={4} pb={4} xAlign={'center'} radius={1}>
                <Text color='text.subtle'>{t('Not assigned to any components')}</Text>
            </Box>
        );
    }

    return (
        <RequirePermission permission={'components.read'} fallback>
            <Box border={'default'} radius={1}>
                <Table>
                    <DeleteScorecardAssignmentModal open={modal.open} onClose={modal.toggle} scorecardAssignmentId={selected!}/>
                    <Table.Body>
                        {
                            data?.map(assignment =>
                                <Reveal>
                                    <Table.Row effects={['hoverable']} key={assignment.id}>
                                        <Table.BodyCell>
                                            {assignment.match_type === 'all' && t('All Components')}
                                            {assignment.match_type === 'component_id' && `Component ${assignment.match_value}`}
                                            {assignment.match_type === 'component_type' && `Components of type "${assignment.match_value}"`}
                                        </Table.BodyCell>
                                        <Table.BodyCell shrink>
                                            <Reveal.Hidden>
                                                <Button spacing='compact' icon='far fa-xmark' onClick={() => deleteAssignment(assignment.id)}/>
                                            </Reveal.Hidden>
                                        </Table.BodyCell>
                                    </Table.Row>
                                </Reveal>
                            )
                        }
                    </Table.Body>
                </Table>
            </Box>
        </RequirePermission>
    );
}