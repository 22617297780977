import {Modal, Button, Form} from '@glimpse/glass';
import {useForm}             from 'foundations/ui/form/use-form';
import {glimpse}             from 'providers/glimpse/glimpse-provider';
import {ComponentPipeline}   from '@glimpse/glimpse';
import {useTranslation}      from 'foundations/i18n/use-translation';
import toast                 from 'react-hot-toast';
import {useQuery}            from 'foundations/net/use-query';
import {useMutate}           from 'foundations/net/use-mutate';
import {SelectPipeline}      from 'features/pipeline/select-pipeline';

export function CreateComponentPipelineModal({
    open,
    onClose,
    componentId
}: {
    open: boolean,
    componentId: ComponentPipeline['component_id'],
    onClose: () => void
}) {
    const {t}                      = useTranslation();
    const form                     = useForm<ComponentPipeline.Create.Params>({component_id: componentId});
    const pipelines                = useQuery<ComponentPipeline>({
        key: ['component-pipeline', componentId],
        fn:  () => glimpse.componentPipeline.list({component_id: componentId})
    });
    const {error, loading, submit} = useMutate({
        key:       ['component-pipeline', componentId],
        fn:        () => glimpse.componentPipeline.create(form.data),
        onSuccess: () => {
            toast.success(t('Pipeline added'));
            onClose();
        }
    });

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header title={t('Add Pipeline')}/>
            <Modal.Body>
                <Form onSubmit={submit}>
                    <Form.Field errors={error?.params.pipeline_id}>
                        <SelectPipeline {...form.register('pipeline_id')} exclude={pipelines.data?.map(pipe => pipe.pipeline_id) ?? []}/>
                    </Form.Field>
                </Form>
            </Modal.Body>
            <Modal.Footer right={
                <>
                    <Button onClick={onClose}>{t('Cancel')}</Button>
                    <Button variant='primary' loading={loading} onClick={submit}>{t('Add')}</Button>
                </>
            }/>
        </Modal>
    );
}