import {Modal, Button, Text} from '@glimpse/glass';
import {Scaffold}       from '@glimpse/glimpse';
import {glimpse}        from 'providers/glimpse/glimpse-provider';
import {useTranslation} from 'foundations/i18n/use-translation';
import toast                 from 'react-hot-toast';
import {useMutate}           from 'foundations/net/use-mutate';
import {FormFallbackError}   from 'foundations/ui/form/form-fallback-error';

export function DeleteScaffoldModal({
    open,
    onClose,
    scaffoldId
}: {
    open: boolean,
    onClose: () => void,
    scaffoldId: Scaffold['id'],
}) {
    const {t}                      = useTranslation();
    const {submit, loading, error} = useMutate({
        key:       ['scaffold'],
        fn:        () => glimpse.scaffold.delete(scaffoldId),
        onSuccess: () => {
            toast.success(t('Scaffold deleted'));
            onClose();
        }
    });

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header title={t('Delete Scaffold')}/>
            <Modal.Body>
                <FormFallbackError error={error}/>
                <Text>
                    {t('Are you sure you want to delete this scaffold?')}
                </Text>
            </Modal.Body>
            <Modal.Footer right={
                <>
                    <Button onClick={onClose}>{t('Cancel')}</Button>
                    <Button variant='destructive' loading={loading} onClick={submit}>{t('Delete')}</Button>
                </>
            }/>
        </Modal>
    );
}