import {Scaffold}                                                             from '@glimpse/glimpse';
import {Grid, Box, Text, useModal, Icon, Flex, Button, Reveal, ConfinedClick} from '@glimpse/glass';
import {ScaffoldModal}                                                        from 'features/scaffold/scaffold-modal';
import {useState}                                                             from 'react';
import React     from 'react';
import {glimpse} from 'providers/glimpse/glimpse-provider';
import {Empty}   from 'foundations/ui/empty/empty';
import {UpdateScaffoldModal}                                                  from 'features/scaffold/update-scaffold-modal';
import {DeleteScaffoldModal}                                                  from 'features/scaffold/delete-scaffold-modal';
import {useTranslation}                                                       from 'foundations/i18n/use-translation';
import {ObjectUtil}                                                           from 'foundations/ui/object-util/object-util';
import {CenteredSpinner}                                                      from 'foundations/ui/interstitial/centered-spinner';
import {useQuery}                                                             from 'foundations/net/use-query';
import {RequirePermission}                                                    from 'features/permission/require-permission';

export function ScaffoldGrid({
    query = {}
}: {
    query?: Scaffold.List.Params,
}) {
    const {t}                     = useTranslation();
    const [selected, setSelected] = useState<Scaffold['id']>();
    const viewModal               = useModal();
    const updateModal             = useModal();
    const deleteModal             = useModal();
    const {data, loading}         = useQuery<Scaffold>({
        key: ['scaffold', query],
        fn:  () => glimpse.scaffold.list(query)
    });

    if (loading) {
        return <CenteredSpinner/>;
    }

    if (data?.length === 0) {
        return ObjectUtil.isEmpty(query)
            ? <Empty
                title={t('Add your first scaffold')}
                icon={<Icon fa={['far', 'fa-box-open']}/>}
                desc={t('Use scaffolds to quickly start new projects or components. Create your first scaffold and it will appear here.')}/>
            : <Empty
                title={t('No scaffolds matching filters')}
                icon={<Icon fa={['far', 'fa-box-open']}/>}
                desc={t('Change your filters or search query to find other scaffolds.')}/>;
    }

    const viewScaffold = (id: Scaffold['id']) => {
        setSelected(id);
        viewModal.toggle();
    };

    const openUpdate = (id: Scaffold['id']) => {
        setSelected(id);
        updateModal.toggle();
    };

    const openDelete = (id: Scaffold['id']) => {
        setSelected(id);
        deleteModal.toggle();
    };

    return (
        <RequirePermission permission={'scaffolds.read'} fallback>
            {
                selected !== undefined &&
                <>
                    <ScaffoldModal onClose={viewModal.toggle} open={viewModal.open} scaffoldId={selected}/>
                    <UpdateScaffoldModal open={updateModal.open} onClose={updateModal.toggle} scaffoldId={selected}/>
                    <DeleteScaffoldModal open={deleteModal.open} onClose={deleteModal.toggle} scaffoldId={selected}/>
                </>
            }
            <Grid gap={2} xs={12} md={4} lg={3}>
                {
                    data?.map(scaffold =>
                        <Reveal>
                            <div onClick={() => viewScaffold(scaffold.id)}>
                                <Box p={3} radius={1} border={'default'} effects={['clickable', 'hoverable']}>
                                    <Box pb={0.5}>
                                        <Flex justify={'between'}>
                                            <Text fontWeight='bold'>{scaffold.name}</Text>
                                            <Reveal.Hidden>
                                                <ConfinedClick>
                                                    <Button onClick={() => openUpdate(scaffold.id)} spacing={'compact'} icon='far fa-pen'/>
                                                    <Button onClick={() => openDelete(scaffold.id)} spacing={'compact'} icon='far fa-trash'/>
                                                </ConfinedClick>
                                            </Reveal.Hidden>
                                        </Flex>
                                    </Box>
                                    <Text color='text.subtle'>{scaffold.description ?? 'No Description'}</Text>
                                </Box>
                            </div>
                        </Reveal>
                    )
                }
            </Grid>
        </RequirePermission>
    );
}