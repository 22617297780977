import React, {useContext} from 'react';
import classNames          from 'classnames';
import {Link, LinkProps}   from 'react-router-dom';
import {Icon}              from '@glimpse/glass';
import styles              from 'foundations/layouts/app-layout/app-glowbar-menu.module.css';
import {useTranslation}    from 'foundations/i18n/use-translation';
import {AuthContext}       from 'features/auth/auth-context';

export function AppGlowbarMenu() {
    const {t}  = useTranslation();
    const auth = useContext(AuthContext);

    return (
        <div className={styles.menu}>
            <ItemGroup>
                <Item
                    label={t('Home')}
                    icon={'house'}
                    href='/'/>
            </ItemGroup>
            <ItemGroup>
                <Item
                    label={t('Components')}
                    icon={'server'}
                    href='/components'/>
                <Item
                    label={t('Scorecards')}
                    icon={'list-check'}
                    href='/scorecards'/>
                <Item
                    label={t('Scaffolds')}
                    icon={'box-open'}
                    href='/scaffolds'/>
            </ItemGroup>
            <ItemGroup>
                <Item
                    label={t('Repos')}
                    icon={'code-branch'}
                    href='/repos'/>
                <Item
                    label={t('PRs')}
                    icon={'code-pull-request'}
                    href='/pull-requests'/>
                <Item
                    label={t('Pipelines')}
                    icon={'arrows-repeat'}
                    href='/pipelines'/>
            </ItemGroup>
            <ItemGroup>
                <Item
                    label={t('Apps')}
                    icon={'boxes-stacked'}
                    href='/apps'/>
                <Item
                    label={t('Teams')}
                    icon={'sitemap'}
                    href='/teams'/>
                <Item
                    label={t('People')}
                    icon={'user-group'}
                    href='/people'/>
            </ItemGroup>
            <div className={styles.bottom}>
                <Item
                    label={t('Insights')}
                    icon={'bolt'}
                    href='/insights'/>
                <Item
                    label={t('Give Feedback')}
                    icon={'megaphone'}
                    target={'_blank'}
                    href={`https://forms.fillout.com/t/3t6AhMnNeaus?name=${auth.user.name}&email=${auth.user.email}`}/>
                {
                    (auth.subscription.status === 'trialing' ||
                        auth.subscription.status === 'active') &&
                    <Item
                        label={t('Upgrade Now')}
                        icon={'circle-up'}
                        href='/settings/billing'/>
                }
                {
                    auth.subscription.status === 'canceled' &&
                    <Item
                        label={t('Renew Now')}
                        icon={'rotate-right'}
                        href='/settings/billing'/>
                }
                <Item
                    label={t('Settings')}
                    icon={'cog'}
                    href='/settings'/>
            </div>
        </div>
    );
}


export function Item({
    label,
    href,
    onClick,
    icon,
    target = '_self'
}: {
    label: string,
    href: string,
    onClick?: () => void,
    icon: string,
    target?: LinkProps['target']
}) {

    let active = false;

    if (href === '/') {
        active = window.location.pathname === '/';

    } else if (href.startsWith('/settings')) {
        if (href === '/settings/billing/plans') {
            active = false;
        } else {
            active = window.location.pathname.startsWith(href);
        }
    } else {
        active = window.location.pathname.startsWith(href);
    }

    const classes = classNames(styles.item, {
        [styles.active]: active
    });

    return (
        <Link to={onClick ? '' : href} className={classes} onClick={onClick} target={target}>
            <Icon fa={['far', icon]} className={styles.itemIcon} fontSize={3}/>
            <span className={styles.itemLabel}>{label}</span>
        </Link>
    );
}

export function ItemGroup({children}: { children: React.ReactNode }) {
    return (
        <div className={styles.group}>
            {children}
        </div>
    );
}