import {Modal, Button, Form, Text, Textbox, Box, Alert, Icon, ShowMore} from '@glimpse/glass';
import {useForm}                                                        from 'foundations/ui/form/use-form';
import {Link}                                                           from '@glimpse/glimpse';
import {FormFallbackError}                                              from 'foundations/ui/form/form-fallback-error';
import {glimpse}                                                        from 'providers/glimpse/glimpse-provider';
import {useTranslation}                                                 from 'foundations/i18n/use-translation';
import toast                                                            from 'react-hot-toast';
import {useMutate}                                                      from 'foundations/net/use-mutate';

export function CreateLinkModal({
    open,
    componentId,
    onClose
}: {
    open: boolean,
    componentId: Link['component_id'],
    onClose: () => void
}) {
    const {t}                      = useTranslation();
    const form                     = useForm<Link.Create.Params>({
        component_id: componentId
    });
    const {submit, error, loading} = useMutate<Link>({
        key:       ['link'],
        fn:        () => glimpse.link.create(form.data),
        onSuccess: () => {
            toast.success(t('Link added'));
            onClose();
        }
    });

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header title={t('Create Link')}/>
            <Modal.Body>
                <Box mt={-1} mb={3}>
                    <Text
                        color='text.subtle'>{t('Create links to any external tool, like a Jira Board, Github Repo or your documentation.')}</Text>
                </Box>
                <Form onSubmit={submit}>
                    <FormFallbackError error={error}/>
                    <Form.Field label={t('Url')} errors={error?.params.url}>
                        <Textbox {...form.register('url')} placeholder='https://www.jira.com/board/123'/>
                    </Form.Field>
                    <Form.Field label={t('Custom Label (optional)')} errors={error?.params.label} description={t('Supply a custom label instead of using dynamically generated labels')}>
                        <Textbox {...form.register('label')} placeholder={'Jira'}/>
                    </Form.Field>
                </Form>
            </Modal.Body>
            <Modal.Footer right={
                <>
                    <Button onClick={onClose}>{t('Cancel')}</Button>
                    <Button variant='primary' loading={loading} onClick={submit}>{t('Add')}</Button>
                </>
            }/>
        </Modal>
    );
}