import {GlassChangeEvent, Box, Button, Filter, Popover, Spaced, usePopover, Select} from '@glimpse/glass';
import {useTranslation}                                                             from 'foundations/i18n/use-translation';
import {useMemo}                                                                    from 'react';

export function StaticFilter({
    options,
    value,
    onChange,
    name,
    label,
    loading
}: {
    options: { label: string, value: any }[]
    value: any,
    onChange: (event: GlassChangeEvent) => void,
    name: string,
    label: string,
    loading?: boolean
}) {
    const {t}        = useTranslation();
    const controller = usePopover();

    const menu = (
        <Box p={2}>
            <Spaced gap={1} direction={'vertical'}>
                <Select options={options} value={value} name={name} onChange={onChange}/>
                <Button variant={'primary'} fullwidth onClick={controller.toggle}>{t('Done')}</Button>
            </Spaced>
        </Box>
    );

    const tempValue = useMemo(() => {
        return options.find((opt) => opt.value === value);
    }, [value, JSON.stringify(options)]);

    return (
        <Popover
            open={controller.open}
            onClose={controller.toggle}
            content={menu}>
            <div onClick={controller.toggle}>
                <Filter label={label} name={name} value={tempValue?.label} onChange={onChange}/>
            </div>
        </Popover>
    );
}