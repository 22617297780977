import {Modal, Button, Form, Textbox, Box, Text, Select} from '@glimpse/glass';
import {Property, PropertyValue}                         from '@glimpse/glimpse';
import {useForm}                                         from 'foundations/ui/form/use-form';
import {glimpse}                                         from 'providers/glimpse/glimpse-provider';
import {LoadingModal}                                    from 'foundations/ui/loading-modal/loading-modal';
import {useTranslation}                                  from 'foundations/i18n/use-translation';
import toast                                             from 'react-hot-toast';
import {useQuery}                                        from 'foundations/net/use-query';
import {useMutate}                                       from 'foundations/net/use-mutate';
import {useQueryClient}                                  from '@tanstack/react-query';

export function CreatePropertyValueModal({
    open,
    onClose,
    propertyId,
    componentId
}: {
    open: boolean,
    onClose: () => void,
    propertyId: PropertyValue['property_id'],
    componentId: PropertyValue['component_id']
}) {
    const {t}         = useTranslation();
    const queryClient = useQueryClient();
    const form        = useForm<PropertyValue.Create.Params>({
        component_id: componentId,
        property_id:  propertyId
    });

    const load = useQuery<Property>({
        key: ['property', propertyId],
        fn:  () => glimpse.property.list({
            id: propertyId
        })
    });

    const {error, loading, submit} = useMutate<PropertyValue>({
        key:       ['property-value'],
        fn:        () => glimpse.propertyValue.create(form.data),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['property']});
            toast.success(t('Property updated'));
            onClose();
        }
    });

    if (load.loading) {
        return <LoadingModal onClose={onClose} open={open}/>;
    }

    const options = load.data?.[0].options?.split(',').map((opt) => ({
        label: opt.trim(),
        value: opt.trim()
    }));

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header title={load.data?.[0].label ?? t('Field')}/>
            <Modal.Body>
                {
                    load.data?.[0].description &&
                    <Box mb={3} mt={-1}>
                        <Text color='text.subtle'>{load.data?.[0].description}</Text>
                    </Box>
                }
                <Form>
                    <Form.Field errors={error?.params.value} label={t('Value')}>
                        {
                            load.data?.[0]?.format === 'select' &&
                            <Select {...form.register('value')} options={options ?? []}/>
                        }
                        {
                            load.data?.[0]?.format === 'text' &&
                            <Textbox {...form.register('value')} placeholder={t('My Value')}/>
                        }
                    </Form.Field>
                    {
                        load.data?.[0]?.format === 'text' &&
                        <Form.Field errors={error?.params.link} label={t('URL')} description={t('URL to go to when this value is clicked')}>
                            <Textbox {...form.register('url')} placeholder={'https://example.com/my-value'}/>
                        </Form.Field>
                    }
                </Form>
            </Modal.Body>
            <Modal.Footer right={
                <>
                    <Button onClick={onClose}>{t('Cancel')}</Button>
                    <Button variant='primary' loading={loading} onClick={submit}>{t('Save')}</Button>
                </>
            }/>
        </Modal>
    );
}