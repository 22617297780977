import {Box}            from '@glimpse/glass';
import styles           from 'foundations/layouts/auth-layout/auth-layout.module.css';
import React            from 'react';
import lockup           from 'foundations/brand/lockup_dark.svg';
import {Link}           from 'react-router-dom';
import {useTranslation} from 'foundations/i18n/use-translation';

export function AuthLayout({
    children
}: {
    children: React.ReactNode
}) {
    const {t} = useTranslation();
    return (
        <>
            <div className={styles.background}/>
            <div className={styles.content}>
                <div className={styles.logoArea}>
                    <Link to={'https://www.withglimpse.com'}>
                        <img className={styles.logo} src={lockup}/>
                    </Link>
                </div>
                <Box p={6} radius={2} className={styles.body}>
                    {children}
                </Box>
            </div>
            <div className={styles.footer}>
                <Link className={styles.footerLink} to={'https://www.withglimpse.com/terms'} target={'_blank'}>
                    {t('Term of Use')}
                </Link>
                <Link className={styles.footerLink} to={'https://www.withglimpse.com/privacy'} target={'_blank'}>
                    {t('Privacy Policy')}
                </Link>
            </div>
        </>
    );
}