import {Alert, Box} from '@glimpse/glass';
import {Response}   from '@glimpse/glimpse';

export function FormFallbackError({
    error
}: {
    error: Response['error']
}) {
    if (typeof error === 'undefined' || Object.keys(error.params).length > 0) {
        return null;
    }

    return (
        <Box mb={2}>
            <Alert bg={'danger.subtle'}>
                {error.message}
            </Alert>
        </Box>
    );
}