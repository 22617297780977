import {RepositoryTable}       from 'features/repository/repository-table';
import {Empty}                 from 'foundations/ui/empty/empty';
import {Box, Grid, Icon, Text} from '@glimpse/glass';
import {AppLayout}             from 'foundations/layouts/app-layout/app-layout';
import React                   from 'react';
import {useTranslation}        from 'foundations/i18n/use-translation';
import {useForm}               from 'foundations/ui/form/use-form';
import {Searchbox}             from 'foundations/ui/searchbox/searchbox';
import {AutoFilter}            from 'foundations/ui/filter/auto-filter';

export function RepositoriesScreen() {
    const {t}   = useTranslation();
    const form  = useForm();
    const empty = <Empty
        icon={<Icon fa={['far', 'fa-code-fork']}/>}
        title={t('Connect your repositories')}
        desc={t('Repositories will show up here after connecting your Git provider')}/>;

    return (
        <AppLayout title={t('Repositories')}>
            <AppLayout.Header>
                <Box pt={3} pb={3}>
                    <Text fontSize={4} fontWeight='bolder'>{t('Repositories')}</Text>
                </Box>
            </AppLayout.Header>
            <AppLayout.Body>
                <Box mb={1.5}>
                    <Searchbox {...form.register('query')}/>
                </Box>
                <Box mb={2}>
                    <Grid gap={1}>
                        <AutoFilter label={t('Owner')} resource={'repository'} property={'namespace_name'} {...form.register('namespace_name')}/>
                        <AutoFilter label={t('Topics')} resource={'repository'} property={'topics'} {...form.register('topics')}/>
                        <AutoFilter label={t('Languages')} resource={'repository'} property={'languages'} {...form.register('languages')}/>
                    </Grid>
                </Box>
                <RepositoryTable empty={empty} query={form.data}/>
            </AppLayout.Body>
        </AppLayout>
    );
}