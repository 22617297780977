import {GlassChangeEvent} from '@glimpse/glass';
import {glimpse}          from 'providers/glimpse/glimpse-provider';
import {FilterValue}      from '@glimpse/glimpse';
import {useMemo}          from 'react';
import {StaticFilter}     from 'foundations/ui/filter/static-filter';
import {useQuery}         from 'foundations/net/use-query';

export function AutoFilter({
    resource,
    property,
    value,
    onChange,
    name,
    label
}: {
    resource: FilterValue.List.Params['resource'],
    property: FilterValue.List.Params['property'],
    value: any,
    onChange: (event: GlassChangeEvent) => void,
    name: string,
    label: string
}) {
    const {data, loading} = useQuery<FilterValue>({
        key: ['filter-value', resource, property],
        fn:  () => glimpse.filterValue.list({resource, property})
    });

    const options = useMemo(() => (data ?? []).map(option => ({
        label: option.label,
        value: option.value
    })), [data]);


    return <StaticFilter loading={loading} options={options} value={value} onChange={onChange} name={name} label={label}/>;
}