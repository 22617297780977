import {AppLayout} from 'foundations/layouts/app-layout/app-layout';
import styles      from 'features/pull-request/pull-request-screen.module.css';

export function PullRequestScreen() {
    return (
        <AppLayout>
            <div className={styles.wrapper}>
                <div className={styles.code}>

                </div>
            </div>
        </AppLayout>
    );
}