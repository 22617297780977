import {Text, Box, Icon} from '@glimpse/glass';
import {AuthLayout}      from 'foundations/layouts/auth-layout/auth-layout';
import {useTranslation}  from 'foundations/i18n/use-translation';

export function PasswordResetSentScreen() {
    const {t} = useTranslation();

    return (
        <AuthLayout>
            <Box xAlign={'center'}>
                <Box mb={4}>
                    <Icon fa={['fas', 'fa-paper-plane']} fontSize={6} color={'secondary'}/>
                </Box>
                <Box mb={4}>
                    <Text fontSize={3} fontWeight='bold'>{t('We\'ve sent you an email with instructions. Please check your inbox')}</Text>
                </Box>
            </Box>
        </AuthLayout>
    );
}