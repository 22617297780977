import {User}  from '@glimpse/glimpse';

export class HubspotProvider {

    public static setIdentity(user: User) {
        if (window._hsq) {
            window._hsq.push([
                'identify',
                {
                    id: user.id,
                    email: user.email,
                    name: user.name,
                }
            ])
        }
    }

}