import {Modal, Text, Box} from '@glimpse/glass';
import {useTranslation}   from 'foundations/i18n/use-translation';

export function ErrorModal({
    onClose,
    open
}: {
    onClose: () => void,
    open: boolean
}) {
    const {t} = useTranslation();
    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Body>
                <Box pt={2} pb={2}>
                    <Text>{t('An unexpected error occurred. Please wait a few moments and try again.')}</Text>
                </Box>
            </Modal.Body>
        </Modal>
    );
}