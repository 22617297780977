import {BoxedSelector}       from '@glimpse/glass';
import {SelectProps}         from '@glimpse/glass';
import {ScorecardAssignment} from '@glimpse/glimpse';
import {useTranslation}      from 'foundations/i18n/use-translation';

export function ScorecardAssignmentTypeBoxSelector(props: Omit<SelectProps, 'options'>) {
    const {t} = useTranslation();

    const options: {
        label: string,
        value: ScorecardAssignment['match_type']
    }[] = [
        {
            value: 'all',
            label: t('All Components')
        },
        {
            value: 'component_type',
            label: t('Component Type')
        },
        {
            value: 'component_id',
            label: t('Single Component')
        }
    ];

    return <BoxedSelector {...props} options={options}/>;
}