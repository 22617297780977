import {Box, Text, Grid} from '@glimpse/glass';
import React             from 'react';
import icon              from 'foundations/brand/lockup_dark.svg';
import styles            from 'features/integration/create-integration-layout.module.css';
import {useTranslation}  from 'foundations/i18n/use-translation';

export function CreateIntegrationLayout({
    children,
    label
}: {
    children: React.ReactNode,
    label: string
}) {
    const {t} = useTranslation();
    return (
        <div>
            <div className={styles.bg}/>
            <Box p={4} className={styles.wrapper}>
                <Box mb={4}>
                    <Grid justify={'between'} align={'center'}>
                        <img src={icon} className={styles.logo}/>
                        <Text fontWeight={'bolder'} fontSize={4}>{`Integrate ${label}`}</Text>
                    </Grid>
                </Box>
                <Box mb={4} radius={1} className={styles.panel} p={4} border={'default'}>
                    {children}
                </Box>
                <Box mt={4} className={styles.note}>
                    <Text color={'n60'}>{t('This screen is part of Glimpse integration setup.')}</Text>
                </Box>
            </Box>
        </div>
    );
}