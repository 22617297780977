import React, {useContext, useMemo} from 'react';
import styles                       from 'features/permission/require-permission.module.css';
import {Box, Flex, Icon, Text}      from '@glimpse/glass';
import {useTranslation}             from 'foundations/i18n/use-translation';
import {AuthContext}                from 'features/auth/auth-context';

export function RequirePermission({
    children,
    permission,
    fallback = false,
}: {
    children: React.ReactNode | React.ReactNode[],
    permission: string,
    fallback?: boolean | React.ReactElement,
}): React.ReactElement | null {
    const {t}               = useTranslation();
    const auth              = useContext(AuthContext);
    const permissionGranted = useMemo(
        () => auth.role.permissions.includes(permission),
        [permission, auth.role.permissions]
    );

    if (permissionGranted) {
        return <>{children}</>;
    } else if (fallback === true) {
        return (
            <div className={styles.require}>
                <Flex justify={'center'}>
                    <div>
                        <Box mb={1} mt={12}>
                            <Box mb={2} p={1.5} display='inline-block' bg='neutral.subtle' className={styles.icon} radius={1}>
                                <Icon fa={['far', 'fa-lock']} fontSize={5}/>
                            </Box>
                            <Text fontSize={2} fontWeight={'bold'}>{t('You don\'t have permission to view this.')}</Text>
                        </Box>
                        <Text color={'text.subtle'}>{t('Please contact us or your admin if you believe this is a mistake.')}</Text>
                    </div>
                </Flex>
            </div>
        );
    } else if (fallback) {
        return fallback;
    } else {
        return null;
    }
}