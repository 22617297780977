import {Badge, Box, Flex, Grid, Icon, Separator, Text} from '@glimpse/glass';
import {BillingProduct}                                from '@glimpse/glimpse';
import {glimpse}                                       from 'providers/glimpse/glimpse-provider';
import {CenteredSpinner}                               from 'foundations/ui/interstitial/centered-spinner';
import {useContext}                                    from 'react';
import {AuthContext}                                   from 'features/auth/auth-context';
import {BillingProductMeta}                            from 'features/billing-product/billing-product-meta';
import {useTranslation}                                from 'foundations/i18n/use-translation';
import {useQuery}                                      from 'foundations/net/use-query';
import {RequirePermission}                             from 'features/permission/require-permission';

export function BillingPlanGrid() {
    const available = useQuery<BillingProduct>({
        key: ['billing-plan'],
        fn:  glimpse.billingProduct.list
    });

    if (available.loading) {
        return <CenteredSpinner/>;
    }

    return (
        <RequirePermission permission={'billing.read'} fallback>
            <Grid gap={2} xs={12} md={4} align={'stretch'}>
                {
                    available.data
                        ?.sort((a, b) => BillingProductMeta[a.name].priority > BillingProductMeta[b.name].priority ? 1 : -1)
                        .map((product) => <Plan product={product}/>)}
            </Grid>
        </RequirePermission>
    );
}

function Plan({
    product
}: {
    product: BillingProduct
}) {
    const {t}              = useTranslation();
    const {subscription}   = useContext(AuthContext);
    const purchasedProduct = subscription.items![0];
    const price            = product.prices[0];

    return (
        <Box radius={1} border='default' p={4}>
            <Box mb={0.5}>
                <Flex justify={'between'} align={'center'} gap={1}>
                    <Text fontWeight={'bold'} fontSize={3}>{product.name}</Text>
                    {
                        purchasedProduct.name === product.name &&
                        <Badge color={'info.subtle'} text={t('Current')}/>
                    }
                </Flex>
            </Box>
            <Box mb={6}>
                <Text color={'text.subtle'}>{BillingProductMeta[product.name].desc}</Text>
            </Box>
            <Box mb={6}>
                <Text fontSize={6} fontWeight={'bold'}>${parseFloat(price.unit_amount.toFixed(0))}</Text>
                <Text color={'text.subtle'}>{t('per person/month')}</Text>
            </Box>
            <Box mt={4} mb={4}>
                <Separator/>
            </Box>
            <Box mb={2}>
                <Text fontWeight={'bold'}>{t('Highlights')}:</Text>
            </Box>
            {
                BillingProductMeta[product.name].features.map(feat =>
                    <Box mb={1} key={feat}>
                        <Box display={'inline-block'} mr={2}>
                            <Icon fa={['fas', 'check']} color={'success'}/>
                        </Box>
                        <Box display={'inline-block'}>
                            <Text>
                                {feat}
                            </Text>
                        </Box>
                    </Box>
                )
            }
        </Box>
    );
}