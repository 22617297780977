import {Modal, Button, Box, Text, Flex, Reveal, useModal, Icon} from '@glimpse/glass';
import {ErrorModal}                                             from 'foundations/ui/error-modal/error-modal';
import {LoadingModal}                                           from 'foundations/ui/loading-modal/loading-modal';
import React, {useState}                                        from 'react';
import {Component, IntegrationApp}                              from '@glimpse/glimpse';
import {glimpse}                                                from 'providers/glimpse/glimpse-provider';
import {useTranslation}                                         from 'foundations/i18n/use-translation';
import {useQuery}                                               from 'foundations/net/use-query';
import {IntegrationHelper}                                      from 'features/integration/integration-helper';
import {DeleteIntegrationAppModal}                              from 'features/integration-app/delete-integration-app-modal';
import {CreateIntegrationAppModal}                              from 'features/integration-app/create-integration-app-modal';

export function ManageIntegrationAppModal({
    open,
    onClose,
    componentId
}: {
    open: boolean,
    onClose: () => void,
    componentId: Component['id']
}) {
    const {t}                     = useTranslation();
    const [selected, setSelected] = useState<IntegrationApp['id']>();
    const deleteModal             = useModal();
    const createModal             = useModal();
    const {data, loading, error}  = useQuery<IntegrationApp>({
        key: ['integration-app', {component_id: componentId}],
        fn:  async () => await glimpse.integrationApp.list({component_id: componentId})
    });

    if (loading)
        return <LoadingModal onClose={onClose} open={open}/>;

    if (error)
        return <ErrorModal onClose={onClose} open={open}/>;


    const del = (id: string) => {
        setSelected(id);
        deleteModal.toggle();
    };

    return (
        <>
            <CreateIntegrationAppModal component_id={componentId} open={createModal.open} onClose={createModal.toggle}/>
            {
                selected &&
                <>
                    <DeleteIntegrationAppModal open={deleteModal.open} onClose={deleteModal.toggle} integrationAppId={selected}/>
                </>
            }
            <Modal onClose={onClose} open={open}>
                <Modal.Header title={t('App')}/>
                <Modal.Body>
                    {
                        IntegrationHelper.listAll()
                            .filter((integration) => integration.capabilities?.apps)
                            .map((integration) => {
                                const installed = data.find((install) => install.source_connector === integration.connector);

                                return (
                                    <Reveal>
                                        <Box mb={1} p={2} border={'default'} radius={1} bg={integration.capabilities?.apps ? 'n0' : 'n20'}>
                                            <Flex align={'center'} justify={'between'}>
                                                <Flex align={'center'}>
                                                    <Box mr={1}>
                                                        <img height={20} src={integration.icon}/>
                                                    </Box>
                                                    <Text>{integration.label}</Text>
                                                </Flex>
                                                <Flex align={'center'} gap={1.5}>
                                                    {
                                                        installed &&
                                                        <>
                                                            <Reveal.Hidden>
                                                                <div>
                                                                    <Button onClick={() => del(installed.id)} icon={'far fa-trash'} spacing={'compact'}/>
                                                                    <Button icon={'far fa-pen'} spacing={'compact'}/>
                                                                </div>
                                                            </Reveal.Hidden>
                                                            <Flex gap={0.5} align={'center'}>
                                                                <Icon fa={['fas', 'fa-circle']} color={'success'} fontSize={0.25}/>
                                                                <Text color={'text.subtle'}>{t('Connected')}</Text>
                                                            </Flex>
                                                        </>
                                                    }
                                                    {
                                                        !installed &&
                                                        <Button spacing={'compact'}>{t('Add')}</Button>
                                                    }
                                                </Flex>
                                            </Flex>
                                        </Box>
                                    </Reveal>
                                );
                            })
                    }
                </Modal.Body>
                <Modal.Footer
                    right={
                        <>
                            <Button onClick={onClose}>{t('Close')}</Button>
                        </>
                    }/>
            </Modal>
        </>
    );
}