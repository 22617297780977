import {useModal, Text, Reveal, Grid, Button, Icon, Box, ConfinedClick, Flex} from '@glimpse/glass';
import {UpdateLinkModal}                                                      from 'features/link/update-link-modal';
import React                                                                  from 'react';
import {Link}                                                                 from '@glimpse/glimpse';
import {glimpse}                                                              from 'providers/glimpse/glimpse-provider';
import {useTranslation}                                                       from 'foundations/i18n/use-translation';
import {useQuery}                                                             from 'foundations/net/use-query';
import {useMutate}                                                            from 'foundations/net/use-mutate';
import {RequirePermission}                                                    from 'features/permission/require-permission';
import {CenteredSpinner}                                                      from 'foundations/ui/interstitial/centered-spinner';

export function LinkList({
    query
}: {
    query?: Link.List.Params
}) {
    const {t}             = useTranslation();
    const {data, loading} = useQuery<Link>({
        key: ['link', query],
        fn:  () => glimpse.link.list(query)
    });

    if (loading) {
        return (
            <Box border={'default'} xAlign={'center'} radius={1}>
                <CenteredSpinner/>
            </Box>
        );
    }

    if (data?.length === 0) {
        return (
            <Box border={'default'} pt={4} pb={4} xAlign={'center'} radius={1}>
                <Text color='text.subtle'>{t('No links yet')}</Text>
            </Box>
        );
    }

    return (
        <Box border={'default'} p={2} radius={1}>
            <RequirePermission permission={'links.read'}>
                <Grid gap={1} xs={12}>
                    {
                        data?.map(link => <LinkItem link={link} key={link.id}/>)
                    }
                </Grid>
            </RequirePermission>
        </Box>
    );
}

function LinkItem({
    link
}: {
    link: Link
}) {
    const updateModal = useModal();
    const {submit}    = useMutate({
        key: ['link', link.id],
        fn:  () => glimpse.link.delete(link.id)
    });

    const handleOpen = () => window.open(link.url);

    return (
        <>
            <UpdateLinkModal open={updateModal.open} onClose={updateModal.toggle} linkId={link.id}/>
            <Box ml={-1}>
                <div onClick={handleOpen}>
                    <Reveal>
                        <Box pl={1} pr={0.5} pt={0.5} pb={0.5} effects={['hoverable', 'clickable']} radius={1}>
                            <Flex justify='between' align='center' wrap={'nowrap'}>
                                <Flex align={'center'}>
                                    {
                                        link.parsed.image
                                            ? <img src={link.parsed.image} style={{height: 18}}/>
                                            : <Icon fa={['fas', 'fa-link']} fontSize={2}/>
                                    }
                                    <Box ml={1}>
                                        <Text style={{}}>{link?.label ?? link.parsed.label ?? link.url}</Text>
                                    </Box>
                                </Flex>
                                <Reveal.Hidden>
                                    <ConfinedClick>
                                        <Button spacing='compact' icon={<Icon fa={['far', 'fa-pen']}/>} onClick={updateModal.toggle}/>
                                        <Button spacing='compact' icon={<Icon fa={['far', 'fa-xmark']}/>} onClick={submit}/>
                                    </ConfinedClick>
                                </Reveal.Hidden>
                            </Flex>
                        </Box>
                    </Reveal>
                </div>
            </Box>
        </>
    );
}