import {Box, Button, Flex, Icon, Slideout, Text, useModal} from '@glimpse/glass';
import {User}                                              from '@glimpse/glimpse';
import {useQuery}        from 'foundations/net/use-query';
import {glimpse}         from 'providers/glimpse/glimpse-provider';
import {CenteredSpinner} from 'foundations/ui/interstitial/centered-spinner';
import UserPlaceholderImage                                from 'features/user/user-placeholder-image.jpg';
import style                                               from 'features/user/user-slideout.module.css';
import {useTranslation}                                    from 'foundations/i18n/use-translation';
import {UpdateUserModal}                                   from 'features/user/update-user-modal';
import {useContext}                                        from 'react';
import {AuthContext}                                       from 'features/auth/auth-context';

export function UserSlideout({
    open,
    onClose,
    userId
}: {
    open: boolean,
    onClose: () => void,
    userId: User['id']
}) {
    const updateModal     = useModal();
    const auth            = useContext(AuthContext);
    const {t}             = useTranslation();
    const {data, loading} = useQuery<User>({
        key: ['user', userId],
        fn:  () => glimpse.user.list({id: userId})
    });

    const user = data?.[0];

    return (
        <>
            <UpdateUserModal open={updateModal.open} onClose={updateModal.toggle} userId={userId}/>
            <Slideout onClose={onClose} open={open}>
                <Slideout.Header onClose={onClose} title={t('Profile')}/>
                {
                    loading &&
                    <Slideout.Group>
                        <CenteredSpinner/>
                    </Slideout.Group>
                }
                <Slideout.Group>
                    <Box mb={4}>
                        <img src={UserPlaceholderImage} className={style.image}/>
                    </Box>
                    <Box mb={0.5}>
                        <Text fontWeight={'bolder'} fontSize={4}>{user?.name}</Text>
                    </Box>
                    <Text color={'text.subtle'} fontSize={2}>{user?.title ?? '-'}</Text>
                </Slideout.Group>
                <Slideout.Group>
                    <Box mb={2}>
                        <Flex justify={'between'} align={'center'}>
                            <Text fontWeight={'bolder'} fontSize={2}>{t('Contact Info')}</Text>
                            {
                                user?.id === auth.user.id &&
                                <Button variant={'link'} onClick={updateModal.toggle}>{t('Edit')}</Button>
                            }
                        </Flex>
                    </Box>
                    <Box mb={2}>
                        <Flex gap={2} align={'center'}>
                            <Box p={1.5} bg={'tertiary'} radius={1}>
                                <Icon fa={['far', 'fa-envelope']} fontSize={3}/>
                            </Box>
                            <div>
                                <Text fontWeight={'bold'}>{t('Email')}</Text>
                                <Text>{user?.email}</Text>
                            </div>
                        </Flex>
                    </Box>
                    <Box>
                        <Flex gap={2} align={'center'}>
                            <Box p={1.5} bg={'tertiary'} radius={1}>
                                <Icon fa={['far', 'fa-phone']} fontSize={3}/>
                            </Box>
                            <div>
                                <Text fontWeight={'bold'}>{t('Phone')}</Text>
                                <Text>{t('-')}</Text>
                            </div>
                        </Flex>
                    </Box>
                </Slideout.Group>
            </Slideout>
        </>
    );
}