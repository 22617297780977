import {createRoutesFromChildren, matchRoutes, useNavigationType, useLocation} from 'react-router-dom';
import * as Sentry                                                             from '@sentry/react';
import {useEffect}                                                             from 'react';
import {User}                                                                  from '@glimpse/glimpse';

export class SentryProvider {
    static init() {
        Sentry.init({
            dsn:          'https://812f737298cc4b794d6722ce887717e5@o4505259264049152.ingest.sentry.io/4506373101846528',
            integrations: [
                new Sentry.BrowserTracing({
                    // See docs for support of different versions of variation of React Router
                    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
                    routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                        useEffect,
                        useLocation,
                        useNavigationType,
                        createRoutesFromChildren,
                        matchRoutes
                    )
                }),
                new Sentry.Replay()
            ],

            // Capture 100% of error traces
            tracesSampleRate: 1.0,

            // Capture 10% of session replays
            replaysSessionSampleRate: 0.1,

            // Capture 100% of sessions replays with errors
            replaysOnErrorSampleRate: 1.0
        });
    }

    static setIdentity(user: User) {
        Sentry.setUser({
            email: user.email
        });
    }

    static clearIdentity() {
        Sentry.setUser(null);
    }
}