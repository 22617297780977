import {Modal, Button, Form, Textbox, Textarea, Alert, Box} from '@glimpse/glass';
import {ErrorModal}                                         from 'foundations/ui/error-modal/error-modal';
import {LoadingModal}                                       from 'foundations/ui/loading-modal/loading-modal';
import {useForm}                                            from 'foundations/ui/form/use-form';
import React                                                from 'react';
import {AppListing}                                         from '@glimpse/glimpse';
import {glimpse}                                            from 'providers/glimpse/glimpse-provider';
import {useTranslation}                                     from 'foundations/i18n/use-translation';
import toast                                                from 'react-hot-toast';
import {useMutate}                                          from 'foundations/net/use-mutate';
import {useQuery}                                           from 'foundations/net/use-query';

export function UpdateAppListingModal({
    open,
    onClose,
    appListingId
}: {
    open: boolean,
    onClose: () => void,
    appListingId: AppListing['id']
}) {
    const {t}  = useTranslation();
    const form = useForm<AppListing.Update.Params>();

    const load = useQuery<AppListing>({
        key:       ['app-listing', appListingId],
        fn:        () => glimpse.appListing.list({id: appListingId}),
        onSuccess: (res) => form.setData(res.data[0])
    });

    const update = useMutate<AppListing>({
        key:       ['app-listing', appListingId],
        fn:        () => glimpse.appListing.update(appListingId, form.data),
        onSuccess: () => {
            toast.success(t('App updated'));
            onClose();
        }
    });

    if (load.loading)
        return <LoadingModal onClose={onClose} open={open}/>;

    if (load.error)
        return <ErrorModal onClose={onClose} open={open}/>;

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header title={t('Update App')}/>
            <Modal.Body>
                <Form>
                    <Form.Field label={t('Label')} errors={update.error?.params.label}>
                        <Textbox {...form.register('label')} placeholder={t('Sentry')}/>
                    </Form.Field>
                    <Form.Field label={t('URL')} errors={update.error?.params.url}>
                        <Textbox {...form.register('url')}/>
                    </Form.Field>
                </Form>
            </Modal.Body>
            <Modal.Footer right={
                <>
                    <Button onClick={onClose}>{t('Cancel')}</Button>
                    <Button variant='primary' loading={update.loading} onClick={update.submit}>{t('Save')}</Button>
                </>
            }/>
        </Modal>
    );
}