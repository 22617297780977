import {GlassChangeEvent} from '@glimpse/glass';
import {StaticFilter}     from 'foundations/ui/filter/static-filter';
import {useTranslation}   from 'foundations/i18n/use-translation';
import {Pipeline}         from '@glimpse/glimpse';

export function PipelineEnvironmentFilter({
    value,
    onChange,
    name,
    label
}: {
    value: any,
    onChange: (event: GlassChangeEvent) => void,
    name: string,
    label: string,
}) {
    const {t} = useTranslation();
    const options: {
        label: string,
        value: Exclude<Pipeline['environment'], null>
    }[]       = [
        {
            value: 'production',
            label: t('Production')
        },
        {
            value: 'staging',
            label: t('Staging')
        },
        {
            value: 'testing',
            label: t('Testing')
        },
        {
            value: 'development',
            label: t('Development')
        },
        {
            value: 'other',
            label: t('Other')
        }
    ];

    return <StaticFilter options={options} value={value} onChange={onChange} name={name} label={label}/>;
}