import {User}        from '@glimpse/glimpse';
import {Select}      from '@glimpse/glass';
import {SelectProps} from '@glimpse/glass';
import {glimpse}     from 'providers/glimpse/glimpse-provider';
import {useQuery}    from 'foundations/net/use-query';

export function SelectUser(props: Omit<SelectProps, 'options'> & { exclude?: User['id'][] }) {
    const {data} = useQuery<User>({
        key:        ['user'],
        fn:         () => glimpse.user.list(),
    });

    const options = data
        ?.filter((user) => !props.exclude?.includes(user.id))
        .map(user => {
            return {
                label: user.name,
                value: user.id
            };
        }) ?? [];

    return <Select {...props} options={options}/>;
}