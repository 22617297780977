import {Modal, Button, Form, Textbox, Textarea, Box, Text} from '@glimpse/glass';
import {Scorecard} from '@glimpse/glimpse';
import {useForm}        from 'foundations/ui/form/use-form';
import {glimpse}        from 'providers/glimpse/glimpse-provider';
import {useTranslation} from 'foundations/i18n/use-translation';
import toast                                                              from 'react-hot-toast';
import {useNavigate}                                                      from 'react-router-dom';
import {useMutate}                                                        from 'foundations/net/use-mutate';

export function CreateScorecardModal({
    open,
    onClose
}: {
    open: boolean,
    onClose: () => void
}) {
    const navigate                 = useNavigate();
    const {t}                      = useTranslation();
    const form                     = useForm<Scorecard.Create.Params>();
    const {error, loading, submit} = useMutate<Scorecard>({
        key:       ['scorecards'],
        fn:        async () => await glimpse.scorecard.create(form.data),
        onSuccess: (res) => {
            toast.success(t('Scorecard created'));
            navigate(`/scorecards/${res.data[0].id}`);
            onClose();
        }
    });

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header title={t('Add Scorecard')}/>
            <Modal.Body>
                <Box mt={1} mb={3}>
                    <Text
                        color='text.subtle'>{t('Add a scorecard to begin creating checks against your components and integrated data.')}</Text>
                </Box>
                <Form>
                    <Form.Field label={t('Name')} errors={error?.params.name}>
                        <Textbox {...form.register('name')} placeholder={t('Gold Service Level')}/>
                    </Form.Field>
                    <Form.Field label={t('Description')} errors={error?.params.description}>
                        <Textarea {...form.register('description')}/>
                    </Form.Field>
                </Form>
            </Modal.Body>
            <Modal.Footer right={
                <>
                    <Button onClick={onClose}>{t('Cancel')}</Button>
                    <Button variant='primary' loading={loading} onClick={submit}>{t('Add')}</Button>
                </>
            }/>
        </Modal>
    );
}