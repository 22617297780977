import {Icon, Tooltip}     from '@glimpse/glass';
import React               from 'react';
import {useTranslation}    from 'foundations/i18n/use-translation';

export function ScorecardCheckRunStatus({
    passed
}: {
    passed: boolean | undefined
}) {
    const {t} = useTranslation();

    if (typeof passed === "undefined") {
        return (
            <Tooltip content={t('Check pending')}>
                <Icon fa={['far', 'fa-circle-minus']} color={'n200'}/>
            </Tooltip>
        );
    }

    if (passed) {
        return (
            <Tooltip content={t('Check succeeded')}>
                <Icon fa={['far', 'fa-circle-check']} color={'success'}/>
            </Tooltip>
        );
    }

    if (!passed) {
        return (
            <Tooltip content={t(`Check failed`)}>
                <Icon fa={['far', 'fa-circle-xmark']} color={'danger'}/>
            </Tooltip>
        );
    }

    return (
        <Tooltip content={t('Check hasn\'t run yet')}>
            <Icon fa={['far', 'fa-circle-minus']} color={'n50'}/>
        </Tooltip>
    );
}