import {Modal, Button, Form}                from '@glimpse/glass';
import {SelectComponent}                    from 'features/component/select-component';
import {useForm}             from 'foundations/ui/form/use-form';
import {glimpse}             from 'providers/glimpse/glimpse-provider';
import {ScorecardAssignment} from '@glimpse/glimpse';
import {LoadingModal}                       from 'foundations/ui/loading-modal/loading-modal';
import {useTranslation}                     from 'foundations/i18n/use-translation';
import toast                                from 'react-hot-toast';
import {useQuery}                           from 'foundations/net/use-query';
import {useMutate}                          from 'foundations/net/use-mutate';
import {SelectComponentType}                from 'features/component/select-component-type';
import {FormFallbackError}                  from 'foundations/ui/form/form-fallback-error';
import {ScorecardAssignmentTypeBoxSelector} from 'features/scorecard-assignment/scorecard-assignment-type-box-selector';

export function CreateScorecardAssignmentModal({
    open,
    onClose,
    scorecardId
}: {
    open: boolean,
    scorecardId: ScorecardAssignment['scorecard_id'],
    onClose: () => void
}) {
    const {t}  = useTranslation();
    const form = useForm<ScorecardAssignment.Create.Params>({
        match_type:   'all',
        scorecard_id: scorecardId
    });

    const load = useQuery<ScorecardAssignment>({
        key: ['scorecard-assignment', scorecardId],
        fn:  () => glimpse.scorecardAssignment.list({scorecard_id: scorecardId})
    });

    const save = useMutate<ScorecardAssignment>({
        key:       ['scorecard-assignment'],
        fn:        () => glimpse.scorecardAssignment.create(form.data),
        onSuccess: () => {
            toast.success(t('Assignment created'));
            onClose();
        }
    });

    if (load.loading) {
        return <LoadingModal onClose={onClose} open={open}/>;
    }

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header title={t('Assign Components')}/>
            <Modal.Body>
                <Form onSubmit={save.submit}>
                    <FormFallbackError error={save.error}/>
                    <Form.Field errors={save.error?.params.component_id}>
                        <ScorecardAssignmentTypeBoxSelector {...form.register('match_type')}/>
                    </Form.Field>
                    {
                        form.data.match_type === 'component_id' &&
                        <Form.Field label={t('Component')} errors={save.error?.params.component_id}>
                            <SelectComponent {...form.register('match_value')}/>
                        </Form.Field>
                    }
                    {
                        form.data.match_type === 'component_type' &&
                        <Form.Field label={t('Component Type')} errors={save.error?.params.component_id}>
                            <SelectComponentType {...form.register('match_value')}/>
                        </Form.Field>
                    }
                </Form>
            </Modal.Body>
            <Modal.Footer right={
                <>
                    <Button onClick={onClose}>{t('Cancel')}</Button>
                    <Button variant='primary' loading={save.loading} onClick={save.submit}>{t('Add')}</Button>
                </>
            }/>
        </Modal>
    );
}