import {TeamMember}          from '@glimpse/glimpse';
import {Modal, Button, Text} from '@glimpse/glass';
import {useNavigate}    from 'react-router-dom';
import {glimpse}        from 'providers/glimpse/glimpse-provider';
import {useTranslation} from 'foundations/i18n/use-translation';
import toast                 from 'react-hot-toast';
import {useMutate}           from 'foundations/net/use-mutate';
import {FormFallbackError}   from 'foundations/ui/form/form-fallback-error';

export function DeleteComponentModal({
    open,
    onClose,
    componentId
}: {
    open: boolean,
    onClose: () => void,
    componentId: TeamMember['id'],
}) {
    const {t}               = useTranslation();
    const navigate          = useNavigate();
    const {loading, submit, error} = useMutate({
        key:       ['component', componentId],
        fn:        async () => await glimpse.component.delete(componentId),
        onSuccess: () => {
            toast.success(t('Component deleted'));
            navigate('/components');
        }
    });

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header title={t('Delete Component')}/>
            <Modal.Body>
                <FormFallbackError error={error}/>
                <Text>
                    {t('Are you sure you want to remove this component? This cannot be undone.')}
                </Text>
            </Modal.Body>
            <Modal.Footer right={
                <>
                    <Button onClick={onClose}>{t('Close')}</Button>
                    <Button variant='destructive' loading={loading} onClick={submit}>{t('Delete Component')}</Button>
                </>
            }/>
        </Modal>
    );
}