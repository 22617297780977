import {Select}      from '@glimpse/glass';
import {SelectProps} from '@glimpse/glass';
import {glimpse}     from 'providers/glimpse/glimpse-provider';
import {Component}   from '@glimpse/glimpse';
import {useQuery}    from 'foundations/net/use-query';

export function SelectRole(props: Omit<SelectProps, 'options'>) {
    const {data} = useQuery<Component>({
        key: ['role'],
        fn:  glimpse.role.list
    });

    const options = data
        .map(role => {
            return {
                label: role.name,
                value: role.id
            };
        }) ?? [];

    return <Select {...props} options={options}/>;
}