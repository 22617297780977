import {Integration}                    from '@glimpse/glimpse';
import {Modal, Button, Text, List, Box} from '@glimpse/glass';
import {glimpse}                        from 'providers/glimpse/glimpse-provider';
import {useTranslation}            from 'foundations/i18n/use-translation';
import {useMutate}                 from 'foundations/net/use-mutate';
import {FormFallbackError}         from 'foundations/ui/form/form-fallback-error';

export function DeleteIntegrationModal({
    open,
    onClose,
    integrationId
}: {
    open: boolean,
    onClose: () => void,
    integrationId: Integration['id'],
}) {
    const {t}                      = useTranslation();
    const {loading, submit, error} = useMutate({
        key:       ['integration'],
        fn:        () => glimpse.integration.delete(integrationId),
        onSuccess: onClose
    });

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header title='Uninstall Integration'/>
            <Modal.Body>
                <FormFallbackError error={error}/>
                <Text>
                    {t('Are you sure you want to uninstall this integration? This cannot be undone.')}
                </Text>
                <Box mt={2}>
                    <List>
                        <List.Item>{t('Imported entities will not be removed')}</List.Item>
                        <List.Item>{t('Scorecards using this integration may fail new runs')}</List.Item>
                        <List.Item>{t('Insights, Dashboards and GlimpseQL queries will no longer be able to use data related to this integration ')}</List.Item>
                    </List>
                </Box>
            </Modal.Body>
            <Modal.Footer right={
                <>
                    <Button onClick={onClose}>{t('Close')}</Button>
                    <Button variant='destructive' loading={loading} onClick={submit}>{t('Uninstall')}</Button>
                </>
            }/>
        </Modal>
    );
}