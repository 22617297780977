import {AppLayout}                                                            from 'foundations/layouts/app-layout/app-layout';
import {Alert, Box, Button, Flex, Icon, Image, Tabs, Text, useModal, useTabs} from '@glimpse/glass';
import React                                                                  from 'react';
import {useParams}                                                            from 'react-router-dom';
import {IntegrationList}                                                      from 'features/integration/integration-list';
import {RequirePermission}                                                    from 'features/permission/require-permission';
import {useTranslation}                                                       from 'foundations/i18n/use-translation';
import {IntegrationHelper}                                                    from 'features/integration/integration-helper';

export function SettingsIntegrationScreen() {
    const {connector} = useParams();
    const {t}         = useTranslation();
    const tabs        = useTabs('installs');
    const meta        = IntegrationHelper.get(connector!);

    return (
        <AppLayout>
            <RequirePermission permission={'workspace.write'} fallback>
                <AppLayout.Header>
                    <Box pt={3} pb={3}>
                        <Flex justify={'between'}>
                            <Flex gap={2} align={'center'}>
                                <Image height={'40px'} source={meta?.icon}/>
                                <Box>
                                    <Text fontSize={4} fontWeight='bolder'>{meta?.label}</Text>
                                    <Text color={'text.subtle'}>{t('Manage how this integration interacts with your workspace')}</Text>
                                </Box>
                            </Flex>
                            <div>
                                <RequirePermission permission={'integrations.write'}>
                                    {
                                        tabs.current === 'installs' &&
                                        <Button icon={<Icon fa={['far', 'fa-plus']}/>} variant={'primary'} onClick={meta?.install}>{t('New Install')}</Button>
                                    }
                                </RequirePermission>
                            </div>
                        </Flex>
                    </Box>
                    <Tabs onChange={tabs.change}>
                        <Tabs.Tab value={'installs'} current={tabs.current} label={t('Installs')}/>
                    </Tabs>
                </AppLayout.Header>
                <AppLayout.Body>
                    <Tabs.Panel active={tabs.current === 'installs'}>
                        <IntegrationList query={{connector}}/>
                    </Tabs.Panel>
                </AppLayout.Body>
            </RequirePermission>
        </AppLayout>
    );
}