import {TeamMember}          from '@glimpse/glimpse';
import {Modal, Button, Text} from '@glimpse/glass';
import {glimpse}             from 'providers/glimpse/glimpse-provider';
import {useTranslation}      from 'foundations/i18n/use-translation';
import toast       from 'react-hot-toast';
import {useMutate} from 'foundations/net/use-mutate';
import {FormFallbackError}   from 'foundations/ui/form/form-fallback-error';

export function DeleteTeamMemberModal({
    open,
    onClose,
    teamMemberId
}: {
    open: boolean,
    onClose: () => void,
    teamMemberId: TeamMember['id'],
}) {
    const {t} = useTranslation();
    const {submit, loading, error} = useMutate({
        key:       ['team-member', teamMemberId],
        fn:        () => glimpse.teamMember.delete(teamMemberId),
        onSuccess: () => {
            toast.success(t('Team member removed'));
            onClose();
        }
    });

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header title='Remove Member'/>
            <Modal.Body>
                <FormFallbackError error={error}/>
                <Text>
                    {t('Are you sure you want to remove this person from the team? Anyone can add this member back onto the team.')}
                </Text>
            </Modal.Body>
            <Modal.Footer right={
                <>
                    <Button onClick={onClose}>{t('Cancel')}</Button>
                    <Button variant='destructive' loading={loading} onClick={submit}>{t('Remove')}</Button>
                </>
            }/>
        </Modal>
    );
}