import {Icon, Reveal, Grid, Box, Text, Badge, Flex} from '@glimpse/glass';
import {Role}                                       from '@glimpse/glimpse';
import React                                        from 'react';
import {glimpse}                                    from 'providers/glimpse/glimpse-provider';
import {Empty}                                      from 'foundations/ui/empty/empty';
import {useTranslation}                             from 'foundations/i18n/use-translation';
import {CenteredSpinner}                            from 'foundations/ui/interstitial/centered-spinner';
import {useQuery}                                   from 'foundations/net/use-query';
import {Link}                                       from 'react-router-dom';
import {RequirePermission}                          from 'features/permission/require-permission';

export function RoleTable({
    query = {}
}: {
    query?: Role.List.Params,
}) {
    const {t} = useTranslation();

    const {data, loading} = useQuery<Role>({
        key: ['role', query],
        fn:  () => glimpse.role.list(query)
    });

    if (loading) {
        return <CenteredSpinner/>;
    }

    if (data?.length === 0) {
        return <Empty
            icon={<Icon fa={['far', 'fa-lock-open']}/>}
            title={t('Add Roles')}
            desc={t('Create custom roles to grant tailored permissions across your org')}/>;
    }

    return (
        <RequirePermission permission={'workspace.read'} fallback>
            <Grid gap={1} xs={12}>
                {
                    data?.map(role =>
                        <Reveal>
                            <Link to={`/roles/${role.id}`}>
                                <Box radius={1} border={'default'} p={2} effects={['hoverable', 'clickable']}>
                                    <Flex align={'center'} justify={'between'}>
                                        <Text fontWeight={'bold'}>{role.name}</Text>
                                        {
                                            role.workspace_id === null &&
                                            <Badge color={'neutral.subtle'} text={t('Prebuilt')}/>
                                        }
                                    </Flex>
                                </Box>
                            </Link>
                        </Reveal>
                    )
                }
            </Grid>
        </RequirePermission>
    );
}