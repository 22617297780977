import {Auth, Session}                    from '@glimpse/glimpse';
import {Text, Form, Textbox, Box, Button} from '@glimpse/glass';
import {AuthLayout}                       from 'foundations/layouts/auth-layout/auth-layout';
import {useSearchParams}                  from 'react-router-dom';
import {useForm}                          from 'foundations/ui/form/use-form';
import {glimpse}                          from 'providers/glimpse/glimpse-provider';
import {useTranslation}                   from 'foundations/i18n/use-translation';
import {useMutate}                        from 'foundations/net/use-mutate';
import {useAuth}                          from 'features/auth/use-auth';
import {FormFallbackError}                from 'foundations/ui/form/form-fallback-error';

export function JoinScreen() {
    const {t}            = useTranslation();
    const {signIn}       = useAuth();
    const [searchParams] = useSearchParams();
    const form           = useForm<Auth.Join.Params>({
        token: searchParams?.get('token') ?? ''
    });

    const session = useMutate<Session>({
        key:       ['session'],
        fn:        () => glimpse.auth.signin({
            method:   'password',
            email:    searchParams?.get('email') ?? '',
            password: form.data.password
        }),
        onSuccess: (res) => signIn(res.data[0].token)
    });

    const {loading, error, submit} = useMutate<Session>({
        key:       ['account'],
        fn:        () => glimpse.auth.join(form.data),
        onSuccess: session.submit
    });

    return (
        <AuthLayout>
            <Box xAlign={'center'} mb={6}>
                <Box mb={2}>
                    <Text fontSize={5} fontWeight='bold'>{t('Join Workspace')}</Text>
                </Box>
                <Text fontSize={3} color={'text.subtle'}>{t('You\'ve been invited to join a workspace on Glimpse. Add your profile details to complete setup.')}</Text>
            </Box>
            <Form onSubmit={submit}>
                <FormFallbackError error={error}/>
                <Form.Field label={t('Full Name')} errors={error?.params.name}>
                    <Textbox {...form.register('name')} placeholder='James Smith'/>
                </Form.Field>
                <Form.Field label={t('Password')} errors={error?.params.password}>
                    <Textbox {...form.register('password')} placeholder={t('Password')} type={'password'}/>
                </Form.Field>
            </Form>
            <Box pt={1}>
                <Button fullwidth onClick={submit} variant='primary' spacing='large' loading={loading || session.loading}>{t('Accept Invite')}</Button>
            </Box>
        </AuthLayout>
    );
}