import {Table, Icon, Flex} from '@glimpse/glass';
import React                      from 'react';
import {ScorecardCheckRun}        from '@glimpse/glimpse';
import {Empty}                    from 'foundations/ui/empty/empty';
import {CenteredSpinner}          from 'foundations/ui/interstitial/centered-spinner';
import {glimpse}                  from 'providers/glimpse/glimpse-provider';
import {useTranslation}           from 'foundations/i18n/use-translation';
import {ScorecardCheckRunStatus}  from 'features/scorecard-check-run/scorecard-check-run-status';
import {useQuery}                 from 'foundations/net/use-query';
import {RequirePermission}        from 'features/permission/require-permission';

export function ScorecardCheckRunTable({
    query = {}
}: {
    query?: ScorecardCheckRun.List.Params
}) {
    const {t} = useTranslation();
    const api = useQuery<ScorecardCheckRun>({
        key: ['scorecard-check-run', query],
        fn:  () => glimpse.scorecardCheckRun.list({...query, expand: ['check', 'component']})
    });

    if (api.loading) {
        return <CenteredSpinner/>;
    }

    if (api.data?.length === 0) {
        return <Empty
            icon={<Icon fa={['far', 'fa-list-check']}/>}
            title={t('No run history')}
            desc={t('This scorecard hasn\'t run yet, check back later')}/>;
    }

    return (
        <RequirePermission permission={'scorecards.read'} fallback>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>{t('Check')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('Component')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('Message')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('Run at')}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {
                        api.data?.map(run =>
                            <Table.Row key={run.id}>
                                <Table.BodyCell>
                                    <Flex gap={1} align={'center'}>
                                        <ScorecardCheckRunStatus passed={run.passed}/>
                                        {
                                            run.check?.name ?? `[${t('Deleted check')}]`
                                        }
                                    </Flex>
                                </Table.BodyCell>
                                <Table.BodyCell>
                                    {
                                        run.component?.name
                                    }
                                </Table.BodyCell>
                                <Table.BodyCell>
                                    {
                                        run.message
                                    }
                                </Table.BodyCell>
                                <Table.BodyCell>
                                    {
                                        run.created_at
                                    }
                                </Table.BodyCell>
                            </Table.Row>
                        )
                    }
                </Table.Body>
            </Table>
        </RequirePermission>
    );
}