import {Modal, Button, Form, Textbox} from '@glimpse/glass';
import {useForm}                      from 'foundations/ui/form/use-form';
import {Invite}                       from '@glimpse/glimpse';
import {glimpse}                      from 'providers/glimpse/glimpse-provider';
import {useTranslation}               from 'foundations/i18n/use-translation';
import toast                          from 'react-hot-toast';
import {useMutate}                    from 'foundations/net/use-mutate';
import {SelectRole}                   from 'features/role/select-role';

export function CreateInviteModal({
    open,
    onClose
}: {
    open: boolean,
    onClose: () => void
}) {
    const {t}  = useTranslation();
    const form = useForm<Invite.Create.Params>({
        role_id: '01hw3m75edgnhdzzmbry043xzb'
    });
    const api  = useMutate<Invite>({
        key:       ['invite'],
        fn:        () => glimpse.invite.create(form.data),
        onSuccess: () => {
            toast.success(t('Invite sent'));
            onClose();
        }
    });

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header title={t('Invite')}/>
            <Modal.Body>
                <Form onSubmit={api.submit}>
                    <Form.Field label={t('Email')} errors={api.error?.params.email}>
                        <Textbox {...form.register('email')} placeholder='john@example.com'/>
                    </Form.Field>
                    <Form.Field label={t('Role')} errors={api.error?.params.role_id}>
                        <SelectRole {...form.register('role_id')}/>
                    </Form.Field>
                </Form>
            </Modal.Body>
            <Modal.Footer right={
                <>
                    <Button onClick={onClose}>{t('Cancel')}</Button>
                    <Button variant='primary' loading={api.loading} onClick={api.submit}>{t('Invite')}</Button>
                </>
            }/>
        </Modal>
    );
}