import {Component, Property, PropertyValue}                              from '@glimpse/glimpse';
import {Box, Button, Flex, Grid, Icon, Reveal, Skeleton, Text, useModal} from '@glimpse/glass';
import React, {useState}                                                 from 'react';
import {glimpse}                                                         from 'providers/glimpse/glimpse-provider';
import {useTranslation}                                                  from 'foundations/i18n/use-translation';
import {UpdatePropertyValueModal}                                        from 'features/property-value/update-property-value-modal';
import {CreatePropertyValueModal}                                        from 'features/property-value/create-property-value-modal';
import {useQuery}                                                        from 'foundations/net/use-query';
import {CenteredSpinner}                                                 from 'foundations/ui/interstitial/centered-spinner';
import {RequirePermission}                                               from 'features/permission/require-permission';

export function ComponentPropertyGrid({
    componentId
}: {
    componentId: Component['id']
}) {
    const {t}                               = useTranslation();
    const updateModal                       = useModal();
    const createModal                       = useModal();
    const [selectedField, setSelectedField] = useState<Property['id']>();
    const [selectedValue, setSelectedValue] = useState<PropertyValue['id']>();
    const query                             = {expand: ['values']};
    const {data, loading}                   = useQuery<Property>({
        key: ['property', query],
        fn:  () => glimpse.property.list(query)
    });

    const openUpdate = (id: PropertyValue['id']) => {
        setSelectedValue(id);
        updateModal.toggle();
    };

    const openCreate = (id: Property['id']) => {
        setSelectedField(id);
        createModal.toggle();
    };

    if (loading) {
        return (
            <Box border={'default'} xAlign={'center'} radius={1}>
                <CenteredSpinner/>
            </Box>
        );
    }

    if (data?.length === 0) {
        return (
            <Box border={'default'} pt={4} pb={4} xAlign={'center'} radius={1}>
                <Text color='text.subtle'>{t('No properties')}</Text>
            </Box>
        );
    }

    return (
        <>
            {
                selectedField &&
                componentId &&
                <CreatePropertyValueModal open={createModal.open} onClose={createModal.toggle} propertyId={selectedField} componentId={componentId!}/>
            }
            {
                selectedValue &&
                <UpdatePropertyValueModal open={updateModal.open} onClose={updateModal.toggle} propertyValueId={selectedValue}/>
            }
            <Grid gap={2} xs={3} align={'stretch'}>
                {
                    loading &&
                    <Skeleton height={'22px'}/>
                }
                {
                    data?.map((property) => {
                        const value = property.values?.find((value) => value.component_id === componentId);
                        return (
                            <Reveal>
                                <Box pl={2} pt={1} pb={1.5} pr={1} border={'default'} radius={1} effects={value?.url ? ['clickable'] : []}>
                                    <Flex justify={'between'}>
                                        <div>
                                            <Box mb={1} mt={1}>
                                                <Text color={'text.subtle'}>{property.label}</Text>
                                            </Box>
                                            {
                                                value
                                                    ? <Text fontWeight={'bold'}>{value.value}</Text>
                                                    : <Text color='text.subtle'>-</Text>
                                            }
                                        </div>
                                        <Reveal.Hidden>
                                            <div>
                                                <RequirePermission permission={'components.write'}>
                                                    <Button onClick={
                                                        value
                                                            ? () => openUpdate(value?.id)
                                                            : () => openCreate(property.id)} spacing='compact' icon={<Icon fa='far fa-pen'/>}/>
                                                </RequirePermission>
                                            </div>
                                        </Reveal.Hidden>
                                    </Flex>
                                </Box>
                            </Reveal>

                        );
                    })
                }
            </Grid>
        </>
    );
}