import {Modal, Button, Form, Textbox, Box, Alert, Text} from '@glimpse/glass';
import {ApiKey}  from '@glimpse/glimpse';
import {useForm}        from 'foundations/ui/form/use-form';
import {glimpse}        from 'providers/glimpse/glimpse-provider';
import {useTranslation} from 'foundations/i18n/use-translation';
import toast                                            from 'react-hot-toast';
import {useState}  from 'react';
import {useMutate} from 'foundations/net/use-mutate';

export function CreateApiKeyModal({
    open,
    onClose
}: {
    open: boolean,
    onClose: () => void
}) {
    const {t}                            = useTranslation();
    const form                           = useForm<ApiKey.Create.Params>();
    const [complete, setComplete]        = useState(false);
    const {data, error, loading, submit} = useMutate<ApiKey>({
        key:       ['api-key'],
        fn:        () => glimpse.apiKey.create(form.data),
        onSuccess: () => {
            toast.success(t('API Key created'));
            onClose();
        }
    });

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header title={t('Add API Key')}/>
            <Modal.Body>
                {
                    !complete &&
                    <Form>
                        <Form.Field label={t('Name')} errors={error?.params.name}>
                            <Textbox {...form.register('name')} placeholder={t('My API')}/>
                        </Form.Field>
                    </Form>
                }
                {
                    complete &&
                    <>
                        <Box mb={2}>
                            <Alert>
                                {t('Make sure to copy your API Key now, it will be hidden after closing this window')}
                            </Alert>
                        </Box>
                        <Text>{t('Your API Key:')} {data?.[0].key}</Text>
                    </>
                }
            </Modal.Body>
            <Modal.Footer right={
                complete ?
                    <Button onClick={onClose}>{t('Done')}</Button>
                    : <>
                        <Button onClick={onClose}>{t('Cancel')}</Button>
                        <Button variant='primary' loading={loading} onClick={submit}>{t('Add')}</Button>
                    </>
            }/>
        </Modal>
    );
}