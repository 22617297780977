"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArrayUtility = void 0;
var ArrayUtility = (function () {
    function ArrayUtility() {
    }
    ArrayUtility.sample = function (array) {
        return array[Math.floor(Math.random() * array.length)];
    };
    return ArrayUtility;
}());
exports.ArrayUtility = ArrayUtility;
