import {Box, Text, Grid, Button, useModal, Icon, Flex} from '@glimpse/glass';
import {Scorecard}                                     from '@glimpse/glimpse';
import {CreateScorecardCheckModal}                     from 'features/scorecard-check/create-scorecard-check-modal';
import {ScorecardCheckTable}                           from 'features/scorecard-check/scorecard-check-table';
import {RequirePermission}                             from 'features/permission/require-permission';
import {useTranslation}                                from 'foundations/i18n/use-translation';
import {faPlus}                                        from '@fortawesome/pro-light-svg-icons';
import React                                           from 'react';

export function ScorecardCheckPanel({
    scorecardId
}: {
    scorecardId: Scorecard['id']
}) {
    const createModal = useModal();
    const {t}         = useTranslation();

    return (
        <>
            <CreateScorecardCheckModal scorecardId={scorecardId} open={createModal.open} onClose={createModal.toggle}/>
            <Box mb={1}>
                <Flex justify='between' align={'center'}>
                    <Text fontWeight='bold' fontSize={3}>{t('Checks')}</Text>
                    <RequirePermission permission={'scorecards.write'}>
                        <Button spacing={'compact'} icon={<Icon fa={faPlus}/>} onClick={createModal.toggle}/>
                    </RequirePermission>
                </Flex>
            </Box>
            <ScorecardCheckTable query={{scorecard_id: scorecardId}}/>
        </>
    );
}