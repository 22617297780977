import {Modal, Button, Form, Textbox} from '@glimpse/glass';
import {ErrorModal}                   from 'foundations/ui/error-modal/error-modal';
import {LoadingModal}                 from 'foundations/ui/loading-modal/loading-modal';
import {User}                         from '@glimpse/glimpse';
import {useForm}                      from 'foundations/ui/form/use-form';
import React            from 'react';
import {glimpse}        from 'providers/glimpse/glimpse-provider';
import {useTranslation} from 'foundations/i18n/use-translation';
import toast                          from 'react-hot-toast';
import {useMutate}                    from 'foundations/net/use-mutate';
import {useQuery}                     from 'foundations/net/use-query';

export function UpdateUserModal({
    open,
    onClose,
    userId
}: {
    open: boolean,
    onClose: () => void,
    userId: User['id']
}) {
    const {t}    = useTranslation();
    const form   = useForm<User.Update.Params>();
    const update = useMutate({
        key:       ['user', userId],
        fn:        () => glimpse.user.update(userId, form.data),
        onSuccess: () => {
            toast.success(t('User updated'));
            onClose();
        }
    });
    const load   = useQuery<User>({
        key:       ['user', userId],
        fn:        () => glimpse.user.list({id: userId}),
        onSuccess: (res) => form.setData(res.data[0])
    });

    if (load.loading)
        return <LoadingModal onClose={onClose} open={open}/>;

    if (load.error)
        return <ErrorModal onClose={onClose} open={open}/>;

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header title={t('Update User')}/>
            <Modal.Body>
                <Form>
                    <Form.Field label={t('Name')} errors={update.error?.params.name}>
                        <Textbox {...form.register('name')}/>
                    </Form.Field>
                    <Form.Field label={t('Title')} errors={update.error?.params.title} description={t('Let people know what you do')}>
                        <Textbox {...form.register('title')}/>
                    </Form.Field>
                </Form>
            </Modal.Body>
            <Modal.Footer right={
                <>
                    <Button onClick={onClose}>{t('Cancel')}</Button>
                    <Button variant='primary' loading={update.loading} onClick={update.submit}>{t('Save')}</Button>
                </>
            }/>
        </Modal>
    );
}