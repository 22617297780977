import {TeamsList}                               from 'features/team/teams-list';
import {CreateTeamModal}                         from 'features/team/create-team-modal';
import {Box, Button, Grid, Icon, Text, useModal} from '@glimpse/glass';
import {Empty}                                   from 'foundations/ui/empty/empty';
import {RequirePermission}                       from 'features/permission/require-permission';
import {AppLayout}                               from 'foundations/layouts/app-layout/app-layout';
import React                                     from 'react';
import {Searchbox}                               from 'foundations/ui/searchbox/searchbox';
import {useForm}                                 from 'foundations/ui/form/use-form';
import {Team}                                    from '@glimpse/glimpse';
import {useTranslation}                          from 'foundations/i18n/use-translation';

export function TeamsScreen() {
    const {t}        = useTranslation();
    const form       = useForm<Team.List.Params>();
    const createTeam = useModal();
    const empty      = (
        <Empty
            title={t('Add your first team')}
            icon={<Icon fa={['far', 'fa-users']}/>}
            desc={t('Add teams to help make it easier to discover your org, agree on component ownership and manage communication.')}/>
    );

    return (
        <AppLayout title={t('Teams')}>
            <RequirePermission permission={'teams.read'} fallback>
                <CreateTeamModal open={createTeam.open} onClose={createTeam.toggle}/>
                <AppLayout.Header>
                    <Box pt={3} pb={3}>
                        <Grid justify='between' align='center'>
                            <Text fontSize={4} fontWeight='bolder'>{t('Teams')}</Text>
                            <div>
                                <RequirePermission permission={'teams.write'}>
                                    <Button variant='primary' icon={<Icon fa={['far', 'fa-plus']}/>} onClick={createTeam.toggle}>{t('Add Team')}</Button>
                                </RequirePermission>
                            </div>
                        </Grid>
                    </Box>
                </AppLayout.Header>
                <AppLayout.Body>
                    <Box mb={2}>
                        <Searchbox {...form.register('query')}/>
                    </Box>
                    <TeamsList empty={empty} query={form.data}/>
                </AppLayout.Body>
            </RequirePermission>
        </AppLayout>
    );
}