import {Select}         from '@glimpse/glass';
import {SelectProps}    from '@glimpse/glass';
import {Component}      from '@glimpse/glimpse';
import {useTranslation} from 'foundations/i18n/use-translation';

export function SelectComponentType(props: Omit<SelectProps, 'options'>) {
    const {t} = useTranslation();

    const options: {
        label: string,
        value: Component['type']
    }[] = [
        {
            value: 'ai_agent',
            label: t('AI Agent')
        },
        {
            value: 'ai_model',
            label: t('AI Model')
        },
        {
            value: 'application',
            label: t('Application')
        },
        {
            value: 'capability',
            label: t('Capability')
        },
        {
            value: 'feature',
            label: t('Feature')
        },
        {
            value: 'infrastructure',
            label: t('Infrastructure')
        },
        {
            value: 'library',
            label: t('Library')
        },
        {
            value: 'service',
            label: t('Service')
        },
        {
            value: 'ui_element',
            label: t('UI Element')
        },
        {
            value: 'website',
            label: t('Website')
        },
        {
            value: 'other',
            label: t('Other')
        }
    ];

    return <Select {...props} options={options}/>;
}