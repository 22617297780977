import {Alert, Icon, Button, Box} from '@glimpse/glass';
import {useSearchParams}          from 'react-router-dom';
import React                      from 'react';
import {glimpse}                  from 'providers/glimpse/glimpse-provider';
import {useTranslation}           from 'foundations/i18n/use-translation';
import {useMutate}                from 'foundations/net/use-mutate';

export function CreateGithubIntegrationScreen() {
    const {t}                              = useTranslation();
    const [searchParams]                   = useSearchParams();
    const {status, error, loading, submit} = useMutate({
        key: ['integration'],
        fn:  () => glimpse.integration.create({
            connector: 'github',
            auth:      {
                installation_id: searchParams.get('installation_id')
            },
        })
    });

    const hasOauthError = searchParams.get('error') !== null;

    React.useEffect(() => {
        if (!hasOauthError) {
            submit();
        }
    }, [hasOauthError]);

    return (
        <Box p={4}>
            <Box pb={2}>
                {
                    searchParams.get('error') &&
                    <Alert bg='danger.subtle' icon={<Icon fa='fa-regular fa-circle-exclamation' fontSize={3}/>}>
                        {t('An error occurred while authorizing the integration. Please try again')}
                    </Alert>
                }
                {
                    status?.success &&
                    <Alert bg='success.subtle' icon={<Icon fa='fa-regular fa-circle-check' fontSize={3}/>}>
                        {t('Integration Connected Successfully. You can safely close this window.')}
                    </Alert>
                }
                {
                    status?.success === false &&
                    <Alert bg='danger.subtle' icon={<Icon fa='fa-regular fa-circle-exclamation' fontSize={3}/>}>
                        {error?.message}
                    </Alert>
                }
            </Box>
            <Button disabled={loading} onClick={window.self.close}>{t('Return to Integrations')}</Button>
        </Box>
    );
}