import {Icon, Reveal, Button, ConfinedClick, useModal, Grid, Box, Text} from '@glimpse/glass';
import {ApiKey, Component}                                              from '@glimpse/glimpse';
import React, {useState} from 'react';
import {glimpse}         from 'providers/glimpse/glimpse-provider';
import {Empty}           from 'foundations/ui/empty/empty';
import {useTranslation}                                                 from 'foundations/i18n/use-translation';
import {CenteredSpinner}                                                from 'foundations/ui/interstitial/centered-spinner';
import {DeleteApiKeyModal}                                              from 'features/api-key/delete-api-key-modal';
import {useQuery}                                                       from 'foundations/net/use-query';
import {RequirePermission}                                              from 'features/permission/require-permission';

export function ApiKeyTable({
    query = {}
}: {
    query?: Component.List.Params,
}) {
    const {t}                     = useTranslation();
    const [selected, setSelected] = useState<ApiKey['id']>();
    const deleteModal             = useModal();

    const {data, loading} = useQuery<ApiKey>({
        key: ['api-key', query],
        fn:  () => glimpse.apiKey.list(query)
    });

    if (loading) {
        return <CenteredSpinner/>;
    }

    if (data?.length === 0) {
        return <Empty
            icon={<Icon fa={['far', 'fa-key']}/>}
            title={t('Add API keys')}
            desc={t('Create API Keys to programmatically access Glimpse from your own applications and other tools')}/>;
    }

    const onDelete = (id: ApiKey['id']) => {
        setSelected(id);
        deleteModal.toggle();
    };

    return (
        <>
            <DeleteApiKeyModal open={deleteModal.open} onClose={deleteModal.toggle} apiKeyId={selected!}/>
            <RequirePermission permission={'api-keys.read'}>
                <Grid gap={1} xs={12}>
                    {
                        data?.map(key =>
                            <Reveal>
                                <Box radius={1} border={'default'} p={2} effects={['hoverable', 'clickable']}>
                                    <Grid justify={'between'} align={'top'}>
                                        <Grid.Item shrink>
                                            <Text fontWeight={'bold'}>{key.name}</Text>
                                            <Text color={'text.subtle'}>{key.key}</Text>
                                        </Grid.Item>
                                        <Reveal.Hidden>
                                            <div>
                                                <ConfinedClick>
                                                    <RequirePermission permission={'api-keys.write'}>
                                                        <Button spacing='compact' icon={<Icon fa={['far', 'fa-trash']}/>} onClick={() => onDelete(key.id)}/>
                                                    </RequirePermission>
                                                </ConfinedClick>
                                            </div>
                                        </Reveal.Hidden>
                                    </Grid>
                                </Box>
                            </Reveal>
                        )
                    }
                </Grid>
            </RequirePermission>
        </>
    );
}