import styles from 'features/auth/auth-button/auth-button.module.css'
import {useMemo} from 'react';
import GoogleLogo from 'features/auth/auth-button/google-logo.png';
import LinkedinLogo from 'features/auth/auth-button/linkedin-logo.png';

export function AuthButton({
    method,
    text,
    onClick,
    loading = false
}: {
    method: 'google' | 'linkedin',
    text: string,
    onClick: () => void,
    loading?: boolean
}) {
    const logo = useMemo(() => {
        switch (method) {
            case 'google':
                return GoogleLogo;
            case 'linkedin':
                return LinkedinLogo
        }
    }, [method])

    return (
        <a className={styles.button} onClick={onClick}>
            <img src={logo} className={styles.logo}/>
            <span className={styles.text}>{text}</span>
        </a>
    )
}