import {Box, Icon, Grid, Text, Button, useModal, Reveal} from '@glimpse/glass';
import React, {useState}                                 from 'react';
import {Integration}                                     from '@glimpse/glimpse';
import {Empty}                                           from 'foundations/ui/empty/empty';
import {CenteredSpinner}                                 from 'foundations/ui/interstitial/centered-spinner';
import {glimpse}                                         from 'providers/glimpse/glimpse-provider';
import {DeleteIntegrationModal}                          from 'features/integration/delete-integration-modal';
import {useTranslation}                                  from 'foundations/i18n/use-translation';
import {useQuery}                                        from 'foundations/net/use-query';
import {UpdateIntegrationModal}                          from 'features/integration/update-integration-modal';


export function IntegrationList({
    query = {}
}: {
    query?: Integration.List.Params
}) {
    const [selected, setSelected] = useState<Integration['id']>();
    const deleteModal             = useModal();
    const updateModal             = useModal();
    const {t}                     = useTranslation();

    const {loading, data} = useQuery<Integration>({
        key: ['integration', query],
        fn:  () => glimpse.integration.list(query)
    });

    if (loading) {
        return <CenteredSpinner/>;
    }

    if (data?.length === 0) {
        return <Empty
            icon={<Icon fa={['fas', 'download']}/>}
            title={t('No installs yet')}
            desc={t('Install new integrations to connect content and enable enriched links.')}/>;
    }

    const deleteIntegration = (id: Integration['id']) => {
        setSelected(id);
        deleteModal.toggle();
    };

    const updateIntegration = (id: Integration['id']) => {
        setSelected(id);
        updateModal.toggle();
    };

    return (
        <>
            {
                selected &&
                <>
                    <DeleteIntegrationModal open={deleteModal.open} onClose={deleteModal.toggle} integrationId={selected}/>
                    <UpdateIntegrationModal open={updateModal.open} onClose={updateModal.toggle} integrationId={selected}/>
                </>
            }
            <Grid xs={12} gap={2}>
                {
                    data?.map(integration =>
                        <Reveal>
                            <Box border={'default'} radius={1} p={2}>
                                <Grid justify={'between'}>
                                    <div>
                                        <Box mb={0.5}>
                                            <Text fontWeight={'bold'}>{integration.name}</Text>
                                        </Box>
                                        <Text color={'text.subtle'}>{t('Installed')} {integration.created_at}</Text>
                                    </div>
                                    <Reveal.Hidden>
                                        <div>
                                            <Button onClick={() => updateIntegration(integration.id)} icon='far fa-pen'>{t('Edit')}</Button>
                                            <Button onClick={() => deleteIntegration(integration.id)} variant={'destructive'} icon={<Icon fa={['far', 'trash']}/>}>{t('Uninstall')}</Button>
                                        </div>
                                    </Reveal.Hidden>
                                </Grid>
                            </Box>
                        </Reveal>
                    )
                }
            </Grid>
        </>
    )
        ;
}