import {Modal, Spinner, Box} from '@glimpse/glass';

export function LoadingModal({
    onClose,
    open
}: {
    onClose: () => void,
    open: boolean
}) {
    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Body>
                <Box pt={2} pb={2} xAlign='center'>
                    <Spinner/>
                </Box>
            </Modal.Body>
        </Modal>
    )
}