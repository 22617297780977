import {Box, Text, Grid}     from '@glimpse/glass';
import {Component}           from '@glimpse/glimpse';
import {useTranslation}      from 'foundations/i18n/use-translation';
import {RequirePermission}   from 'features/permission/require-permission';
import {ScorecardResultList} from 'features/scorecard-result/scorecard-result-list';

export function ScorecardResultPanel({
    componentId
}: {
    componentId: Component['id']
}) {
    const {t} = useTranslation();

    return (
        <RequirePermission permission={'scorecards.read'}>
            <Box mb={1} ml={0.5}>
                <Grid justify='between'>
                    <Text fontWeight='bold' fontSize={3}>{t('Scorecards')}</Text>
                </Grid>
            </Box>
            <ScorecardResultList componentId={componentId}/>
        </RequirePermission>
    );
}