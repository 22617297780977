import {Icon, Button, useModal, Box, Grid, Text} from '@glimpse/glass';
import {CreateScorecardModal}                    from 'features/scorecard/create-scorecard-modal';
import {ScorecardGrid}                           from 'features/scorecard/scorecard-grid';
import {RequirePermission}                       from 'features/permission/require-permission';
import {AppLayout}                               from 'foundations/layouts/app-layout/app-layout';
import React                                     from 'react';
import {Searchbox}                               from 'foundations/ui/searchbox/searchbox';
import {useForm}                                 from 'foundations/ui/form/use-form';
import {Scorecard}                               from '@glimpse/glimpse';
import {useTranslation}                          from 'foundations/i18n/use-translation';

export function ScorecardsScreen() {
    const {t}   = useTranslation();
    const form  = useForm<Scorecard.List.Params>();
    const modal = useModal();

    return (
        <AppLayout title={t('Scorecards')}>
            <CreateScorecardModal open={modal.open} onClose={modal.toggle}/>
            <AppLayout.Header>
                <Box pt={3} pb={3}>
                    <Grid justify='between' align='center'>
                        <div>
                            <Text fontSize={4} fontWeight='bolder'>{t('Scorecards')}</Text>
                            <Text fontSize={1} color={'text.subtle'}>{t('Track maturity and health')}</Text>
                        </div>
                        <div>
                            <RequirePermission permission={'scorecards.write'}>
                                <Button variant='primary' icon={<Icon fa={['far', 'fa-plus']}/>} onClick={modal.toggle}>{t('Add Scorecard')}</Button>
                            </RequirePermission>
                        </div>
                    </Grid>
                </Box>
            </AppLayout.Header>
            <AppLayout.Body>
                <Box mb={2}>
                    <Searchbox {...form.register('query')}/>
                </Box>
                <ScorecardGrid query={form.data}/>
            </AppLayout.Body>
        </AppLayout>
    );
}