import {Select}      from '@glimpse/glass';
import {SelectProps} from '@glimpse/glass';
import {glimpse}     from 'providers/glimpse/glimpse-provider';
import {Component}   from '@glimpse/glimpse';
import {useQuery}    from 'foundations/net/use-query';

export function SelectComponent(props: Omit<SelectProps, 'options'> & { exclude?: (Component['id'] | null)[] }) {
    const {data} = useQuery<Component>({
        key: ['component'],
        fn:  glimpse.component.list
    });

    const options = data
        ?.filter((component) => !props.exclude?.includes(component.id))
        .map(component => {
            return {
                label: component.name,
                value: component.id
            };
        }) ?? [];

    return <Select {...props} options={options}/>;
}