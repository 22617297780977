import {Workspace}           from '@glimpse/glimpse';
import {Modal, Button, Text} from '@glimpse/glass';
import {useNavigate}    from 'react-router-dom';
import {glimpse}        from 'providers/glimpse/glimpse-provider';
import {useTranslation} from 'foundations/i18n/use-translation';
import toast                 from 'react-hot-toast';
import {useMutate}           from 'foundations/net/use-mutate';
import {FormFallbackError}   from 'foundations/ui/form/form-fallback-error';

export function DeleteWorkspaceModal({
    open,
    onClose,
    workspaceId
}: {
    open: boolean,
    onClose: () => void,
    workspaceId: Workspace['id'],
}) {
    const {t}                     = useTranslation();
    const navigate                = useNavigate();
    const {loading, error, submit} = useMutate({
        key:       ['workspace', workspaceId],
        fn:        () => glimpse.workspace.delete(workspaceId),
        onSuccess: () => {
            toast.success(t('Workspace deleted'));
            navigate('/');
        }
    });

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header title={t('Deactivate Workspace')}/>
            <Modal.Body>
                <FormFallbackError error={error}/>
                <Text>
                    {t('Deactivating a workspace cannot be undone. All data, including components, teams and users, will be deleted and cannot be recovered.')}
                </Text>
            </Modal.Body>
            <Modal.Footer right={
                <>
                    <Button onClick={onClose}>{t('Cancel')}</Button>
                    <Button variant='destructive' loading={loading} onClick={submit}>{t('Deactivate')}</Button>
                </>
            }/>
        </Modal>
    );
}