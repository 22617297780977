export class Config {

    public static env(name: string): string {
        const env = process.env[name];

        if (env === undefined) {
            throw new Error(`Attempted to access env var ${name} but it does not exist`);
        }

        return env;
    }

    public static isProduction() {
        return process.env.NODE_ENV !== 'development';
    }
}