import {Scaffold}                               from '@glimpse/glimpse';
import {Modal, Button, Form, Textbox, Textarea} from '@glimpse/glass';
import {ErrorModal}                             from 'foundations/ui/error-modal/error-modal';
import {LoadingModal}                           from 'foundations/ui/loading-modal/loading-modal';
import {useForm}                                from 'foundations/ui/form/use-form';
import {SelectRepository} from 'features/repository/select-repository';
import {glimpse}          from 'providers/glimpse/glimpse-provider';
import {useTranslation}   from 'foundations/i18n/use-translation';
import toast                                    from 'react-hot-toast';
import {useMutate}                              from 'foundations/net/use-mutate';
import {useQuery}                               from 'foundations/net/use-query';

export function UpdateScaffoldModal({
    open,
    onClose,
    scaffoldId
}: {
    open: boolean,
    onClose: () => void,
    scaffoldId: Scaffold['id']
}) {
    const {t}    = useTranslation();
    const form   = useForm<Scaffold.Update.Params>();
    const update = useMutate({
        key:       ['scaffold', scaffoldId],
        fn:        () => glimpse.scaffold.update(scaffoldId, form.data),
        onSuccess: () => {
            toast.success(t('Scaffold updated'));
            onClose();
        }
    });

    const load = useQuery<Scaffold>({
        key: ['scaffold', scaffoldId],
        fn:  () => glimpse.scaffold.list({id: scaffoldId}),
        onSuccess: (res) => {
            form.setData(res.data[0] as Scaffold.Update.Params);
        }
    });

    if (load.loading)
        return <LoadingModal onClose={onClose} open={open}/>;

    if (load.error)
        return <ErrorModal onClose={onClose} open={open}/>;

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header title={t('Update Scaffold')}/>
            <Modal.Body>
                <Form>
                    <Form.Field label={t('Name')} errors={update.error?.params.name}>
                        <Textbox {...form.register('name')} placeholder='Ruby Api'/>
                    </Form.Field>
                    <Form.Field label={t('Repository')} errors={update.error?.params.template_url}>
                        <SelectRepository {...form.register('template_url')} placeholder='https://github.com/acme/repo.git'/>
                    </Form.Field>
                    <Form.Field label={t('Description')} errors={update.error?.params.description}>
                        <Textarea {...form.register('description')}/>
                    </Form.Field>
                </Form>
            </Modal.Body>
            <Modal.Footer right={
                <>
                    <Button onClick={onClose}>{t('Cancel')}</Button>
                    <Button variant='primary' loading={update.loading} onClick={update.submit}>{t('Save')}</Button>
                </>
            }/>
        </Modal>
    );
}