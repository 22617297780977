import {Modal, Button, Form, Textbox} from '@glimpse/glass';
import {IntegrationApp}               from '@glimpse/glimpse';
import {useForm}                      from 'foundations/ui/form/use-form';
import {glimpse}                      from 'providers/glimpse/glimpse-provider';
import {useTranslation}               from 'foundations/i18n/use-translation';
import toast                          from 'react-hot-toast';
import {useMutate}                    from 'foundations/net/use-mutate';
import React, {useMemo}               from 'react';
import {SelectGithubRepo}             from 'features/integration/github/select-github-repo';
import {SelectIntegrationConnector}   from 'features/integration/select-integration-connector';

export function CreateIntegrationAppModal({
    open,
    onClose,
    source_connector,
    component_id
}: {
    open: boolean,
    onClose: () => void,
    component_id?: IntegrationApp['component_id'],
    source_connector?: IntegrationApp['source_connector']
}) {
    const {t}  = useTranslation();
    const form = useForm<IntegrationApp.Create.Params>({
        source_connector,
        component_id
    });

    const {loading, submit} = useMutate<IntegrationApp>({
        key:       ['integration-app'],
        fn:        () => glimpse.integrationApp.create(form.data),
        onSuccess: () => {
            toast.success(t('App Added'));
            onClose();
        }
    });

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header title={t('Add App')}/>
            <Modal.Body>
                <Form>
                    {
                        source_connector === 'github' &&
                        <Form.Field label={t('Github Repo')}>
                            {/*@ts-ignore */}
                            <SelectGithubRepo {...form.register('settings.repo')}/>
                        </Form.Field>
                    }
                </Form>
            </Modal.Body>
            <Modal.Footer right={
                <>
                    <Button onClick={onClose}>{t('Cancel')}</Button>
                    <Button variant='primary' loading={loading} onClick={submit}>{t('Add')}</Button>
                </>
            }/>
        </Modal>
    );
}