"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FontawesomeCommonLoader = void 0;
var fontawesome_svg_core_1 = require("@fortawesome/fontawesome-svg-core");
require("@fortawesome/fontawesome-svg-core/styles.css");
function FontawesomeCommonLoader(_a) {
    var packs = _a.packs;
    fontawesome_svg_core_1.library.add.apply(fontawesome_svg_core_1.library, packs);
    fontawesome_svg_core_1.config.autoAddCss = false;
    return null;
}
exports.FontawesomeCommonLoader = FontawesomeCommonLoader;
