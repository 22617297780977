import {Button, Box, Form, Textbox} from '@glimpse/glass';
import React                        from 'react';
import {glimpse}                    from 'providers/glimpse/glimpse-provider';
import {useForm}                    from 'foundations/ui/form/use-form';
import {FormFallbackError}          from 'foundations/ui/form/form-fallback-error';
import {CreateIntegrationLayout}    from 'features/integration/create-integration-layout';
import {useTranslation}             from 'foundations/i18n/use-translation';
import {useMutate}                  from 'foundations/net/use-mutate';
import {IntegrationSuccessInlay}    from 'features/integration/integration-success-inlay';

export function CreateJiraIntegrationScreen() {
    const {t}                              = useTranslation();
    const form                             = useForm();
    const {error, loading, submit, status} = useMutate({
        key: ['integration'],
        fn:  () => glimpse.integration.create({
            connector: 'jira',
            auth:      {...form.data}
        })
    });

    if (status?.success) {
        return (
            <CreateIntegrationLayout label={'Jira'}>
                <IntegrationSuccessInlay/>
            </CreateIntegrationLayout>
        );
    }

    return (
        <CreateIntegrationLayout label={'Jira'}>
            <Form>
                <FormFallbackError error={error}/>
                <Form.Field label={t('Email')}>
                    <Textbox {...form.register('username')} placeholder={'admin@example.com'}/>
                </Form.Field>
                <Form.Field label={t('API Token')}>
                    <Textbox {...form.register('api_token')} placeholder={'BBTT3xFfGF0rgnBwF5hssds6JJBeywwsIB2zmrQoViLVMPSUENxwFVM86egGwP8MyOLhuJutIpWu3Y494Z-qRicpJC4zZdqNMKD1GDy3ah5tNplwgISaacxWOAVaoTgxOQe'}/>
                </Form.Field>
                <Form.Field label={t('Site URL')}>
                    <Textbox {...form.register('host')} placeholder={'mysite.atlassian.net'}/>
                </Form.Field>
            </Form>
            <Box mt={4}>
                <Button spacing={'large'} fullwidth variant={'primary'} disabled={loading} onClick={submit}>{t('Connect')}</Button>
            </Box>
        </CreateIntegrationLayout>
    );
}