import {Component, ComponentDependency}                          from '@glimpse/glimpse';
import {Table, Button, Box, useModal, Text, Reveal, Badge, Flex} from '@glimpse/glass';
import {useNavigate}                                             from 'react-router-dom';
import {glimpse}                                                 from 'providers/glimpse/glimpse-provider';
import {DeleteComponentDependencyModal}                          from 'features/component-dependency/delete-component-dependency-modal';
import React, {useState}                                         from 'react';
import {useQuery}                                                from 'foundations/net/use-query';
import {RequirePermission}                                       from 'features/permission/require-permission';
import {CenteredSpinner}                                         from 'foundations/ui/interstitial/centered-spinner';
import {useTranslation}                                          from 'foundations/i18n/use-translation';
import {ComponentIcon}                                           from 'features/component/component-icon';

export function ComponentDependencyTable({
    componentId
}: {
    componentId: Component['id']
}) {
    const {t}                     = useTranslation();
    const navigate                = useNavigate();
    const modal                   = useModal();
    const [selected, setSelected] = useState<ComponentDependency['id']>();
    const query                   = {
        parent_id: componentId,
        expand:    ['dependent', 'parent']
    };
    const {loading, data}         = useQuery<ComponentDependency>({
        key: ['component-dependency', query],
        fn:  async () => await glimpse.componentDependency.list(query)
    });

    const deleteDep = (id: ComponentDependency['id']) => {
        setSelected(id);
        modal.toggle();
    };

    if (loading) {
        return (
            <Box border={'default'} xAlign={'center'} radius={1}>
                <CenteredSpinner/>
            </Box>
        );
    }

    if (data?.length === 0) {
        return (
            <Box border={'default'} pt={4} pb={4} xAlign={'center'} radius={1}>
                <Text color='text.subtle'>{t('No dependencies')}</Text>
            </Box>
        );
    }

    return (
        <Box border={'default'} radius={1}>
            <Table>
                <DeleteComponentDependencyModal open={modal.open} onClose={modal.toggle} componentDependencyId={selected!}/>
                <RequirePermission permission={'components.read'} fallback>
                    <Table.Body>
                        {
                            data?.map(dependency =>
                                <Reveal>
                                    <Table.Row effects={['hoverable', 'clickable']} onClick={() => navigate(`/components/${dependency.dependent!.id}`)} key={dependency.id}>
                                        <Table.BodyCell>
                                            <Box p={0.5}>
                                                <Flex align={'center'} gap={1}>
                                                    <ComponentIcon componentType={dependency.dependent?.type!}/>
                                                    <Text fontWeight={'bold'}>
                                                        {dependency.dependent?.name}
                                                    </Text>
                                                </Flex>
                                            </Box>
                                        </Table.BodyCell>
                                        <Table.BodyCell>
                                            <Box p={1}>
                                                <Text color={'text.subtle'}>
                                                    {dependency.dependent?.description ?? '-'}
                                                </Text>
                                            </Box>
                                        </Table.BodyCell>
                                        <Table.BodyCell shrink>
                                            <RequirePermission permission={'components.write'}>
                                                <Reveal.Hidden>
                                                    <Button spacing='compact' icon='far fa-xmark' onClick={() => deleteDep(dependency.id)}/>
                                                </Reveal.Hidden>
                                            </RequirePermission>
                                        </Table.BodyCell>
                                    </Table.Row>
                                </Reveal>
                            )
                        }
                    </Table.Body>
                </RequirePermission>
            </Table>
        </Box>
    );
}