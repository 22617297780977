import {Modal, Button, Form} from '@glimpse/glass';
import {useForm}             from 'foundations/ui/form/use-form';
import {glimpse}             from 'providers/glimpse/glimpse-provider';
import {ComponentDependency} from '@glimpse/glimpse';
import {useTranslation}      from 'foundations/i18n/use-translation';
import toast                 from 'react-hot-toast';
import {useQuery}            from 'foundations/net/use-query';
import {useMutate}           from 'foundations/net/use-mutate';
import {ComponentListBox}    from 'features/component/component-list-box';
import {SelectComponent}     from 'features/component/select-component';

export function CreateComponentDependencyModal({
    open,
    onClose,
    parentId
}: {
    open: boolean,
    parentId: ComponentDependency['parent_id'],
    onClose: () => void
}) {
    const {t}                      = useTranslation();
    const form                     = useForm<ComponentDependency.Create.Params>({parent_id: parentId});
    const dependencies             = useQuery<ComponentDependency>({
        key: ['component-dependency', parentId],
        fn:  () => glimpse.componentDependency.list({parent_id: parentId})
    });
    const {error, loading, submit} = useMutate({
        key:       ['component-dependency', parentId],
        fn:        () => glimpse.componentDependency.create(form.data),
        onSuccess: () => {
            toast.success(t('Dependency added'));
            onClose();
        }
    });

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header title={t('Add Dependency')}/>
            <Modal.Body>
                <Form onSubmit={submit}>
                    <Form.Field errors={error?.params.dependent_id}>
                        <SelectComponent {...form.register('dependent_id')} exclude={[
                            parentId,
                            ...dependencies.data?.map(dep => dep.dependent_id) ?? []
                        ]}/>
                    </Form.Field>
                </Form>
            </Modal.Body>
            <Modal.Footer right={
                <>
                    <Button onClick={onClose}>{t('Cancel')}</Button>
                    <Button variant='primary' loading={loading} onClick={submit}>{t('Add')}</Button>
                </>
            }/>
        </Modal>
    );
}