import {Pipeline}       from '@glimpse/glimpse';
import {Badge}          from '@glimpse/glass';
import {useTranslation} from 'foundations/i18n/use-translation';

export function PipelineStatusBadge({
    status
}: {
    status: Pipeline['status'] | undefined
}) {
    const {t} = useTranslation();
    switch (status) {
        case 'pending':
            return <Badge color={'neutral.subtle'} text={t('Pending')}/>;
        case 'in_progress':
            return <Badge color={'info.subtle'} text={t('In progress')}/>;
        case 'cancelled':
            return <Badge color={'neutral.subtle'} text={t('Cancelled')}/>;
        case 'success':
            return <Badge color={'success.subtle'} text={t('Success')}/>;
        case 'error':
            return <Badge color={'danger.subtle'} text={t('Error')}/>;
        default:
            return null;

    }
}