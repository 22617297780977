import {Modal, Button, Form, Textbox, Textarea, Switch, ShowMore, GlassChangeEvent} from '@glimpse/glass';
import {Property}                                                                   from '@glimpse/glimpse';
import {useForm}                                                                    from 'foundations/ui/form/use-form';
import {glimpse}                                                                    from 'providers/glimpse/glimpse-provider';
import {useTranslation}                                                             from 'foundations/i18n/use-translation';
import toast                                                                        from 'react-hot-toast';
import {SelectPropertyFormat}                                                       from 'features/property/select-property-format';
import {useMutate}                                                                  from 'foundations/net/use-mutate';
import {useState}                                                                   from 'react';

export function CreatePropertyModal({
    open,
    onClose
}: {
    open: boolean,
    onClose: () => void
}) {
    const {t}                                   = useTranslation();
    const [userChangedName, setUserChangedName] = useState<boolean>(false);
    const form                                  = useForm<Property.Create.Params>({
        format: 'text'
    });
    const {error, loading, submit}              = useMutate<Property>({
        key:       ['property'],
        fn:        () => glimpse.property.create(form.data),
        onSuccess: () => {
            toast.success(t('Property created'));
            onClose();
        }
    });

    const onChangeName = (event: GlassChangeEvent) => {
        form.setData({
            ...form.data,
            [event.currentTarget.name]: event.currentTarget.value
                                            .toLowerCase()
                                            .replaceAll(/[^a-z]/g, '_')
        });

        setUserChangedName(true);
    };

    const onChangeLabel = (event: GlassChangeEvent) => {
        const data = {
            ...form.data,
            label: event.currentTarget.value
        };

        if (!userChangedName) {
            data.name = event.currentTarget.value
                .toLowerCase()
                .replaceAll(/[^a-z]/g, '_');
        }

        form.setData(data);
    };

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header title={t('Add Property')}/>
            <Modal.Body>
                <Form>
                    <Form.Field label={t('Name')} errors={error?.params.name} description={t('This will be used to reference the property in Scorecards and GlimpseQL')}>
                        <Textbox {...form.register('name')} onChange={onChangeName} placeholder={t('tier')}/>
                    </Form.Field>
                    <Form.Field label={t('Display Name')} errors={error?.params.name}>
                        <Textbox {...form.register('label')} onChange={onChangeLabel} placeholder={t('Tier')}/>
                    </Form.Field>
                    <Form.Field label={t('Format')} errors={error?.params.format}>
                        <SelectPropertyFormat {...form.register('format')}/>
                    </Form.Field>
                    {
                        form.data.format === 'select' &&
                        <Form.Field label={t('Select Options')} errors={error?.params.options} description={'Please provide a comma seperated list like (Option A, Option B, ...)'}>
                            <Textbox {...form.register('options')}/>
                        </Form.Field>
                    }
                    <Form.Field label={t('Description')} errors={error?.params.description}>
                        <Textarea {...form.register('description')}/>
                    </Form.Field>
                </Form>
            </Modal.Body>
            <Modal.Footer right={
                <>
                    <Button onClick={onClose}>{t('Cancel')}</Button>
                    <Button variant='primary' loading={loading} onClick={submit}>{t('Add')}</Button>
                </>
            }/>
        </Modal>
    );
}