import {useModal, Button, Icon, Box, Text, Grid} from '@glimpse/glass';
import {AppLayout}                               from 'foundations/layouts/app-layout/app-layout';
import React                                     from 'react';
import {RequirePermission}                       from 'features/permission/require-permission';
import {useTranslation}                          from 'foundations/i18n/use-translation';
import {RequireEntitlement}                      from 'features/entitlement/require-entitlement';
import {BillingProductUpsell}                    from 'features/billing-product/billing-product-upsell';
import {RoleTable}                               from 'features/role/role-table';
import {CreateRoleModal}                         from 'features/role/create-role-modal';

export function SettingsRolesScreen() {
    const createModal = useModal();
    const {t}         = useTranslation();

    const upsell = <BillingProductUpsell
        icon={<Icon fa={['fas', 'fa-key']} color={'secondary'} fontSize={4}/>}
        preTitle={t('Custom RBAC')}
        title={t('Customize Roles & Permissions')}
        desc={t('Create custom roles, tailor permissions and fine-tune access control for your org structure.')}/>;

    return (
        <AppLayout title={t('Roles')}>
            <RequirePermission permission={'workspace.write'} fallback>
                <CreateRoleModal open={createModal.open} onClose={createModal.toggle}/>
                <AppLayout.Header>
                    <Box pt={3} pb={3}>
                        <Grid justify='between' align='center'>
                            <Text fontSize={4} fontWeight='bolder'>{t('Roles')}</Text>
                            <RequireEntitlement entitlement={'roles'}>
                                <Button variant='primary' icon={<Icon fa={['far', 'fa-plus']}/>} onClick={createModal.toggle}>{t('Add Role')}</Button>
                            </RequireEntitlement>
                        </Grid>
                    </Box>
                </AppLayout.Header>
                <AppLayout.Body>
                    <RequireEntitlement entitlement={'roles'} fallback={upsell}>
                        <RoleTable query={{sort: ['workspace_id:asc', 'name:asc']}}/>
                    </RequireEntitlement>
                </AppLayout.Body>
            </RequirePermission>
        </AppLayout>
    );
}