import {Table, Text, Box, Icon, Spaced} from '@glimpse/glass';
import React                            from 'react';
import {PullRequest}                    from '@glimpse/glimpse';
import {Empty}                          from 'foundations/ui/empty/empty';
import {CenteredSpinner} from 'foundations/ui/interstitial/centered-spinner';
import {glimpse}         from 'providers/glimpse/glimpse-provider';
import {useTranslation}  from 'foundations/i18n/use-translation';
import {useQuery}                       from 'foundations/net/use-query';
import {RequirePermission}              from 'features/permission/require-permission';

export function PullRequestTable({
    query = {}
}: {
    query?: PullRequest.List.Params
}) {
    const {t} = useTranslation();
    const api = useQuery<PullRequest>({
        key: ['pull-request', query],
        fn:  () => glimpse.pullRequest.list(query)
    });

    if (api.loading) {
        return <CenteredSpinner/>;
    }

    if (api.data?.length === 0) {
        return <Empty
            icon={<Icon fa={['far', 'fa-code-pull-request']}/>}
            title={t('Connect your pull requests')}
            desc={t('Pull Requests or Merge Requests will show up here after connecting your Git provider')}/>;
    }
    return (
        <RequirePermission permission={'pull-requests.read'} fallback>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell> </Table.HeaderCell>
                        <Table.HeaderCell>{t('Name')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('Description')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('Repo')}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                {
                    api.data?.length === 0 &&
                    <Box p={2}>
                        <Text color='text.subtle'>{t('There are no repositories to display')}</Text>
                    </Box>
                }
                <Table.Body>
                    {
                        api.data?.map(pr =>
                            <Table.Row effects={['clickable', 'hoverable']} key={pr.id}>
                                <Table.BodyCell shrink>
                                    {pr.state === 'open' && <Icon fontSize={3} color={'success'} fa={['fas', 'code-pull-request']}/>}
                                    {pr.state === 'merged' && <Icon fontSize={3} color={'brand'} fa={['fas', 'code-pull-request']}/>}
                                    {pr.state === 'closed' && <Icon fontSize={3} color={'danger'} fa={['fas', 'code-pull-request-closed']}/>}
                                    {pr.state === 'draft' && <Icon fontSize={3} color={'success'} fa={['fas', 'code-pull-request-draft']}/>}
                                </Table.BodyCell>
                                <Table.BodyCell>
                                    <Spaced direction={'vertical'} gap={0}>
                                        <Text fontWeight={'bold'}>{pr.title}</Text>
                                        <Text color={'text.subtle'}>#{pr.number}</Text>
                                    </Spaced>
                                </Table.BodyCell>
                                <Table.BodyCell>{pr.description ?? '-'}</Table.BodyCell>
                                <Table.BodyCell>{`${pr.source_target_namespace_key} / ${pr.source_target_key}`}</Table.BodyCell>
                            </Table.Row>
                        )
                    }
                </Table.Body>
            </Table>
        </RequirePermission>
    );
}