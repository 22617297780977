import {Box, Grid, Icon, Text, useModal} from '@glimpse/glass';
import React                             from 'react';
import {AppLayout}                       from 'foundations/layouts/app-layout/app-layout';
import {useTranslation}                  from 'foundations/i18n/use-translation';
import {CreateActionModal} from 'features/action/create-action-modal';
import {Empty}             from 'foundations/ui/empty/empty';

export function DeploymentsScreen() {
    const {t}         = useTranslation();
    const createModal = useModal();

    return (
        <AppLayout>
            <CreateActionModal open={createModal.open} onClose={createModal.toggle}/>
            <AppLayout.Header>
                <Box pt={3} pb={3}>
                    <Grid justify='between' align='center'>
                        <Text fontSize={4} fontWeight='bolder'>{t('Deployments')}</Text>
                    </Grid>
                </Box>
            </AppLayout.Header>
            <AppLayout.Body>
                <Empty icon={<Icon fa={['far', 'fa-signs-post']}/>} title={t('Coming soon')} desc={t('Track and manage your deployments across all tooling!')}/>
            </AppLayout.Body>
        </AppLayout>
    );
}