import {Text, Form, Textbox, Box, Button, Separator, Grid} from '@glimpse/glass';
import {AuthLayout}                                        from 'foundations/layouts/auth-layout/auth-layout';
import {useNavigate}                                       from 'react-router-dom';
import {useForm}                                           from 'foundations/ui/form/use-form';
import {PasswordReset}  from '@glimpse/glimpse';
import {glimpse}        from 'providers/glimpse/glimpse-provider';
import {useTranslation} from 'foundations/i18n/use-translation';
import {useMutate}                                         from 'foundations/net/use-mutate';

export function RequestPasswordResetScreen() {
    const navigate                 = useNavigate();
    const {t}                      = useTranslation();
    const form                     = useForm<PasswordReset.Create.Params>();
    const {error, loading, submit} = useMutate({
        key:       ['password-reset'],
        fn:        () => glimpse.passwordReset.create(form.data),
        onSuccess: () => {
            navigate('/reset-password/sent');
        }
    });

    const login = () => navigate('/login');

    return (
        <AuthLayout>
            <Box pb={4} xAlign={'center'}>
                <Text fontSize={4} fontWeight='bold'>{t('Enter your email to reset your password')}</Text>
            </Box>
            <Form onSubmit={submit}>
                <Form.Field label={t('Email')} errors={error?.params.email}>
                    <Textbox {...form.register('email')}/>
                </Form.Field>
            </Form>
            <Box pt={1}>
                <Button fullwidth variant='primary' spacing='large' onClick={submit} loading={loading}>{t('Continue')}</Button>
            </Box>
            <Box pb={4} pt={4}>
                <Separator/>
            </Box>
            <Grid justify='between'>
                <Button variant='link' onClick={login}>{t('Back to login')}</Button>
            </Grid>
        </AuthLayout>
    );
}