import {Box, Text, Grid, Button, useModal, Flex, Icon} from '@glimpse/glass';
import {ComponentDependencyTable}                      from 'features/component-dependency/component-dependency-table';
import {CreateComponentDependencyModal}                from 'features/component-dependency/create-component-dependency-modal';
import {ComponentDependency}                           from '@glimpse/glimpse';
import {RequirePermission}                             from 'features/permission/require-permission';
import {useTranslation}                                from 'foundations/i18n/use-translation';
import {faPlus}                                        from '@fortawesome/pro-light-svg-icons';
import React                                           from 'react';

export function ComponentDependencyPanel({
    componentId
}: {
    componentId: ComponentDependency['dependent_id']
}) {
    const createDependency = useModal();
    const {t}              = useTranslation();

    return (
        <>
            <CreateComponentDependencyModal open={createDependency.open} onClose={createDependency.toggle} parentId={componentId}/>
            <Box mb={1} ml={0.5}>
                <Flex justify='between' align={'center'}>
                    <Text fontWeight='bold' fontSize={3}>{t('Dependencies')}</Text>
                    <RequirePermission permission={'components.write'}>
                        <Button spacing={'compact'} icon={<Icon fa={faPlus}/>} onClick={createDependency.toggle}/>
                    </RequirePermission>
                </Flex>
            </Box>
            <ComponentDependencyTable componentId={componentId}/>
        </>
    );
}