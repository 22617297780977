export function useTranslation() {

    const t = (string: string) => {
        return string;
    };

    return {
        t
    };
}
