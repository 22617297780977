import React                             from 'react';
import {GlassChangeEvent, ObjectUtility} from '@glimpse/glass';

export function useForm<S extends Record<string, any>>(data: Partial<S> = {}): {
    data: S,
    setData: (data: S) => void,
    set: (prop: keyof S, value: any) => void,
    register: (name: keyof S) => {
        name: string,
        value: any,
        onChange: (event: GlassChangeEvent) => void
    }
} {
    const [formData, setFormData] = React.useState<Partial<S>>(data);

    const register = (name: keyof S) => {
        return {
            name:     name as any,
            value:    formData[name] as any ?? undefined,
            onChange: (event: GlassChangeEvent) => {
                const newData = {...formData};
                ObjectUtility.setNestedKey(newData, name as string, event.currentTarget.value);
                setFormData(newData);
            }
        };
    };

    const set = (prop: keyof S, value: any) => {
        const data = {...formData};
        ObjectUtility.setNestedKey(data, prop as string, value);
        setFormData(data);
    };

    return {
        data:     formData as S,
        setData:  (data: S) => setFormData(data ?? {}),
        register: register,
        set
    };
}