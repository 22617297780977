import {Box, Button,  Grid, Text, useModal} from '@glimpse/glass';
import React                               from 'react';
import {AppLayout}                         from 'foundations/layouts/app-layout/app-layout';
import {useTranslation}                    from 'foundations/i18n/use-translation';
import {AppListingsGrid}                   from 'features/app-listing/app-listings-grid';
import { RequirePermission } from 'features/permission/require-permission';
import {CreateAppListingModal}              from 'features/app-listing/create-app-listing-modal';

export function AppListingsScreen() {
    const {t}         = useTranslation();
    const createModal = useModal();

    return (
        <AppLayout title={t('Applications')}>
            <CreateAppListingModal open={createModal.open} onClose={createModal.toggle}/>
            <AppLayout.Header>
                <Box pt={3} pb={3}>
                    <Grid justify='between' align='center'>
                        <Text fontSize={4} fontWeight='bolder'>{t('Applications')}</Text>
                        <div>
                            <RequirePermission permission={'app-listings.write'}>
                                <Button variant='primary' icon='fa-regular fa-plus' onClick={createModal.toggle}>{t('Add App')}</Button>
                            </RequirePermission>
                        </div>
                    </Grid>
                </Box>
            </AppLayout.Header>
            <AppLayout.Body>
                <AppListingsGrid query={{
                    sort: ['label:asc']
                }}/>
            </AppLayout.Body>
        </AppLayout>
    );
}