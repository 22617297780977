import {Box, Button, Flex, Grid, Text, useModal} from '@glimpse/glass';
import {CreateComponentModal}                          from 'features/component/create-component-modal';
import {ComponentTable}                                from 'features/component/component-table';
import {useForm}                                       from 'foundations/ui/form/use-form';
import {Component}                                     from '@glimpse/glimpse';
import {RequirePermission}                             from 'features/permission/require-permission';
import React                                           from 'react';
import {AppLayout}                                     from 'foundations/layouts/app-layout/app-layout';
import {Searchbox}                                     from 'foundations/ui/searchbox/searchbox';
import {useTranslation}                                from 'foundations/i18n/use-translation';
import {ComponentTypeFilter}                           from 'features/component/component-type-filter';

export function ComponentsScreen() {
    const {t}             = useTranslation();
    const createComponent = useModal();
    const form            = useForm<Component.List.Params>();

    return (
        <AppLayout title={t('Components')}>
            <CreateComponentModal open={createComponent.open} onClose={createComponent.toggle}/>
            <AppLayout.Header>
                <Box pt={3} pb={3}>
                    <Grid justify='between' align='center'>
                        <div>
                            <Text fontSize={4} fontWeight='bolder'>{t('Components')}</Text>
                            <Text fontSize={1} color={'text.subtle'}>{t('Manage your stack')}</Text>
                        </div>
                        <div>
                            <RequirePermission permission={'components.write'}>
                                <Button variant='primary' icon='fa-regular fa-plus' onClick={createComponent.toggle}>{t('Add Component')}</Button>
                            </RequirePermission>
                        </div>
                    </Grid>
                </Box>
            </AppLayout.Header>
            <AppLayout.Body>
                <Box mb={1.5}>
                    <Searchbox {...form.register('query')}/>
                </Box>
                <Box mb={2}>
                    <Flex gap={1}>
                        <ComponentTypeFilter {...form.register('type')} label={t('Type')}/>
                    </Flex>
                </Box>
                <ComponentTable query={{
                    ...form.data,
                    sort:  ['name:asc'],
                    limit: 100
                }}/>
            </AppLayout.Body>
        </AppLayout>
    );
}