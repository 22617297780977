import {Grid, Text, Button, Skeleton, useModal, Box, Spaced, Menu, Icon, Flex, Tabs} from '@glimpse/glass';
import {UpdateComponentModal}                                                        from 'features/component/update-component-modal';
import {ComponentDependencyPanel}                                                    from 'features/component-dependency/component-dependency-panel';
import {useParams}                                                                   from 'react-router-dom';
import {AppLayout}                                                                   from 'foundations/layouts/app-layout/app-layout';
import {DeleteComponentModal}                                                        from 'features/component/delete-component-modal';
import {glimpse}                                                                     from 'providers/glimpse/glimpse-provider';
import React                                                                         from 'react';
import {RequirePermission}                                                           from 'features/permission/require-permission';
import {useTranslation}                                                              from 'foundations/i18n/use-translation';
import {useQuery}                                                                    from 'foundations/net/use-query';
import {Component}                                                                   from '@glimpse/glimpse';
import {ComponentOwnerPanel}                                                         from 'features/component-owner/component-owner-panel';
import {ComponentPropertyPanel}                                                      from 'features/component-property/component-property-panel';
import {ComponentMetaPanel}                                                          from 'features/component/component-meta-panel';
import {ScorecardResultPanel}                                                        from 'features/scorecard-result/scorecard-result-panel';
import {LinkPanel}                 from 'features/link/link-panel';
import {ManageIntegrationAppModal} from 'features/integration-app/manage-integration-app-modal';
import {ComponentPipelinePanel}                                                      from 'features/component-pipeline/component-pipeline-panel';

export function ComponentScreen() {
    const {id}        = useParams();
    const {t}         = useTranslation();
    const integrationModal = useModal();
    const updateModal = useModal();
    const deleteModal = useModal();

    const {data, loading} = useQuery<Component>({
        key: ['component', id],
        fn:  async () => await glimpse.component.list({id})
    });

    const component = data?.[0];

    const menu = (
        <>
            <Menu.Group>
                <Menu.Item onClick={updateModal.toggle} icon={<Icon fa={['far', 'fa-pen']}/>}>
                    {t('Edit')}
                </Menu.Item>
                {
                    false &&
                    <Menu.Item onClick={integrationModal.toggle} icon={<Icon fa={['far', 'fa-arrows-repeat']}/>}>
                        {t('Integrations')}
                    </Menu.Item>
                }
                <Menu.Item onClick={deleteModal.toggle} icon={<Icon fa={['far', 'fa-trash']}/>}>
                    {t('Delete')}
                </Menu.Item>
            </Menu.Group>
        </>
    );

    return (
        <AppLayout>
            <RequirePermission permission={'components.read'} fallback>
                {
                    id &&
                    <>
                        <ManageIntegrationAppModal open={integrationModal.open} onClose={integrationModal.toggle} componentId={id!}/>
                        <DeleteComponentModal open={deleteModal.open} onClose={deleteModal.toggle} componentId={id!}/>
                        <UpdateComponentModal open={updateModal.open} onClose={updateModal.toggle} componentId={id!}/>
                    </>
                }
                <AppLayout.Header>
                    <Box pt={3} pb={3}>
                        <Grid justify='between'>
                            <div>
                                <Box pb={1}>
                                    {
                                        loading
                                            ? <Skeleton width='200px' height='22px'/>
                                            : <Flex align={'center'}>
                                                <Text fontSize={4} fontWeight='bolder'>{component?.name}</Text>
                                            </Flex>
                                    }
                                </Box>
                                {
                                    loading
                                        ? <Skeleton width='150px' height='22px'/>
                                        : <Text color='text.subtle'>{component?.description ?? t('No Description')}</Text>
                                }
                            </div>
                            <div>
                                <RequirePermission permission={'components.write'}>
                                    <Menu menu={menu}>
                                        <Button icon={'fas fa-ellipsis'}/>
                                    </Menu>
                                </RequirePermission>
                            </div>
                        </Grid>
                    </Box>
                    <Tabs onChange={() => {
                    }}>
                        <Tabs.Tab value={'overview'} current={'overview'} label={t('Overview')}/>
                    </Tabs>
                </AppLayout.Header>
                <AppLayout.Body>
                    <Grid gap={6}>
                        <Grid.Item xs={3}>
                            <Spaced gap={3} direction={'vertical'}>
                                <ComponentOwnerPanel componentId={id!}/>
                                <LinkPanel componentId={id!}/>
                                <ComponentMetaPanel componentId={id!}/>
                            </Spaced>
                        </Grid.Item>
                        <Grid.Item xs={9}>
                            <Spaced gap={3} direction={'vertical'}>
                                <ComponentPropertyPanel componentId={id!}/>
                                <ScorecardResultPanel componentId={id!}/>
                                <ComponentDependencyPanel componentId={id!}/>
                                <ComponentPipelinePanel componentId={id!}/>
                            </Spaced>
                        </Grid.Item>
                    </Grid>
                </AppLayout.Body>
            </RequirePermission>
        </AppLayout>
    );
}