import {Select}      from '@glimpse/glass';
import {SelectProps} from '@glimpse/glass';
import {glimpse}     from 'providers/glimpse/glimpse-provider';
import {Pipeline}    from '@glimpse/glimpse';
import {useQuery}    from 'foundations/net/use-query';

export function SelectPipeline(props: Omit<SelectProps, 'options'> & { exclude?: (Pipeline['id'] | null)[] }) {
    const {data} = useQuery<Pipeline>({
        key: ['pipeline'],
        fn:  glimpse.pipeline.list
    });

    const options = data
        ?.filter((pipeline) => !props.exclude?.includes(pipeline.id))
        .map(pipeline => {
            return {
                label: pipeline.name,
                value: pipeline.id
            };
        }) ?? [];

    return <Select {...props} options={options}/>;
}