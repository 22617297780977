import {Modal, Button, Form, Textbox, Textarea} from '@glimpse/glass';
import {ScorecardCheck}                         from '@glimpse/glimpse';
import {useForm}        from 'foundations/ui/form/use-form';
import {glimpse}        from 'providers/glimpse/glimpse-provider';
import {useTranslation} from 'foundations/i18n/use-translation';
import toast                                    from 'react-hot-toast';
import {useMutate}                              from 'foundations/net/use-mutate';

export function CreateScorecardCheckModal({
    open,
    onClose,
    scorecardId
}: {
    open: boolean,
    onClose: () => void,
    scorecardId: ScorecardCheck['scorecard_id']
}) {
    const {t}                      = useTranslation();
    const form                     = useForm<ScorecardCheck.Create.Params>({
        scorecard_id: scorecardId,
        points:       1
    });
    const {error, loading, submit} = useMutate<ScorecardCheck>({
        key:       ['scorecard-check', scorecardId],
        fn:        () => glimpse.scorecardCheck.create(form.data),
        onSuccess: () => {
            toast.success(t('Check created'));
            onClose();
        }
    });

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header title={t('Add Check')}/>
            <Modal.Body>
                <Form>
                    <Form.Field label={t('Name')} errors={error?.params.name}>
                        <Textbox {...form.register('name')} placeholder={t('')}/>
                    </Form.Field>
                    <Form.Field label={t('GQL')} errors={error?.params.gql}>
                        <Textarea {...form.register('gql')}/>
                    </Form.Field>
                </Form>
            </Modal.Body>
            <Modal.Footer right={
                <>
                    <Button onClick={onClose}>{t('Cancel')}</Button>
                    <Button variant='primary' loading={loading} onClick={submit}>{t('Add')}</Button>
                </>
            }/>
        </Modal>
    );
}