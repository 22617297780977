import {useCallback, useContext} from 'react';
import {AuthContext}             from 'features/auth/auth-context';
import Cookies                   from 'js-cookie';
import {Session}                 from '@glimpse/glimpse';
import {PosthogProvider}         from 'providers/posthog/posthog-provider';
import {SentryProvider}          from 'providers/sentry/sentry-provider';

export function useAuth() {
    const auth = useContext(AuthContext);

    const signOut = useCallback(() => {
        Cookies.remove('_glimpse_sid');
        PosthogProvider.clearIdentity();
        SentryProvider.clearIdentity();
        window.location.href = '/';
    }, []);

    const signIn = useCallback((token: Session['token']) => {
        Cookies.set('_glimpse_sid', token);
        window.location.href = '/';
    }, []);

    return {
        isSignedIn: typeof auth.session !== 'undefined',
        signOut,
        signIn
    };
}