import {Select}         from '@glimpse/glass';
import {SelectProps}    from '@glimpse/glass';
import {Component}      from '@glimpse/glimpse';
import {useTranslation} from 'foundations/i18n/use-translation';

export function SelectPropertyFormat(props: Omit<SelectProps, 'options'> & { exclude?: Component['id'][] }) {
    const {t}     = useTranslation();
    const options = [
        {
            label: t('Text'),
            value: 'text'
        },
        {
            label: t('Select'),
            value: 'select'
        }
    ];

    return <Select {...props} options={options}/>;
}