import {Modal, Button, Form, Textbox, Switch, Flex, Text, Box, Separator, Tooltip, Icon} from '@glimpse/glass';
import {ErrorModal}                                                                      from 'foundations/ui/error-modal/error-modal';
import {LoadingModal}                                                                    from 'foundations/ui/loading-modal/loading-modal';
import {useForm}                                                                         from 'foundations/ui/form/use-form';
import React                                                                             from 'react';
import {Integration}                                                                     from '@glimpse/glimpse';
import {glimpse}                                                                         from 'providers/glimpse/glimpse-provider';
import {useTranslation}                                                                  from 'foundations/i18n/use-translation';
import toast                                                                             from 'react-hot-toast';
import {useMutate}                                                                       from 'foundations/net/use-mutate';
import {useQuery}                                                                        from 'foundations/net/use-query';
import {IntegrationHelper}                                                               from 'features/integration/integration-helper';

export function UpdateIntegrationModal({
    open,
    onClose,
    integrationId
}: {
    open: boolean,
    onClose: () => void,
    integrationId: Integration['id']
}) {
    const {t}  = useTranslation();
    const form = useForm<Integration.Update.Params>();

    const {data, loading, error} = useQuery<Integration>({
        key:       ['integration', integrationId],
        fn:        () => glimpse.integration.list({id: integrationId}),
        onSuccess: (res) => form.setData(res.data?.[0])
    });

    const mutate = useMutate<Integration>({
        key:       ['integration', integrationId],
        fn:        () => glimpse.integration.update(integrationId, form.data),
        onSuccess: () => {
            toast.success(t('Integration updated'));
            onClose();
        }
    });

    if (loading)
        return <LoadingModal onClose={onClose} open={open}/>;

    if (error)
        return <ErrorModal onClose={onClose} open={open}/>;

    const meta = IntegrationHelper.get(data?.[0].connector);

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header title={t('Update Integration')}/>
            <Modal.Body>
                <Form>
                    <Form.Field label={t('Name')} errors={mutate.error?.params.name}>
                        <Textbox {...form.register('name')} placeholder={t('Installation #472')}/>
                    </Form.Field>
                    <Box pt={1} pb={3}>
                        <Separator/>
                    </Box>
                    {
                        meta?.features?.components &&
                        <Form.Field>
                            <Flex justify={'between'} align={'center'}>
                                <Flex gap={1} align={'center'}>
                                    <Text>{t('Sync Components')}</Text>
                                    <Tooltip content={t('Automatically import new components and keep them up to date')}>
                                        <Icon color={'n50'} fa={['fas', 'fa-circle-info']}/>
                                    </Tooltip>
                                </Flex>
                                <Switch {...form.register('sync_component')}/>
                            </Flex>
                        </Form.Field>
                    }
                    {
                        meta?.features?.teams &&
                        <Form.Field>
                            <Flex justify={'between'} align={'center'}>
                                <Flex gap={1} align={'center'}>
                                    <Text>{t('Sync Teams')}</Text>
                                    <Tooltip content={t('Automatically import new teams and keep them up to date')}>
                                        <Icon color={'n50'} fa={['fas', 'fa-circle-info']}/>
                                    </Tooltip>
                                </Flex>
                                <Switch {...form.register('sync_team')}/>
                            </Flex>
                        </Form.Field>
                    }
                    {
                        meta?.features?.repositories &&
                        <Form.Field>
                            <Flex justify={'between'} align={'center'}>
                                <Flex gap={1} align={'center'}>
                                    <Text>{t('Sync Repositories')}</Text>
                                    <Tooltip content={t('Automatically import new repositories and keep them up to date')}>
                                        <Icon color={'n50'} fa={['fas', 'fa-circle-info']}/>
                                    </Tooltip>
                                </Flex>
                                <Switch {...form.register('sync_repository')}/>
                            </Flex>
                        </Form.Field>
                    }
                    {
                        meta?.features?.pullRequests &&
                        <Form.Field>
                            <Flex justify={'between'} align={'center'}> <Flex gap={1} align={'center'}>
                                <Text>{t('Sync Pull Requests')}</Text>
                                <Tooltip content={t('Automatically import new pull requests and keep them up to date')}>
                                    <Icon color={'n50'} fa={['fas', 'fa-circle-info']}/>
                                </Tooltip>
                            </Flex>
                                <Switch {...form.register('sync_pull_request')}/>
                            </Flex>
                        </Form.Field>
                    }
                    {
                        meta?.features?.pipelines &&
                        <Form.Field>
                            <Flex justify={'between'} align={'center'}>
                                <Flex gap={1} align={'center'}>
                                    <Text>{t('Sync Pipelines')}</Text>
                                    <Tooltip content={t('Automatically import new pipelines and keep them up to date')}>
                                        <Icon color={'n50'} fa={['fas', 'fa-circle-info']}/>
                                    </Tooltip>
                                </Flex>
                                <Switch {...form.register('sync_pipeline')}/>
                            </Flex>
                        </Form.Field>
                    }
                </Form>
            </Modal.Body>
            <Modal.Footer right={
                <>
                    <Button onClick={onClose}>{t('Cancel')}</Button>
                    <Button variant='primary' loading={mutate.loading} onClick={mutate.submit}>{t('Save')}</Button>
                </>
            }/>
        </Modal>
    );
}