import {Select}      from '@glimpse/glass';
import {SelectProps} from '@glimpse/glass';
import {glimpse}  from 'providers/glimpse/glimpse-provider';
import {GqlQuery} from '@glimpse/glimpse';
import {useQuery} from 'foundations/net/use-query';

export function SelectGithubRepo(props: Omit<SelectProps, 'options'>) {
    const query           = {
        query: 'github.repos()'
    };
    const {loading, data} = useQuery<GqlQuery>({
        key: ['gql-query', query],
        fn:  () => glimpse.gqlQuery.create(query)
    });

    const options = data[0]?.result
        .map((repo: any) => {
            return {
                label: repo.full_name,
                value: repo.full_name
            };
        }) ?? [];

    return <Select {...props} options={options} loading={loading}/>;
}