import {PullRequestTable} from 'features/pull-request/pull-request-table';
import {useForm}          from 'foundations/ui/form/use-form';
import {PullRequest}      from '@glimpse/glimpse';
import {Box, BoxedSelector, Text} from '@glimpse/glass';
import {AppLayout}                from 'foundations/layouts/app-layout/app-layout';
import React                      from 'react';
import {useTranslation}           from 'foundations/i18n/use-translation';

export function PullRequestsScreen() {
    const {t}  = useTranslation();
    const form = useForm<PullRequest.List.Params>({
        state: 'open'
    });

    const options = [
        {label: t('Open'), value: 'open'},
        {label: t('Closed'), value: 'closed'},
        {label: t('All'), value: 'all'}
    ];

    return (
        <AppLayout title={t('Pull Requests')}>
            <AppLayout.Header>
                <Box pt={3} pb={3}>
                    <Text fontSize={4} fontWeight='bolder'>{t('Pull Requests')}</Text>
                </Box>
            </AppLayout.Header>
            <AppLayout.Body>
                <Box mb={2}>
                    <BoxedSelector {...form.register('state')} options={options}/>
                </Box>
                <PullRequestTable query={form.data}/>
            </AppLayout.Body>
        </AppLayout>
    );
}