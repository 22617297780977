import {Glimpse} from '@glimpse/glimpse';
import {Config}  from 'foundations/config/config';

export class GlimpseProvider {

    /**
     * Glimpse Client
     * @private
     */
    private static _client: Glimpse<any>;

    static key = Config.env('REACT_APP_GLIMPSE_KEY');

    static init() {
        this._client         = new Glimpse(this.key);
        this._client.baseUrl = Config.env('REACT_APP_GLIMPSE_HOST');
    }

    /**
     * Get an instance of the Glimpse client
     */
    static get client() {
        if (!this._client) {
            this.init();
        }
        return this._client;
    }
}

export const glimpse = GlimpseProvider.client;