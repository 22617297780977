import {Modal, Button, Form, Textbox} from '@glimpse/glass';
import {AppListing}                   from '@glimpse/glimpse';
import {useForm}                      from 'foundations/ui/form/use-form';
import {glimpse}                      from 'providers/glimpse/glimpse-provider';
import {useTranslation}               from 'foundations/i18n/use-translation';
import toast                          from 'react-hot-toast';
import {useMutate}                    from 'foundations/net/use-mutate';

export function CreateAppListingModal({
    open,
    onClose
}: {
    open: boolean,
    onClose: () => void
}) {
    const {t}                      = useTranslation();
    const form                     = useForm<AppListing.Create.Params>();
    const {error, loading, submit} = useMutate({
        key:       ['app-listing'],
        fn:        () => glimpse.appListing.create(form.data),
        onSuccess: () => {
            toast.success(t('App Created'));
            onClose();
        }
    });

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header title={t('Add App')}/>
            <Modal.Body>
                <Form>
                    <Form.Field label={t('Label')} errors={error?.params.label}>
                        <Textbox {...form.register('label')} placeholder={t('Sentry')}/>
                    </Form.Field>
                    <Form.Field label={t('URL')} errors={error?.params.url}>
                        <Textbox {...form.register('url')}/>
                    </Form.Field>
                </Form>
            </Modal.Body>
            <Modal.Footer right={
                <>
                    <Button onClick={onClose}>{t('Cancel')}</Button>
                    <Button variant='primary' loading={loading} onClick={submit}>{t('Add')}</Button>
                </>
            }/>
        </Modal>
    );
}