import {Table, Text, Box, Icon, Flex, Reveal, Badge} from '@glimpse/glass';
import {Repository}                                  from '@glimpse/glimpse';
import React                                         from 'react';
import {glimpse}                                     from 'providers/glimpse/glimpse-provider';
import {useTranslation}                              from 'foundations/i18n/use-translation';
import {CenteredSpinner}                             from 'foundations/ui/interstitial/centered-spinner';
import {useQuery}                                    from 'foundations/net/use-query';
import {RequirePermission}                           from 'features/permission/require-permission';

export function RepositoryTable({
    query = {},
    empty
}: {
    query?: Repository.List.Params,
    empty?: React.ReactElement
}) {
    const {t}             = useTranslation();
    const {loading, data} = useQuery<Repository>({
        key: ['repository', query],
        fn:  () => glimpse.repository.list(query)
    });

    if (loading) {
        return <CenteredSpinner/>;
    }

    if (data?.length === 0 && empty) {
        return empty;
    }

    return (
        <RequirePermission permission={'repositories.read'} fallback>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell> </Table.HeaderCell>
                        <Table.HeaderCell> </Table.HeaderCell>
                        <Table.HeaderCell> </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                {
                    data?.length === 0 &&
                    <Box p={2}>
                        <Text color='text.subtle'>{t('There are no repositories to display')}</Text>
                    </Box>
                }
                <Table.Body>
                    {
                        data?.map(repo =>
                            <Reveal key={repo.id}>
                                <Table.Row effects={['clickable', 'hoverable']} key={repo.id} onClick={() => window.open(repo.web_url ?? '#', '_blank')}>
                                    <Table.BodyCell>
                                        <Box pt={1} pb={1}>
                                            <Flex align={'center'}>
                                                <Box mr={1.5}>
                                                    <Icon fontSize={4} fa={['fab', 'fa-github']}/>
                                                </Box>
                                                <Box>
                                                    <Text fontWeight={'bold'}>
                                                        {`${repo.namespace_name} / ${repo.name}`}
                                                    </Text>
                                                    <Text color={'text.subtle'}>{repo.description}</Text>
                                                </Box>
                                            </Flex>
                                        </Box>
                                    </Table.BodyCell>
                                    <Table.BodyCell>
                                        <Badge color={'neutral.subtle'} text={repo.visibility}/>
                                    </Table.BodyCell>
                                    <Table.BodyCell>
                                        <Text>{repo.languages[0]}</Text>
                                    </Table.BodyCell>
                                </Table.Row>
                            </Reveal>
                        )
                    }
                </Table.Body>
            </Table>
        </RequirePermission>
    );
}