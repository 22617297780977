import {Table, Text, Box, Icon, Spaced} from '@glimpse/glass';
import React                            from 'react';
import {Issue}                          from '@glimpse/glimpse';
import {Empty}                          from 'foundations/ui/empty/empty';
import {CenteredSpinner}                from 'foundations/ui/interstitial/centered-spinner';
import {useTranslation}                 from 'foundations/i18n/use-translation';
import {useQuery}                       from 'foundations/net/use-query';
import {glimpse}                        from 'providers/glimpse/glimpse-provider';
import {RequirePermission}              from 'features/permission/require-permission';

export function IssueTable({
    query
}: {
    query?: Issue.List.Params
}) {
    const {t}             = useTranslation();
    const {loading, data} = useQuery<Issue>({
        key: ['issue', query],
        fn:  () => glimpse.issue.list(query)
    });

    if (loading) {
        return <CenteredSpinner/>;
    }

    if (data?.length === 0) {
        return <Empty
            icon={<Icon fa={['far', 'fa-bug']}/>}
            title={t('Connect your issues')}
            desc={t('Issues will show up here after connecting your Git provider')}/>;
    }

    return (
        <RequirePermission permission={'issues.read'} fallback>

            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell> </Table.HeaderCell>
                        <Table.HeaderCell>{t('Name')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('Description')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('Repo')}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                {
                    data?.length === 0 &&
                    <Box p={2}>
                        <Text color='text.subtle'>{t('There are no repositories to display')}</Text>
                    </Box>
                }
                <Table.Body>
                    {
                        data?.map(issue =>
                            <Table.Row effects={['clickable', 'hoverable']} key={issue.id}>
                                <Table.BodyCell shrink>
                                    {issue.state === 'open' && <Icon fontSize={3} color={'success'} fa={['fas', 'bug']}/>}
                                    {issue.state === 'closed' && <Icon fontSize={3} color={'danger'} fa={['fas', 'bug-slash']}/>}
                                </Table.BodyCell>
                                <Table.BodyCell>
                                    <Spaced direction={'vertical'} gap={0}>
                                        <Text fontWeight={'bold'}>{issue.title}</Text>
                                        <Text color={'text.subtle'}>#{issue.number}</Text>
                                    </Spaced>
                                </Table.BodyCell>
                                <Table.BodyCell>{issue.description ?? '-'}</Table.BodyCell>
                            </Table.Row>
                        )
                    }
                </Table.Body>
            </Table>
        </RequirePermission>
    );
}