import {Text, Grid, Icon, Box, Image, Flex, Tooltip} from '@glimpse/glass';
import React                                         from 'react';
import {Pipeline}                                    from '@glimpse/glimpse';
import {glimpse}                                     from 'providers/glimpse/glimpse-provider';
import {Empty}                                       from 'foundations/ui/empty/empty';
import {Link}                                        from 'react-router-dom';
import {useTranslation}                              from 'foundations/i18n/use-translation';
import {CenteredSpinner}                             from 'foundations/ui/interstitial/centered-spinner';
import {useQuery}                                    from 'foundations/net/use-query';
import {RequirePermission}                           from 'features/permission/require-permission';
import {IntegrationHelper}                           from 'features/integration/integration-helper';
import {PipelineStatusBadge}                         from 'features/pipeline/pipeline-status-badge';

export function PipelinesGrid({
    query
}: {
    query?: Pipeline.List.Params
}) {
    const {t}             = useTranslation();
    const {data, loading} = useQuery<Pipeline>({
        key: ['pipelines', query],
        fn:  async () => await glimpse.pipeline.list(query)
    });

    if (loading) {
        return <CenteredSpinner/>;
    }

    if (data?.length === 0) {
        return <Empty
            icon={<Icon fa={['far', 'fa-code-pull-request']}/>}
            title={t('Connect your pipelines')}
            desc={t('Your CI/CD pipelines and builds will show up here after connecting your CI platform.')}/>;
    }

    return (
        <RequirePermission permission={'pipelines.read'} fallback>
            <Grid gap={1} xs={12} md={12}>
                {
                    data?.map(pipeline =>
                        <Link to={pipeline.web_url ?? '#'} target={'_blank'} key={pipeline.id}>
                            <Box p={3} radius={1} border={'default'} effects={['clickable', 'hoverable']}>
                                <Flex justify={'between'}>
                                    <Flex gap={1} align={'center'}>
                                        <Tooltip content={IntegrationHelper.get(pipeline.source_connector).label}>
                                            <Image width={'20px'} source={IntegrationHelper.get(pipeline.source_connector).icon}/>
                                        </Tooltip>
                                        <Text fontWeight='bold'>{pipeline.name}</Text>
                                    </Flex>
                                    <div>
                                        <PipelineStatusBadge status={pipeline.status}/>
                                    </div>
                                </Flex>
                            </Box>
                        </Link>
                    )
                }
            </Grid>
        </RequirePermission>
    );
}