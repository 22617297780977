import {Repository}  from '@glimpse/glimpse';
import {Select}      from '@glimpse/glass';
import {SelectProps} from '@glimpse/glass';
import {glimpse}     from 'providers/glimpse/glimpse-provider';
import {useQuery}    from 'foundations/net/use-query';

export function SelectRepository(props: Omit<SelectProps, 'options'>) {
    const api = useQuery<Repository>({
        key: ['repository'],
        fn:  glimpse.repository.list
    });

    const options = api.data?.map(repo => {
        return {
            label: `${repo.namespace_name}/${repo.name}`,
            value: repo.git_url
        };
    }) ?? [];

    return <Select {...props} options={options} emptyMessage={'No Repositories Connected'} loading={api.loading}/>;
}