"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Date = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
function Date(_a) {
    var children = _a.children;
    return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: children === undefined ? '-' : DateHelper.iso8601ToReadable(children) });
}
exports.Date = Date;
var DateHelper = (function () {
    function DateHelper() {
    }
    DateHelper.iso8601ToReadable = function (isoDate) {
        var date = new Date(isoDate);
        var month = this.monthNumToName(date.getMonth());
        return "".concat(month, " ").concat(date.getDay(), ", ").concat(date.getFullYear());
    };
    DateHelper.monthNumToName = function (monthNumber) {
        if (monthNumber < 1 || monthNumber > 12) {
            throw new RangeError('monthNumber must be between 1 and 12');
        }
        var months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ];
        return months[monthNumber];
    };
    return DateHelper;
}());
