import {Box, Icon, Tooltip} from '@glimpse/glass';
import {Component}          from '@glimpse/glimpse';
import {useTranslation}     from 'foundations/i18n/use-translation';
import style                from 'features/component/component-icon.module.css';

export function ComponentIcon({
    componentType
}: {
    componentType: Component['type']
}) {

    const {t} = useTranslation();
    const def: Record<Component['type'], {
        icon: string,
        label: string
    }>        = {
        application:    {
            icon:  'fa-sidebar',
            label: t('Application')
        },
        ai_model:       {
            icon:  'fa-microchip-ai',
            label: t('AI Model')
        },
        ai_agent:       {
            icon:  'fa-brain-circuit',
            label: t('AI Agent')
        },
        capability:     {
            icon:  'fa-screwdriver-wrench',
            label: t('Capability')
        },
        infrastructure: {
            icon:  'fa-server',
            label: t('Infrastructure')
        },
        feature:       {
            icon:  'fa-boxes-stacked',
            label: t('Feature')
        },
        library:        {
            icon:  'fa-books',
            label: t('Library')
        },
        other:          {
            icon:  'fa-grid-2',
            label: t('Other')
        },
        service:        {
            icon:  'fa-gears',
            label: t('Service')
        },
        ui_element:     {
            icon:  'fa-paintbrush-pencil',
            label: t('UI Element')
        },
        website:        {
            icon:  'fa-globe',
            label: t('Website')
        }
    };

    return (
        <Tooltip content={def[componentType].label}>
            <Box p={1} radius={1} border={'default'}>
                <Icon fa={['far', def[componentType].icon]} color={'text.subtle'} className={style.icon}/>
            </Box>
        </Tooltip>
    );
}