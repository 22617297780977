import posthog from 'posthog-js';
import {User}  from '@glimpse/glimpse';

export class PosthogProvider {

    public static init() {
        if (process.env.NODE_ENV === 'production') {
            posthog.init('phc_gubF4DX6PWbgw2D2REsMzukAxvQxaBFg3VlM3gZdEKw');
        }
    }

    public static setIdentity(user: User) {
        if (process.env.NODE_ENV !== 'production') {
            return;
        }

        posthog.identify(user.id,
            {
                email: user.email,
                name:  user.name
            }
        );
    }

    public static clearIdentity() {
        if (process.env.NODE_ENV !== 'production') {
            return;
        }

        posthog.reset();
    }
}