"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ObjectUtility = void 0;
var ObjectUtility = (function () {
    function ObjectUtility() {
    }
    var _a;
    _a = ObjectUtility;
    ObjectUtility.setNestedKey = function (obj, path, value) {
        var _b = path.split('.'), head = _b[0], rest = _b.slice(1);
        !rest.length
            ? obj[head] = value
            : _a.setNestedKey(obj[head], rest.join('.'), value);
    };
    return ObjectUtility;
}());
exports.ObjectUtility = ObjectUtility;
