import '@fortawesome/fontawesome-svg-core/styles.css';
import '@glimpse/glass/build/main.css';
import {Glass, Box, FontawesomeClientLoader, Spinner} from '@glimpse/glass';
import {LoginScreen}                                  from 'features/auth/login/login-screen';
import {SignupScreen}                                 from 'features/auth/signup/signup-screen';
import {HomeScreen}                                   from 'features/home/home-screen';
import {ComponentScreen}                              from 'features/component/component-screen';
import {TeamScreen}                                   from 'features/team/team-screen';
import React, {useEffect, useState}                   from 'react';
import {BrowserRouter, Routes, Route}                 from 'react-router-dom';
import {Auth, AuthPackage}                            from 'features/auth/auth';
import {PrivateRoutes}                                from 'foundations/routing/private-routes';
import {PublicRoutes}                                 from 'foundations/routing/public-routes';
import {ConsumePasswordResetScreen}                   from 'features/auth/password-reset/consume-password-reset-screen';
import {RequestPasswordResetScreen}                   from 'features/auth/password-reset/request-password-reset-screen';
import {CreateGithubIntegrationScreen}                from 'features/integration/github/create-github-integration-screen';
import {PullRequestScreen}                            from 'features/pull-request/pull-request-screen';
import {fal}                                          from '@fortawesome/pro-light-svg-icons';
import {fas}                                          from '@fortawesome/pro-solid-svg-icons';
import {far}                                          from '@fortawesome/pro-regular-svg-icons';
import {fab}                                          from '@fortawesome/free-brands-svg-icons';
import {TeamsScreen}                                  from 'features/team/teams-screen';
import {UsersScreen}                                  from 'features/user/users-screen';
import {RepositoriesScreen}                           from 'features/repository/repositories-screen';
import {ScaffoldsScreen}                              from 'features/scaffold/scaffolds-screen';
import {ComponentsScreen}                             from 'features/component/components-screen';
import {PullRequestsScreen}                           from 'features/pull-request/pull-requests-screen';
import {IssuesScreen}                                 from 'features/issue/issues-screen';
import {ScorecardsScreen}                             from 'features/scorecard/scorecards-screen';
import {SettingsScreen}                               from 'features/settings/settings-screen';
import {SettingsBillingScreen}                        from 'features/settings/settings-billing-screen';
import {SettingsIntegrationsScreen}                   from 'features/settings/settings-integrations-screen';
import {SettingsWorkspaceMembersScreen}               from 'features/settings/settings-workspace-members-screen';
import {SettingsIntegrationScreen}                    from 'features/settings/settings-integration-screen';
import {AuthContext}                                  from 'features/auth/auth-context';
import {CreateJiraIntegrationScreen}                  from 'features/integration/jira/create-jira-integration-screen';
import {CreatePagerdutyIntegrationScreen}             from 'features/integration/pagerduty/create-pagerduty-integration-screen';
import {ScorecardScreen}                              from 'features/scorecard/scorecard-screen';
import {SettingsPropertiesScreen}                     from 'features/settings/settings-properties-screen';
import {InsightsScreen}                               from 'features/insight/insights-screen';
import {SentryProvider}                               from 'providers/sentry/sentry-provider';
import {Toaster}                                      from 'react-hot-toast';
import {ActionsScreen}                                from 'features/action/actions-screen';
import {SettingsApiKeysScreen}                        from 'features/settings/settings-api-keys-screen';
import {DeploymentsScreen}                            from 'features/deployment/deployments-screen';
import {PosthogProvider}                              from 'providers/posthog/posthog-provider';
import {PasswordResetSentScreen}                      from 'features/auth/password-reset/password-reset-sent-screen';
import {PasswordResetCompleteScreen}                  from 'features/auth/password-reset/password-reset-complete-screen';
import {QueryClient, QueryClientProvider}             from '@tanstack/react-query';
import {CreateGithubTokenIntegrationScreen}           from 'features/integration/github-token/create-github-token-integration-screen';
import {CreateGitlabIntegrationScreen}                from 'features/integration/gitlab/create-gitlab-integration-screen';
import {SettingsRolesScreen}                          from 'features/settings/settings-roles-screen';
import {RoleScreen}                                   from 'features/role/role-screen';
import {CreateAzureIntegrationScreen}                 from 'features/integration/azure/create-azure-integration-screen';
import {JoinScreen}                                   from 'features/auth/join/join-screen';
import {CreateCustomApiIntegrationScreen}             from 'features/integration/custom-api/create-custom-api-integration-screen';
import {ScorecardResultScreen}                        from 'features/scorecard-result/scorecard-result-screen';
import {AuthRoutes}                                   from 'foundations/routing/auth-routes';
import {HubspotProvider}                              from 'providers/hubspot/hubspot-provider';
import {PipelinesScreen}                              from 'features/pipeline/pipelines-screen';
import {AppListingsScreen}                            from 'features/app-listing/app-listings-screen';
import {CreatePosthogIntegrationScreen}               from 'features/integration/posthog/create-posthog-integration-screen';

SentryProvider.init();
PosthogProvider.init();

const queryClient = new QueryClient();

function App() {
    const [loading, setLoading] = useState(true);
    const [auth, setAuth]       = useState<AuthPackage>({} as AuthPackage);

    useEffect(() => {
        Auth.init()
            .then((auth) => {
                setAuth(auth);
                if (auth.user) {
                    PosthogProvider.setIdentity(auth.user);
                    SentryProvider.setIdentity(auth.user);
                    HubspotProvider.setIdentity(auth.user);
                }
            })
            .catch((res) => console.log(res))
            .finally(() => setLoading(false));
    }, []);

    if (loading) {
        return (
            <Box pt={16} mt={16} xAlign={'center'}>
                <Spinner/>
            </Box>
        );
    }

    return (
        <Glass>
            <QueryClientProvider client={queryClient}>
                <AuthContext.Provider value={auth}>
                    <Toaster/>
                    <FontawesomeClientLoader packs={[far, fal, fas, fab]}/>
                    <BrowserRouter>
                        <Routes>
                            <Route element={<PublicRoutes/>}>
                                <Route element={<AuthRoutes/>}>
                                    <Route path='/signup' element={<SignupScreen/>}/>
                                    <Route path='/login' element={<LoginScreen/>}/>
                                </Route>
                                <Route path='/reset-password' element={<RequestPasswordResetScreen/>}/>
                                <Route path='/reset-password/sent' element={<PasswordResetSentScreen/>}/>
                                <Route path='/reset-password/consume' element={<ConsumePasswordResetScreen/>}/>
                                <Route path='/reset-password/complete' element={<PasswordResetCompleteScreen/>}/>
                                <Route path='/join' element={<JoinScreen/>}/>
                            </Route>
                            <Route element={<PrivateRoutes/>}>
                                <Route path='/' element={<HomeScreen/>}/>

                                <Route path='/actions' element={<ActionsScreen/>}/>
                                <Route path='/apps' element={<AppListingsScreen/>}/>
                                <Route path='/pipelines' element={<PipelinesScreen/>}/>
                                <Route path='/scaffolds' element={<ScaffoldsScreen/>}/>
                                <Route path='/scorecards' element={<ScorecardsScreen/>}/>
                                <Route path='/scorecards/:id' element={<ScorecardScreen/>}/>
                                <Route path='/deployments' element={<DeploymentsScreen/>}/>
                                <Route path='/components' element={<ComponentsScreen/>}/>
                                <Route path='/components/:id' element={<ComponentScreen/>}/>
                                <Route path='/components/:component_id/scorecard/:scorecard_id' element={<ScorecardResultScreen/>}/>

                                <Route path='/people' element={<UsersScreen/>}/>
                                <Route path='/teams' element={<TeamsScreen/>}/>
                                <Route path='/teams/:id' element={<TeamScreen/>}/>

                                <Route path='/repos' element={<RepositoriesScreen/>}/>
                                <Route path='/issues' element={<IssuesScreen/>}/>
                                <Route path='/pull-requests' element={<PullRequestsScreen/>}/>
                                <Route path='/pull-requests/:id' element={<PullRequestScreen/>}/>

                                <Route path='/insights' element={<InsightsScreen/>}/>

                                <Route path='/roles/:id' element={<RoleScreen/>}/>

                                <Route path='/settings' element={<SettingsScreen/>}/>
                                <Route path='/settings/api-keys' element={<SettingsApiKeysScreen/>}/>
                                <Route path='/settings/roles' element={<SettingsRolesScreen/>}/>
                                <Route path='/settings/workspace/members' element={<SettingsWorkspaceMembersScreen/>}/>
                                <Route path='/settings/billing' element={<SettingsBillingScreen/>}/>
                                <Route path='/settings/properties' element={<SettingsPropertiesScreen/>}/>
                                <Route path='/settings/integrations' element={<SettingsIntegrationsScreen/>}/>
                                <Route path='/settings/integrations/:connector' element={<SettingsIntegrationScreen/>}/>
                                <Route path='/settings/integrations/azure/create' element={<CreateAzureIntegrationScreen/>}/>
                                <Route path='/settings/integrations/github/create' element={<CreateGithubIntegrationScreen/>}/>
                                <Route path='/settings/integrations/gitlab/create' element={<CreateGitlabIntegrationScreen/>}/>
                                <Route path='/settings/integrations/posthog/create' element={<CreatePosthogIntegrationScreen/>}/>
                                <Route path='/settings/integrations/custom-api/create' element={<CreateCustomApiIntegrationScreen/>}/>
                                <Route path='/settings/integrations/github-token/create' element={<CreateGithubTokenIntegrationScreen/>}/>
                                <Route path='/settings/integrations/jira/create' element={<CreateJiraIntegrationScreen/>}/>
                                <Route path='/settings/integrations/pagerduty/create' element={<CreatePagerdutyIntegrationScreen/>}/>
                            </Route>
                        </Routes>
                    </BrowserRouter>
                </AuthContext.Provider>
            </QueryClientProvider>
        </Glass>
    );
}

export default App;
