import {ScorecardCheck}                                                                      from '@glimpse/glimpse';
import {Table, Button, useModal, Code, Reveal, ConfinedClick, Icon, Box, Text, Flex, Spaced} from '@glimpse/glass';
import {glimpse}                                                                             from 'providers/glimpse/glimpse-provider';
import React, {useState}                                                                     from 'react';
import {DeleteScorecardCheckModal}                                                           from 'features/scorecard-check/delete-scorecard-check-modal';
import {UpdateScorecardCheckModal}                                                           from 'features/scorecard-check/update-scorecard-check-modal';
import {useTranslation}                                                                      from 'foundations/i18n/use-translation';
import {useQuery}                                                                            from 'foundations/net/use-query';
import {RequirePermission}                                                                   from 'features/permission/require-permission';
import {CenteredSpinner}                                                                     from 'foundations/ui/interstitial/centered-spinner';

export function ScorecardCheckTable({
    query = {}
}: {
    query?: ScorecardCheck.List.Params
}) {
    const {t}                     = useTranslation();
    const updateModal             = useModal();
    const deleteModal             = useModal();
    const [selected, setSelected] = useState<ScorecardCheck['id']>();
    const {loading, data}         = useQuery<ScorecardCheck>({
        key: ['scorecard-check', query],
        fn:  () => glimpse.scorecardCheck.list(query)
    });

    const deleteCheck = (id: ScorecardCheck['id']) => {
        setSelected(id);
        deleteModal.toggle();
    };

    const updateCheck = (id: ScorecardCheck['id']) => {
        setSelected(id);
        updateModal.toggle();
    };

    if (loading) {
        return (
            <Box border={'default'} xAlign={'center'} radius={1}>
                <CenteredSpinner/>
            </Box>
        );
    }

    if (data?.length === 0) {
        return (
            <Box border={'default'} pt={4} pb={4} xAlign={'center'} radius={1}>
                <Text color='text.subtle'>{t('No checks created yet')}</Text>
            </Box>
        );
    }

    return (
        <RequirePermission permission={'scorecards.read'} fallback>
            {
                selected &&
                <>
                    <UpdateScorecardCheckModal open={updateModal.open} onClose={updateModal.toggle} scorecardCheckId={selected}/>
                    <DeleteScorecardCheckModal open={deleteModal.open} onClose={deleteModal.toggle} scorecardCheckId={selected}/>
                </>
            }
            <Spaced gap={1} direction={'vertical'}>
                {
                    data?.map(check =>
                        <Reveal key={check.id}>
                            <Box effects={['hoverable']} p={2} border={'default'} radius={1}>
                                <Flex justify={'between'}>
                                    <div>
                                        <Box mb={1}>
                                            <Text fontWeight={'bold'}>{check.name}</Text>
                                        </Box>
                                        <Code>
                                            {check.gql}
                                        </Code>
                                    </div>
                                    <Reveal.Hidden>
                                        <ConfinedClick>
                                            <Button spacing='compact' icon='far fa-pen' onClick={() => updateCheck(check.id)}/>
                                            <Button spacing='compact' icon='far fa-trash' onClick={() => deleteCheck(check.id)}/>
                                        </ConfinedClick>
                                    </Reveal.Hidden>
                                </Flex>
                            </Box>
                        </Reveal>
                    )
                }
            </Spaced>
        </RequirePermission>
    );
}