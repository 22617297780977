import {Scaffold}                                        from '@glimpse/glimpse';
import {Modal, Button, Code, Text, Separator, List, Box} from '@glimpse/glass';
import {ErrorModal}                                      from 'foundations/ui/error-modal/error-modal';
import {LoadingModal}                                    from 'foundations/ui/loading-modal/loading-modal';
import {glimpse}                                         from 'providers/glimpse/glimpse-provider';
import {useTranslation}                                  from 'foundations/i18n/use-translation';
import {useQuery}                                        from 'foundations/net/use-query';

export function ScaffoldModal({
    onClose,
    open,
    scaffoldId
}: {
    onClose: () => void,
    open: boolean,
    scaffoldId: Scaffold['id']
}) {
    const {t}                    = useTranslation();
    const {data, error, loading} = useQuery<Scaffold>({
        key: ['scaffold', scaffoldId],
        fn:  () => glimpse.scaffold.list({id: scaffoldId})
    });

    const scaffold = data?.[0];

    if (loading)
        return <LoadingModal onClose={onClose} open={open}/>;

    if (error)
        return <ErrorModal onClose={onClose} open={open}/>;

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header title={scaffold?.name ?? ''}/>
            <Modal.Body>
                <Text>{scaffold?.description ?? t('No description')}</Text>
                <Box pt={3} pb={3}>
                    <Separator/>
                </Box>
                <Box pb={1}>
                    <Text fontWeight='bold'>{t('Use this Scaffold')}</Text>
                </Box>
                <Box pb={2}>
                    <Text>{t('To use this scaffold you need clone the repository locally and set a new remote.')}</Text>
                </Box>
                <Box>
                    <List type='decimal'>
                        <List.Item><Code>{`git clone ${scaffold?.template_url}`}</Code></List.Item>
                        <List.Item><Code>git remote set-url origin https://github.com/NEW/YOUR_REPO</Code></List.Item>
                    </List>
                </Box>
            </Modal.Body>
            <Modal.Footer
                right={
                    <Button onClick={onClose}>{t('Close')}</Button>
                }
            />
        </Modal>
    );
}