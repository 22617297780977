import {Icon, Reveal, Button, ConfinedClick, useModal, Grid, Box, Text} from '@glimpse/glass';
import {Component, Property}                                            from '@glimpse/glimpse';
import React, {useState} from 'react';
import {glimpse}         from 'providers/glimpse/glimpse-provider';
import {Empty}           from 'foundations/ui/empty/empty';
import {useTranslation}                                                 from 'foundations/i18n/use-translation';
import {UpdatePropertyModal}                                            from 'features/property/update-property-modal';
import {DeletePropertyModal}                                            from 'features/property/delete-property-modal';
import {useQuery}                                                       from 'foundations/net/use-query';
import {RequirePermission}                                              from 'features/permission/require-permission';

export function PropertyTable({
    query = {}
}: {
    query?: Component.List.Params,
}) {
    const {t}                     = useTranslation();
    const [selected, setSelected] = useState<Property['id']>();
    const deleteModal             = useModal();
    const updateModal             = useModal();
    const {data, loading}         = useQuery<Property>({
        key: ['property', query],
        fn:  () => glimpse.property.list(query)
    });

    if (loading) {
        return null;
    }

    if (data?.length === 0) {
        return <Empty
            icon={<Icon fa={['far', 'fa-pen-field']}/>}
            title={t('Add your first property')}
            desc={t('Custom properties allow you to add structured information to your components')}/>;
    }

    const onDelete = (id: Property['id']) => {
        setSelected(id);
        deleteModal.toggle();
    };

    const onUpdate = (id: Property['id']) => {
        setSelected(id);
        updateModal.toggle();
    };

    return (
        <RequirePermission permission={'properties.read'} fallback>
            <UpdatePropertyModal open={updateModal.open} onClose={updateModal.toggle} propertyId={selected!}/>
            <DeletePropertyModal open={deleteModal.open} onClose={deleteModal.toggle} propertyId={selected!}/>
            <Grid gap={1} xs={12}>
                {
                    data?.map(field =>
                        <Reveal>
                            <Box radius={1} border={'default'} p={2} effects={['hoverable', 'clickable']}>
                                <Grid justify={'between'} align={'top'}>
                                    <Grid.Item shrink>
                                        <Text fontWeight={'bold'}>{field.label}</Text>
                                        <Text color={'text.subtle'}>{field.description ?? '-'}</Text>
                                    </Grid.Item>
                                    <Reveal.Hidden>
                                        <div>
                                            <ConfinedClick>
                                                <Button spacing='compact' icon={<Icon fa={['far', 'fa-pen']}/>} onClick={() => onUpdate(field.id)}/>
                                                <Button spacing='compact' icon={<Icon fa={['far', 'fa-trash']}/>} onClick={() => onDelete(field.id)}/>
                                            </ConfinedClick>
                                        </div>
                                    </Reveal.Hidden>
                                </Grid>
                            </Box>
                        </Reveal>
                    )
                }
            </Grid>
        </RequirePermission>
    );
}