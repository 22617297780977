import {Team}                                    from '@glimpse/glimpse';
import {Button, Text, useModal, Icon, Box, Flex} from '@glimpse/glass';
import {CreateTeamMemberModal}                   from 'features/team-member/create-team-member-modal';
import React                                     from 'react';
import {useTranslation}                          from 'foundations/i18n/use-translation';
import {RequirePermission}                       from 'features/permission/require-permission';
import {faPlus}                                  from '@fortawesome/pro-light-svg-icons';
import {TeamMemberList}                          from 'features/team-member/team-member-list';

export function TeamMembersPanel({
    teamId
}: {
    teamId: Team['id']
}) {
    const {t}         = useTranslation();
    const createModal = useModal();

    return (
        <>
            <CreateTeamMemberModal open={createModal.open} onClose={createModal.toggle} teamId={teamId}/>
            <Box mb={1} ml={0.5}>
                <Flex justify='between' align='center'>
                    <Text fontWeight='bold' fontSize={3}>{t('Members')}</Text>
                    <RequirePermission permission={'teams.write'}>
                        <Button spacing={'compact'} icon={<Icon fa={faPlus}/>} onClick={createModal.toggle}/>
                    </RequirePermission>
                </Flex>
            </Box>
            <TeamMemberList query={{team_id: teamId}}/>
        </>
    );
}