import {ScorecardCheck}      from '@glimpse/glimpse';
import {Modal, Button, Text} from '@glimpse/glass';
import {glimpse}             from 'providers/glimpse/glimpse-provider';
import {useTranslation}      from 'foundations/i18n/use-translation';
import toast                 from 'react-hot-toast';
import {useMutate}           from 'foundations/net/use-mutate';
import {FormFallbackError}   from 'foundations/ui/form/form-fallback-error';

export function DeleteScorecardCheckModal({
    open,
    onClose,
    scorecardCheckId
}: {
    open: boolean,
    onClose: () => void,
    scorecardCheckId: ScorecardCheck['id'],
}) {
    const {t}                      = useTranslation();
    const {loading, submit, error} = useMutate({
        key:       ['scorecard-check', scorecardCheckId],
        fn:        () => glimpse.scorecardCheck.delete(scorecardCheckId),
        onSuccess: () => {
            toast.success(t('Check deleted'));
            onClose();
        }
    });

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header title={t('Delete Check')}/>
            <Modal.Body>
                <FormFallbackError error={error}/>
                <Text>
                    {t('Are you sure you want to delete this check? This cannot be undone.')}
                </Text>
            </Modal.Body>
            <Modal.Footer right={
                <>
                    <Button onClick={onClose}>{t('Close')}</Button>
                    <Button variant='destructive' loading={loading} onClick={submit}>{t('Delete Check')}</Button>
                </>
            }/>
        </Modal>
    );
}