import {Team}                                                    from '@glimpse/glimpse';
import {Grid, Text, Skeleton, Button, useModal, Box, Menu, Icon} from '@glimpse/glass';
import {UpdateTeamModal}                                         from 'features/team/update-team-modal';
import {TeamMembersPanel}                                        from 'features/team-member/team-members-panel';
import {useParams}                                               from 'react-router-dom';
import {AppLayout}                                               from 'foundations/layouts/app-layout/app-layout';
import {DeleteTeamModal}                                         from 'features/team/delete-team-modal';
import {glimpse}                                                 from 'providers/glimpse/glimpse-provider';
import {useTranslation}                                          from 'foundations/i18n/use-translation';
import {useQuery}                                                from 'foundations/net/use-query';
import React                                                     from 'react';
import {RequirePermission}                                       from 'features/permission/require-permission';
import {TeamComponentPanel}                                      from 'features/team-component/team-component-panel';

export function TeamScreen() {
    const {t}         = useTranslation();
    const updateModal = useModal();
    const deleteModal = useModal();
    const {id}        = useParams();
    const api         = useQuery<Team>({
        key: ['team', id],
        fn:  () => glimpse.team.list({id})
    });

    const team = api.data?.[0];

    const menu = (
        <>
            <Menu.Group>
                <Menu.Item onClick={updateModal.toggle} icon={<Icon fa={['far', 'fa-pen']}/>}>
                    {t('Edit')}
                </Menu.Item>
                <Menu.Item onClick={deleteModal.toggle} icon={<Icon fa={['far', 'fa-trash']}/>}>
                    {t('Delete')}
                </Menu.Item>
            </Menu.Group>
        </>
    );

    return (
        <AppLayout>
            <RequirePermission permission={'teams.read'} fallback>
                <DeleteTeamModal open={deleteModal.open} onClose={deleteModal.toggle} teamId={id!}/>
                <UpdateTeamModal open={updateModal.open} onClose={updateModal.toggle} teamId={id!}/>
                <AppLayout.Header>
                    <Box pt={3} pb={3}>
                        <Grid justify='between'>
                            <div>
                                <Box pb={1}>
                                    {
                                        team?.name === undefined
                                            ? <Skeleton width='200px' height='22px'/>
                                            : <Text fontSize={4} fontWeight='bolder'>{team.name}</Text>
                                    }
                                </Box>
                                {
                                    team?.description === undefined
                                        ? <Skeleton width='150px' height='22px'/>
                                        : <Text color='text.subtle'>{team.description ?? t('No Description')}</Text>
                                }
                            </div>
                            <div>
                                <RequirePermission permission={'teams.write'}>
                                    <Menu menu={menu}>
                                        <Button icon={'fas fa-ellipsis'}/>
                                    </Menu>
                                </RequirePermission>
                            </div>
                        </Grid>
                    </Box>
                </AppLayout.Header>
                <AppLayout.Body>
                    <Grid gap={3}>
                        <Grid.Item xs={3}>
                            <TeamMembersPanel teamId={id!}/>
                        </Grid.Item>
                        <Grid.Item xs={9}>
                            <TeamComponentPanel teamId={id!}/>
                        </Grid.Item>
                    </Grid>
                </AppLayout.Body>
            </RequirePermission>
        </AppLayout>
    );

}