import {Button, Box, Form, Textbox} from '@glimpse/glass';
import React                        from 'react';
import {glimpse}                    from 'providers/glimpse/glimpse-provider';
import {useForm}                    from 'foundations/ui/form/use-form';
import {FormFallbackError}          from 'foundations/ui/form/form-fallback-error';
import {CreateIntegrationLayout}    from 'features/integration/create-integration-layout';
import {useTranslation}             from 'foundations/i18n/use-translation';
import {useMutate}                  from 'foundations/net/use-mutate';
import {IntegrationSuccessInlay}    from 'features/integration/integration-success-inlay';

export function CreateAzureIntegrationScreen() {
    const {t}                              = useTranslation();
    const form                             = useForm();
    const {error, loading, submit, status} = useMutate(
        {
            key: ['integration'],
            fn:  () => glimpse.integration.create({
                connector: 'azure',
                auth:      form.data
            })
        }
    );

    if (status?.success) {
        return (
            <CreateIntegrationLayout label={'Jira'}>
                <IntegrationSuccessInlay/>
            </CreateIntegrationLayout>
        );
    }

    return (
        <CreateIntegrationLayout label={'Azure'}>
            <Form>
                <FormFallbackError error={error}/>
                <Form.Field label={t('Client ID')}>
                    <Textbox {...form.register('client_id')}/>
                </Form.Field>
                <Form.Field label={t('Client Secret')}>
                    <Textbox {...form.register('client_secret')}/>
                </Form.Field>
                <Form.Field label={t('Tenant ID')}>
                    <Textbox {...form.register('tenant_id')}/>
                </Form.Field>
                <Form.Field label={t('Subscription ID')}>
                    <Textbox {...form.register('subscription_id')}/>
                </Form.Field>
            </Form>
            <Box mt={4}>
                <Button spacing={'large'} fullwidth variant={'primary'} disabled={loading} onClick={submit}>{t('Connect')}</Button>
            </Box>
        </CreateIntegrationLayout>
    );
}