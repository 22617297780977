export const BillingProductMeta: Record<string, {
    name: string,
    features: string[],
    desc: string,
    priority: number,
}> = {
    Starter: {
        name:     'Starter',
        features: ['Unlimited Users', 'Unlimited Components', 'Unlimited Actions', 'Unlimited Scorecards', 'Unlimited Integrations'],
        desc:     'For those who take development seriously',
        priority: 1
    },
    Pro:     {
        name:     'Pro',
        features: ['API Access', 'Advanced Analytics', 'Custom RBAC (Coming Soon)', 'Single Sign-On (Coming Soon)'],
        desc:     'For those who need something extra',
        priority: 2
    }
};