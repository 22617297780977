import {Scaffold}                                                         from '@glimpse/glimpse';
import {Modal, Button, Form, Textbox, Text, Box, BoxedSelector, Textarea} from '@glimpse/glass';
import {useForm}                                                          from 'foundations/ui/form/use-form';
import {SelectRepository}                                                 from 'features/repository/select-repository';
import {glimpse}                                                          from 'providers/glimpse/glimpse-provider';
import {useTranslation}                                                   from 'foundations/i18n/use-translation';
import toast                                                              from 'react-hot-toast';
import {useMutate}                                                        from 'foundations/net/use-mutate';

export function CreateScaffoldModal({
    open,
    onClose
}: {
    open: boolean,
    onClose: () => void
}) {
    const {t}  = useTranslation();
    const form = useForm<Scaffold.Create.Params>({
        type: 'git'
    });

    const {error, submit, loading} = useMutate({
        key:       ['scaffold'],
        fn:        () => glimpse.scaffold.create(form.data),
        onSuccess: () => {
            toast.success(t('Scaffold created'));
            onClose();
        }
    });

    const options = [
        {label: t('Git'), value: 'git'},
        {label: t('Cookiecutter (Coming soon)'), value: 'cookiecutter', disabled: true}
    ];

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Header title={t('Add Scaffold')}/>
            <Modal.Body>
                <Box mb={2}>
                    <BoxedSelector {...form.register('type')} options={options}/>
                </Box>
                <Box mb={3}>
                    <Text color='text.subtle'>{t('Git is the simplest and quickest way to use scaffolds. It requires very little setup and works with any project.')}</Text>
                </Box>
                <Form onSubmit={submit}>
                    <Form.Field label={t('Name')} errors={error?.params.name}>
                        <Textbox {...form.register('name')} placeholder='Ruby Api'/>
                    </Form.Field>
                    <Form.Field label={t('Repository')} errors={error?.params.template_url}>
                        <SelectRepository {...form.register('template_url')}/>
                    </Form.Field>
                    <Form.Field label={t('Description')} errors={error?.params.description}>
                        <Textarea {...form.register('description')}/>
                    </Form.Field>
                </Form>
            </Modal.Body>
            <Modal.Footer right={
                <>
                    <Button onClick={onClose}>{t('Cancel')}</Button>
                    <Button variant='primary' loading={loading} onClick={submit}>{t('Add')}</Button>
                </>
            }/>
        </Modal>
    );
}