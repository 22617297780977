import {useQuery as useQueryOriginal}     from '@tanstack/react-query';
import {Response}                         from '@glimpse/glimpse';
import {useCallback, useEffect, useState} from 'react';
import toast                              from 'react-hot-toast';
import {useTranslation}                   from 'foundations/i18n/use-translation';

export function useQuery<T>(options: {
    key: any[],
    fn: (() => Promise<Response>) | (({page}?: { page?: number }) => Promise<Response>),
    onSuccess?: (res: Response<T[]>) => void,
    pagination?: 'add' | 'replace',
}): {
    loading: boolean,
    data: Response<T>['data'][],
    error: Response<T>['error'],
    status?: Response<T>['status'],
    pagination: {
        page: number,
        loading: boolean,
        next: () => void,
    }
} {
    const {t}                           = useTranslation();
    const [page, setPage]               = useState<number>();
    const [loadingPage, setLoadingPage] = useState<boolean>(true);
    const [data, setData]               = useState<Response<T>['data'][]>([]);
    const fnWithCallback                = async () => {
        const res = await (page ? options.fn({page}) : options.fn());

        if (res.status.success && options.onSuccess) {
            options.onSuccess(res);
        }

        if (!res.status.success && res.error?.code === 'internal_error') {
            toast.error(t('A server error error. Please try again in a moment.'));
        }

        return res;
    };

    const query = useQueryOriginal({
        queryKey: [...options.key, page],
        queryFn:  fnWithCallback
    });

    useEffect(() => {
        if (options.pagination === 'add') {
            setData([
                ...data,
                ...query.data?.data
            ]);
        } else {
            setData(query.data?.data);
        }
        setLoadingPage(false);
    }, [JSON.stringify(query.data?.data)]);

    const nextPage = useCallback(() => {
        setPage(query.data?.pagination?.next_page);
        setLoadingPage(true);
    }, [query.data?.pagination?.next_page]);

    return {
        loading:    query.isLoading,
        data:       data ?? query.data?.data ?? [],
        error:      query.data?.error,
        status:     query.data?.status,
        pagination: {
            next:    nextPage,
            loading: loadingPage,
            page:    page ?? 0
        }
    };
}