import {ResponsiveBar}                     from '@nivo/bar';
import {ScorecardCheck, ScorecardCheckRun} from '@glimpse/glimpse';
import {useQuery}                          from 'foundations/net/use-query';
import {glimpse}                           from 'providers/glimpse/glimpse-provider';
import {useMemo}                           from 'react';
import {CenteredSpinner}                   from 'foundations/ui/interstitial/centered-spinner';

export function ScorecardCheckRunHistoryChart({
    query
}: {
    query: ScorecardCheck.List.Params
}) {
    const q      = {
        ...query,
        expand: ['check']
    };
    const {data, loading} = useQuery<ScorecardCheckRun>({
        key: ['scorecard-check-run', q],
        fn:  () => glimpse.scorecardCheckRun.list(q)
    });

    const byDate: Record<string, {
        date: string,
        Failed: number
        Passed: number
    }> = {};

    const chartData = useMemo(() => {
        const filteredForReruns = data
            .filter((a, i, arr) =>
                arr.findIndex(b =>
                    (
                        b.created_at.split('T')[0] === a.created_at.split('T')[0]
                        && a.scorecard_check_id === b.scorecard_check_id
                        && a.passed === b.passed
                    )
                ) === i);

        for (const run of filteredForReruns) {
            const date = run.created_at.split('T')[0];
            if (typeof byDate[date] !== 'object') {
                byDate[date] = {
                    Passed: 0,
                    Failed: 0,
                    date
                };
            }

            if (run.passed) {
                byDate[date].Passed += 1;
            } else {
                byDate[date].Failed += -1;
            }
        }

        return Object.values(byDate)
            .sort((a, b) => a.date > b.date ? 1 : -1);
    }, [data]);


    if (loading) {
        return <CenteredSpinner/>
    }

    return (
        <ResponsiveBar
            data={chartData}
            indexBy='date'
            keys={[
                'Passed',
                'Failed'
            ]}
            minValue={-7}
            maxValue={7}
            colors={['#2ABB7F', '#e12449']}
            enableLabel={false}
            margin={{top: 25, right: 50, bottom: 50, left: 25}}
            padding={0.3}
            labelFormat={v => `${Math.abs(v as number)}`}
            valueFormat={v => `${Math.abs(v)}`}
            markers={[{
                axis:      'y',
                value:     0,
                lineStyle: {
                    stroke:      '#888',
                    strokeWidth: 1
                }
            }]}
            axisLeft={null}
            axisBottom={{
                tickSize:       5,
                tickPadding:    5,
                tickRotation:   0,
                truncateTickAt: 0
            }}
            axisRight={{
                tickSize:       5,
                tickPadding:    5,
                tickRotation:   0,
                truncateTickAt: 0
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
        />
    );
}