import {Text, Grid, Icon, Box, Reveal, ConfinedClick, Button, useModal, Flex} from '@glimpse/glass';
import React, {useState}                                                      from 'react';
import {AppListing, Pipeline, Scaffold}                                       from '@glimpse/glimpse';
import {glimpse}                                                              from 'providers/glimpse/glimpse-provider';
import {Empty}                                                                from 'foundations/ui/empty/empty';
import {Link}                                                                 from 'react-router-dom';
import {useTranslation}                                                       from 'foundations/i18n/use-translation';
import {CenteredSpinner}                                                      from 'foundations/ui/interstitial/centered-spinner';
import {useQuery}                                                             from 'foundations/net/use-query';
import {RequirePermission}                                                    from 'features/permission/require-permission';
import {IntegrationHelper}                                                    from 'features/integration/integration-helper';
import {UpdateAppListingModal}                                                from 'features/app-listing/update-app-listing-modal';
import {DeleteAppListingModal}                                                from 'features/app-listing/delete-app-listing-modal';

export function AppListingsGrid({
    query
}: {
    query?: Pipeline.List.Params
}) {
    const {t}                     = useTranslation();
    const [selected, setSelected] = useState<AppListing['id']>();
    const updateModal             = useModal();
    const deleteModal             = useModal();
    const {data, loading}         = useQuery<AppListing>({
        key: ['app-listing', query],
        fn:  async () => await glimpse.appListing.list(query)
    });

    if (loading) {
        return <CenteredSpinner/>;
    }

    if (data?.length === 0) {
        return <Empty
            icon={<Icon fa={['far', 'boxes-stacked']}/>}
            title={t('Application Directory')}
            desc={t('Create a directory of all your applications so developers can discover faster.')}/>;
    }


    const openUpdate = (id: Scaffold['id']) => {
        setSelected(id);
        updateModal.toggle();
    };

    const openDelete = (id: Scaffold['id']) => {
        setSelected(id);
        deleteModal.toggle();
    };

    return (
        <RequirePermission permission={'app-listings.read'} fallback>
            {
                selected !== undefined &&
                <>
                    <UpdateAppListingModal open={updateModal.open} onClose={updateModal.toggle} appListingId={selected}/>
                    <DeleteAppListingModal open={deleteModal.open} onClose={deleteModal.toggle} appListingId={selected}/>
                </>
            }
            <Grid gap={1} sm={2} md={4} align={'stretch'}>
                {
                    data?.map(app => {
                        const logo = IntegrationHelper.parseIntegrationFromUrl(app.url)?.icon ?? `https://s2.googleusercontent.com/s2/favicons?domain_url=${app.url}`;
                        return (
                            <Reveal>
                                <Link to={app.url} target={'_blank'} key={app.id}>
                                    <Box p={2} radius={1} border={'default'} effects={['clickable', 'hoverable']}>
                                        <Flex justify={'between'}>
                                            <img src={logo} style={{height: 32, borderRadius: 8}}/>
                                            <Reveal.Hidden>
                                                <ConfinedClick>
                                                    <Button variant={'subtle'} spacing={'compact'} icon='far fa-arrow-up-right-from-square' disabled/>
                                                    <RequirePermission permission={'app-listings.write'}>
                                                        <Button onClick={() => openUpdate(app.id)} spacing={'compact'} icon='far fa-pen'/>
                                                        <Button onClick={() => openDelete(app.id)} spacing={'compact'} icon='far fa-trash'/>
                                                    </RequirePermission>
                                                </ConfinedClick>
                                            </Reveal.Hidden>
                                        </Flex>
                                        <Box mt={1}>
                                            <Text fontWeight='bold'>{app.label}</Text>
                                            <Text color={'text.subtle'}>{app.url}</Text>
                                        </Box>
                                    </Box>
                                </Link>
                            </Reveal>
                        );
                    })
                }
            </Grid>
        </RequirePermission>
    );
}