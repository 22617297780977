import {Team}            from '@glimpse/glimpse';
import {Box, Text, Grid} from '@glimpse/glass';
import {Link}            from 'react-router-dom';
import React            from 'react';
import {glimpse}        from 'providers/glimpse/glimpse-provider';
import {useTranslation} from 'foundations/i18n/use-translation';
import {useQuery}        from 'foundations/net/use-query';

export function TeamsList({
    query = {},
    empty
}: {
    query?: Team.List.Params,
    empty?: React.ReactElement
}) {
    const {t} = useTranslation();
    const api = useQuery<Team>({
        key: ['team', query],
        fn:  () => glimpse.team.list({
            ...query,
            expand: ['members', 'components']
        })
    });

    if (api.data?.length === 0 && empty) {
        return empty;
    }

    return (
        <>
            {
                api.data?.map(team =>
                    <Box pb={1} key={team.id}>
                        <Link to={`/teams/${team.id}`}>
                            <Box p={2} radius={1} border={'default'} effects={['hoverable', 'clickable']}>
                                <Grid justify='between' align='center'>
                                    <div>
                                        <Box pb={0.5}>
                                            <Text fontWeight='bold'>{team.name}</Text>
                                        </Box>
                                        <Text color='text.subtle'>{team.description ?? '-'}</Text>
                                    </div>
                                    <div>
                                        <Text color='text.subtle'>{team.members?.length ?? 0} {t('Members')}</Text>
                                        <Text color='text.subtle'>{team.components?.length ?? 0} {t('Components')}</Text>
                                    </div>
                                </Grid>
                            </Box>
                        </Link>
                    </Box>
                )
            }
        </>
    );
}