import {Button, Text, Box, Flex, Select, Separator} from '@glimpse/glass';
import {Role}                                       from '@glimpse/glimpse';
import {useTranslation}                             from 'foundations/i18n/use-translation';
import React, {useMemo}                             from 'react';
import {useMutate}                                  from 'foundations/net/use-mutate';
import {glimpse}                                    from 'providers/glimpse/glimpse-provider';
import {useForm}                                    from 'foundations/ui/form/use-form';
import {useQuery}                                   from 'foundations/net/use-query';
import {useNavigate}                                from 'react-router-dom';
import toast                                        from 'react-hot-toast';

export function RolePermissionsPanel({
    roleId
}: {
    roleId: Role['id']
}) {
    const {t}       = useTranslation();
    const navigate  = useNavigate();
    const form      = useForm<Role.Update.Params>();
    const ownerRole = useQuery<Role>({
        key: ['role', {workspace_id: null, name: 'Owner'}],
        fn:  () => glimpse.role.list({workspace_id: null, name: 'Owner'})
    });

    const currentRole = useQuery<Role>({
        key:       ['role', roleId],
        fn:        () => glimpse.role.list({id: roleId}),
        onSuccess: (res) => form.setData(res.data[0])
    });

    const update = useMutate({
        key:       ['role', roleId],
        fn:        () => glimpse.role.update(roleId, form.data),
        onSuccess: () => {
            toast.success(t('Role updated'));
            navigate('/settings/roles');
        }
    });

    const handleChange = (permissionName: string, state: 'no_access' | 'read' | 'read_write') => {
        let permissions = [...form.data.permissions ?? []];
        permissions     = permissions.filter(perm => perm !== `${permissionName}.read` && perm !== `${permissionName}.write`);

        switch (state) {
            case 'no_access':
                break;
            case 'read':
                permissions.push(`${permissionName}.read`);
                break;
            case 'read_write':
                permissions.push(`${permissionName}.read`);
                permissions.push(`${permissionName}.write`);
                break;
        }

        form.setData({
            ...form.data,
            permissions
        });
    };

    const options = useMemo(() => [
        {
            label: t('No access'),
            value: 'no_access'
        },
        {
            label: t('Read'),
            value: 'read'
        },
        {
            label: t('Read & Write'),
            value: 'read_write'
        }
    ], []);

    const possiblePerms = ownerRole.data[0]?.permissions.map((permission) => permission.split('.')[0]);

    return (
        <>
            <Box border={'default'} pl={2} pr={2} radius={1}>
                {
                    ([...new Set(possiblePerms)] as string[]).map((grant, i) => {
                        const name  = grant.split('.')[0];
                        const read  = form.data.permissions?.includes(`${name}.read`);
                        const write = form.data.permissions?.includes(`${name}.write`);

                        const current = write ? 'read_write' : read ? 'read' : 'no_access';

                        return (
                            <>
                                {
                                    i !== 0 && <Separator/>
                                }
                                <Box p={2}>
                                    <Flex justify={'between'} align={'center'}>
                                        <Text fontWeight={'bold'}>{grant.replaceAll('-', ' ')}</Text>
                                        <Select disabled={currentRole.data[0]?.workspace_id === null} options={options} value={current} name={'grants'} onChange={(event) => handleChange(name, event.currentTarget.value)}/>
                                    </Flex>
                                </Box>
                            </>
                        );
                    })
                }
            </Box>
            {
                currentRole.data[0]?.workspace_id !== null &&
                <Box mt={2} mb={2}>
                    <Button variant={'primary'} onClick={update.submit}>{t('Save')}</Button>
                </Box>
            }
        </>
    );
}